import React from 'react';
import InputDropdownBox from 'src/components/InputDropdownBox';
import useStores from 'src/hooks/useStores';
import { observer } from 'mobx-react';

const DealerDropdownBox = props => {
  const { requestStore } = useStores();
  const { dealerEntity } = requestStore;
  const { dealerList } = dealerEntity;

  return (
    <InputDropdownBox
      items={dealerList.map(o => ({ value: o.label, key: o.dealerCode }))}
      label={'Дилерский центр*'}
      placeholder="Выберите из списка"
      {...props}
    />
  );
};

export default observer(DealerDropdownBox);
