import { Anchor } from 'antd';
import React from 'react';

const { Link } = Anchor;

const LinkText = props => {
  const { href, children } = props;

  return (
    <Anchor>
      <Link href={href} title={children} target="_blank" />
    </Anchor>
  );
};
export default LinkText;
