import React from 'react';
import { Col, Form } from 'antd';
import InputMaskedBox from '../components/InputMaskedBox';
import { phone, required } from '../services/validators';

const PhoneInputBox = props => {
  const { ...other } = props;

  return (
    <Col span={12}>
      <Form.Item
        name={'clientPhoneMobile'}
        rules={[required, phone]}
        validateTrigger={'onBlur'}
      >
        <InputMaskedBox
          mask={'+7(111)111-11-11'}
          label={'Номер мобильного телефона*'}
          placeholder={'+7(XXX)XXX-XX-XX'}
          helperText={'Введите номер Вашего мобильного телефона'}
          {...other}
        />
      </Form.Item>
    </Col>
  );
};

export default PhoneInputBox;
