import React from 'react';
import { Row, Col, Form } from 'antd';
import DropdownInput from '../components/InputDropdownBox';
import { required } from 'src/services/validators';
import DealerCityDropdownBox from 'src/components/DealerCityDropdownBox';
import DealerDropdownBox from 'src/components/DealerDropdownBox';

const DealerDataForm = props => {
  const { bankList, formDisabled } = props;
  return (
    <>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Form.Item name={'dealerCity'} rules={[required]}>
            <DealerCityDropdownBox disabled={formDisabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'dealerSalePointName'} rules={[required]}>
            <DealerDropdownBox disabled={formDisabled} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Form.Item name={'bankPartnerName'} rules={[required]}>
            <DropdownInput
              label={'Банк-партнер*'}
              helperText={'Выберите банк для перечисления суммы кредита'}
              items={bankList.map(bank => bank.name)}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default DealerDataForm;
