import React from 'react';
import { InputNumber, Row, Typography } from 'antd';

const { Text } = Typography;

const InputNumberBox = props => {
  const {
    label,
    helperText,
    thousandSeparator = true,
    suffix,
    ...other
  } = props;

  return (
    <div className={'input-box'}>
      {label && (
        <Row>
          <Text className={'input-label'}>{label}</Text>
        </Row>
      )}
      <Row
        style={{
          width: '100%',
          paddingTop: label ? 0 : '36px',
        }}
      >
        <div className={'input-number input-box'} style={{ width: '100%' }}>
          <InputNumber
            style={{ width: '100%' }}
            {...other}
            formatter={
              thousandSeparator
                ? value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                : value => value
            }
            parser={value => value.replace(/\D/g, '')}
          />
        </div>
        {suffix && <span className={'suffix'}>{suffix}</span>}
      </Row>
      <div className={'helper-text'}>
        {helperText && (
          <Row>
            <Text>{helperText}</Text>
          </Row>
        )}
      </div>
    </div>
  );
};
export default InputNumberBox;
