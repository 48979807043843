import React from 'react';
import formatters from '../services/formatters';
import { inject, observer } from 'mobx-react';
import CalculatorInfoRow from './CalculatorInfoRow';

const CalculatorInfoBox = props => {
  const { calculatorStore } = props;
  const { calculatorEntity, changedValues } = calculatorStore;
  const {
    model,
    brand,
    carCost,
    carUseType,
    creditTerm,
    insuranceInCredit,
    beginPayment,
    beginPaymentPercent,
    residualPayment,
    residualPaymentPercent,
    creditAmount,
    monthlyPayment,
    creditRate,
    cascoInCredit,
    govProgram,
    govProgramName,
  } = calculatorEntity;

  return (
    <>
      <CalculatorInfoRow
        data={[
          { label: 'Тип автомобиля:', value: carUseType },
          { label: 'Марка:', value: brand },
          { label: 'Модель:', value: model },
        ]}
      />
      <CalculatorInfoRow
        data={[
          { label: 'Стоимость авто:', value: formatters.toMoney(carCost) },
          { label: 'Срок кредита:', value: formatters.toMonth(creditTerm) },
          {
            label: 'Первоначальный взнос:',
            value: formatters.toMoneyAndPercent(
              beginPayment,
              beginPaymentPercent
            ),
          },
        ]}
      />
      <CalculatorInfoRow
        data={[
          {
            label: 'Остаточный платеж:',
            value: formatters.toMoneyAndPercent(
              residualPayment,
              residualPaymentPercent
            ),
          },
          {
            label: 'Сумма кредита:',
            value: formatters.toMoney(creditAmount),
            changed: changedValues.creditAmount,
          },
          {
            label: 'Процентная ставка:',
            value: formatters.toPercent(creditRate),
            changed: changedValues.creditRate,
          },
        ]}
      />
      <CalculatorInfoRow
        data={[
          {
            label: 'Ежемесячный платеж:',
            value: formatters.toMoney(monthlyPayment),
            changed: changedValues.monthlyPayment,
          },
          {
            label: 'Страхование жизни:',
            value: insuranceInCredit ? 'Включено в сумму кредита' : 'Нет',
          },
          {
            label: 'Страхование КАСКО:',
            value: cascoInCredit ? 'Обязательно к оформлению' : 'Нет',
          },
        ]}
      />
      {govProgram && govProgramName && (
        <CalculatorInfoRow
          data={[
            {
              label: 'Госпрограмма:',
              value: govProgramName,
            },
          ]}
        />
      )}
    </>
  );
};

export default inject('calculatorStore')(observer(CalculatorInfoBox));
