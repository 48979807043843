import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

export default class IconArrow extends React.Component {
  static defaultProps = {
    rotate: '0deg',
    text: '',
  };

  render() {
    const { rotate, text } = this.props;

    return (
      <div className={'collapse-icon'}>
        {text && <Text>{text}</Text>}
        <svg
          style={{
            transform: `rotate(${rotate})`,
          }}
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L6 6L11 1"
            stroke="#004969"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }
}
