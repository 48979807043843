import { action, computed, observable } from 'mobx';
import api from '../services/api';
import { dialogStore, loaderStore, pinStore, requestStore } from './index';
import AuthEntity from './entities/AuthEntity';
import { globalState } from 'src/stores';
import { createLeadRequest } from 'src/helpers/makeRequest';
import calculatorStore from './CalculatorStore';

export class AuthStore {
  @observable status = false;
  @observable authEntity = new AuthEntity(this);
  @observable loginFormVisible = false;
  @observable hasError = '';
  @observable location = 'auth';

  constructor() {
    this.updateStatus();
  }

  updateFields(data) {
    this.authEntity.updateFields(data);
  }

  @computed
  get btnDisabled() {
    return !this.authEntity.documentNumber;
  }

  consents(fieldName) {
    return ['accordancePersonInfo', 'infoFromPromotionalMaterials']
      .filter(consentName => this.authEntity[consentName])
      .map(consentName => ({ [fieldName]: consentName }));
  }

  @action.bound
  async onLogin(number, successFn) {
    try {
      loaderStore.on();

      if (pinStore.available) {
        await api.authLogin(number);
        const tokenData = api.getAccessTokenData();
        if (tokenData.verification === null) {
          return this.setError(
            'Неправильно введен номер заявки, попробуйте еще раз'
          );
        }
        this.authEntity.optID = await api.getOptID();
      }

      pinStore.run(successFn);
    } catch (e) {
      dialogStore.catch(e);
    }
  }

  @action
  setError(error) {
    loaderStore.off();
    this.hasError = error;
  }

  @action.bound
  async loginByLead() {
    const documentNumber = this.authEntity.documentNumber;
    if (!documentNumber) {
      return this.setError('Введите номер заявки');
    }
    try {
      await this.onLogin(
        {
          documentNumber,
        },
        () => {
          this.loginFormVisible = false;
          this.onLoginByLeadSuccess();
        }
      );
    } catch (e) {
      dialogStore.catch(e);
    }
  }

  @action.bound
  async loginByPhone() {
    await this.onLogin(
      {
        phone: this.authEntity.clientPhoneMobile,
        consents: this.consents('description'),
        clientInfo: {
          firstName: this.authEntity.firstName,
          lastName: this.authEntity.lastName,
          middleName: this.authEntity.middleName,
        },
      },
      this.onLoginByPhoneSuccess
    );
  }

  createLeadRequest = () => {
    return createLeadRequest({
      authData: this.authEntity,
      creditData: calculatorStore.calculatorEntity,
    });
  };

  @action.bound
  async onLoginByPhoneSuccess() {
    this.status = true;
    loaderStore.on();
    try {
      const request = this.createLeadRequest();
      const res = await api.createCRM(request, {
        signedDoc: this.consents('type').map(consentObj => {
          consentObj.optID = this.authEntity.optID;
          consentObj.number = this.authEntity.clientPhoneMobile;
          return consentObj;
        }),
      });
      this.authEntity.documentNumber = res.documentNumber;
      globalState.goNext();
    } catch (e) {
      dialogStore.catch(e);
    } finally {
      loaderStore.off();
    }
  }

  @action.bound
  async onLoginByLeadSuccess() {
    this.status = true;
    this.location = '';
    await requestStore.getLead();
    if (requestStore.requestReworkEntity._status !== 'new') {
      this.location = 'revision';
    }
    // если пройден шаг 3, то его редактирование блокируется (PRVWDBOR-59)
    if (requestStore.passportEntity.mainIdentityDocSeries) {
      globalState.isRegister = true;
    }
    globalState.modeOn('passportData');
    globalState.setStatus(this.location);
    loaderStore.off();
  }

  @action.bound
  updateStatus() {
    this.status = !!api.getRefreshToken() && !api.needRefresh();
  }

  onSubmit() {
    pinStore.open();
  }

  setClientGender(v) {
    requestStore.setClientGender(v);
  }

  @action.bound
  showLoginForm() {
    this.loginFormVisible = true;
  }

  @action.bound
  closeLoginForm() {
    this.loginFormVisible = false;
    this.authEntity.documentNumber = undefined;
    this.hasError = undefined;
  }
}

const authStore = new AuthStore();
export default authStore;
