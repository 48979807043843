import React from 'react';

import { observer } from 'mobx-react';
import { Col, Layout, Row, Typography } from 'antd';
import CalculatorCollapseBox from '../components/CalculatorCollapseBox';
import StatusRow from '../components/StatusRow';
import ButtonCancelBox from '../components/ButtonCancelBox';
import CorrespondenceCollapseBox from '../components/CorrespondenceCollapseBox';
import WithIcon from '../components/hocs/WithIcon';
import IconBasket from '../components/icons/IconBasket';
import useStores from '../hooks/useStores';
import RejectInfoBox from '../components/RejectInfoBox';

const { Text } = Typography;
const CancelButton = WithIcon(ButtonCancelBox);

const RequestStatusPage = () => {
  const { requestStore } = useStores();
  const { cancelRequest } = requestStore;
  const {
    status,
    statusColor,
    number,
    requestDate,
    cancelButtonShow,
    correspondenceBoxShow,
    calculatorBoxShow,
    rejectInfoBoxShow,
    calculatorHeader,
    collapseCalculator,
  } = requestStore.requestReworkEntity;

  return (
    <Layout
      style={{
        border: '1px solid grey',
        padding: 32,
        backgroundColor: 'white',
      }}
    >
      <Row gutter={[0, 2]}>
        <Col>
          <div className={'status-block-title'}>
            <Text>Заявка на получение кредита</Text>
          </div>
        </Col>
      </Row>
      <Row gutter={[2, 4]} justify={'start'}>
        <Col>
          <div className={'status-block'}>
            <Text>{`№ ${number}`}</Text>
          </div>
        </Col>
        <Col>
          <div className={'status-block'}>
            <Text>{`Создана ${requestDate}`}</Text>
          </div>
        </Col>
      </Row>

      <Row justify={'space-between'} align={'middle'} className={'status-row'}>
        <StatusRow status={status} color={statusColor} />
        <Col>
          {cancelButtonShow && (
            <CancelButton icon={<IconBasket />} onClick={cancelRequest} />
          )}
        </Col>
      </Row>
      {calculatorBoxShow && (
        <CalculatorCollapseBox
          header={calculatorHeader}
          defaultActiveKey={collapseCalculator}
        />
      )}
      {correspondenceBoxShow && <CorrespondenceCollapseBox comment />}
      {rejectInfoBoxShow && <RejectInfoBox />}
    </Layout>
  );
};

export default observer(RequestStatusPage);
