import React, { useState } from 'react';
import '../common/dadataSuggestions/styles.css';
import IconClear from './icons/IconClear';
import IconSearch from './icons/IconSearch';
import { Col, Row, Typography } from 'antd';
import DadataSuggestions from '../common/dadataSuggestions/src';

const REACT_APP_DADATA_TOKEN = process.env.REACT_APP_DADATA_TOKEN;

const { Text } = Typography;

const DaDataBox = props => {
  const {
    searchIcon = true,
    label,
    additionalFn,
    additionalLabel,
    onChange,
    helperText,
    value,
    mutationFn,
    query = 'value',
    disabled,
    ...other
  } = props;

  const [readOnly, setReadOnly] = useState(true);

  const getData = (data, path) => {
    return path.split('.').reduce((current, key) => current[key], data);
  };

  const onSelect = e => {
    const arg = getData(e, query);
    onChange(arg);
    if (mutationFn) {
      mutationFn(e);
    }
  };

  const onChangeFn = e => {
    onChange(e);
    if (mutationFn) {
      mutationFn();
    }
  };

  const onClear = () => onChange('');

  return (
    <div className={'input-box dadata'}>
      <Row justify={'space-between'}>
        <Col>
          <Text className={'input-label'}>{label}</Text>
        </Col>
        {!disabled && (
          <Col>
            <Text onClick={additionalFn} className={'additional'}>
              {additionalLabel}
            </Text>
          </Col>
        )}
      </Row>
      <div className={'dadata-input'}>
        {searchIcon && (
          <div className={'dadata-search-icon'}>
            <IconSearch />
          </div>
        )}

        <DadataSuggestions
          style={searchIcon ? {} : { padding: ' 0 11px' }}
          autocomplete={'skdvw;kjv'}
          minChars={2}
          token={REACT_APP_DADATA_TOKEN}
          onSelect={onSelect}
          onChange={onChangeFn}
          query={value ? value : ''}
          highlighting={false}
          onFocus={() => setReadOnly(false)}
          readOnly={readOnly}
          disabled={disabled}
          {...other}
        />
        {value && !disabled && (
          <div className={'dadata-clear-icon'} onClick={onClear}>
            <IconClear />
          </div>
        )}
      </div>
      <div className={'helper-text'}>
        {helperText && (
          <Row>
            <Text>{helperText}</Text>
          </Row>
        )}
      </div>
    </div>
  );
};

export default DaDataBox;
