import React from 'react';
import 'mobx-react-lite/batchingForReactDom';
import { observer } from 'mobx-react';
import { Form, Row } from 'antd';
import CheckBoxWithLink from '../components/CheckBoxWithLink';
import AuthDataForm from '../forms/AuthDataForm';
import UserDataForm from '../forms/UserDataForm';
import RequestPageLayout from '../layouts/RequestPageLayout';
import { required, requiredCheckBox } from '../services/validators';
import useStores from 'src/hooks/useStores';
import NoteBox from 'src/components/NoteBox';
import ButtonPrimaryBox from 'src/components/ButtonPrimaryBox';
import { scrollToError } from 'src/helpers/helpers';
import links from '../stores/lists/links';

const AuthPage = () => {
  const { authStore } = useStores();
  const { authEntity, loginByPhone } = authStore;
  const {
    buttonDisabled,
    firstName,
    lastName,
    middleName,
    clientEmail,
    clientPassword,
    clientPhoneMobile,
    accordancePersonInfo,
    infoFromPromotionalMaterials,
  } = authEntity;
  return (
    <>
      <RequestPageLayout buttonDisabled={buttonDisabled}>
        <Form
          name={'AuthData'}
          initialValues={{
            firstName,
            lastName,
            middleName,
            clientEmail,
            clientPassword,
            clientPhoneMobile,
            accordancePersonInfo,
            infoFromPromotionalMaterials,
          }}
          onFinish={loginByPhone}
          onFinishFailed={scrollToError}
        >
          <UserDataForm />

          <AuthDataForm />

          <Row gutter={[0, 20]}>
            <Form.Item
              valuePropName={'checked'}
              name={'accordancePersonInfo'}
              rules={[required, requiredCheckBox]}
            >
              <CheckBoxWithLink
                text={'Я даю свое согласие на обработку'}
                linkText={'персональных данных'}
                to={links.consentToProcessing}
              />
            </Form.Item>
            <Form.Item
              valuePropName={'checked'}
              name={'infoFromPromotionalMaterials'}
            >
              <CheckBoxWithLink
                text={'Я согласен(а) на получение'}
                linkText={'рекламных материалов'}
                to={links.consentToAdvertising}
              />
            </Form.Item>
          </Row>
          <NoteBox />
          <Form.Item>
            <ButtonPrimaryBox htmlType={'submit'} disabled={buttonDisabled}>
              ДАЛЕЕ
            </ButtonPrimaryBox>
          </Form.Item>
        </Form>
      </RequestPageLayout>
    </>
  );
};

export default observer(AuthPage);
