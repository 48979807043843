import { action, computed, observable } from 'mobx';
import {
  PersonalEntity,
  AdditionalInfoEntity,
  IncomeEntity,
  PassportEntity,
  DealerEntity,
  RequestReworkEntity,
} from './entities/';
import FileManager from 'src/stores/managers/FileManager';
import {
  globalState,
  authStore,
  calculatorStore,
  pinStore,
  loaderStore,
  dialogStore,
} from 'src/stores/index';
import api from 'src/services/api';
import { createLeadRequest, createPOSRequest } from '../helpers/makeRequest';
import helperStore from './HelperStore';
import React from 'react';
import Text from 'antd/es/typography/Text';

export class RequestStore {
  @observable personalEntity = new PersonalEntity(this);
  @observable additionalInfoEntity = new AdditionalInfoEntity(this);
  @observable incomeEntity = new IncomeEntity(this);
  @observable passportEntity = new PassportEntity(this);
  @observable dealerEntity = new DealerEntity(this);
  @observable requestReworkEntity = new RequestReworkEntity(this);

  @observable verify = true;
  @observable _dealers = [];

  @observable uploadShow = false;
  @observable uploadAdditionalShow = false;
  @observable _documentListAll = [];

  @observable _docsList = ['1']; //паспорт

  requestUpdated = true;
  docsIsLoading = false;
  optID;

  fileStore = new FileManager();
  reworkFileStore = new FileManager();

  get needUpdate() {
    return authStore.status && !this.requestUpdated;
  }

  get documentNumber() {
    return authStore.authEntity.documentNumber;
  }

  consents(propertyName) {
    return ['AccordanceInformationToBKI', 'UserAgreement']
      .filter(consentName => this.passportEntity[consentName])
      .map(consentName => ({ [propertyName]: consentName }));
  }

  @computed
  get docList() {
    const secondDocId = [this.personalEntity.secondDocId] || [];
    const additionalDocId = this.personalEntity.additionalDocId;
    return [...this._docsList, ...secondDocId, ...additionalDocId];
  }

  clientGenderSelect = [
    { value: 'MALE', name: 'МУЖСКОЙ' },
    { value: 'FEMALE', name: 'ЖЕНСКИЙ' },
  ];

  requiredForms = ['personalData', 'incomeData', 'additionalData'];

  forms = new Map();
  formErrors = this.requiredForms.reduce((res, f) => {
    res[f] = undefined;
    return res;
  }, {});

  @action.bound
  setClientGender(v) {
    this.passportEntity.setClientGender(v);
  }

  get isDrivingLicense() {
    return this.personalEntity.isDrivingLicense;
  }

  @action.bound
  async onSubmit(form) {
    loaderStore.on();
    try {
      if (form === 'passportData') {
        this.verify = await this.verifyPassport();
        if (!this.verify) {
          return loaderStore.off();
        }
        return await this.signAndUpdateLead();
      } else if (form === 'additionalData') {
        //Валидирует все формы перед отправкой.
        const valid = await this.checkRequiredForms();
        if (!valid) {
          return loaderStore.off();
        }
        await this.signAndCreatePOS();
      } else {
        await this.updateLead();
        globalState.goNext();
      }
    } catch (e) {
      dialogStore.catch(e);
    } finally {
      loaderStore.off();
    }
  }

  @computed
  get hasSubsidy() {
    return calculatorStore.hasSubsidy;
  }

  @action.bound
  openUpload() {
    this.uploadShow = true;
  }

  @action.bound
  closeUpload() {
    this.uploadShow = false;
    if (this.forms.has('additionalData')) {
      const form = this.forms.get('additionalData');
      form.validateFields(['addDocuments']);
    }
  }

  @action.bound
  openUploadAdditional() {
    this.uploadAdditionalShow = true;
  }

  @action.bound
  closeUploadAdditional() {
    this.uploadAdditionalShow = false;
  }

  setDocuments(docs) {
    this.personalEntity.setDocuments(docs);
  }

  verifyPassport = () => {
    return api
      .getPassportCompliance({
        lastName: authStore.authEntity.lastName,
        docSer: this.passportEntity.mainIdentityDocSeries,
        docNum: this.passportEntity.mainIdentityDocNumber,
        name: authStore.authEntity.name,
        firstName: authStore.authEntity.name,
        dateBirthday: this.passportEntity.clientBirthdate,
      })
      .catch(() => false);
  };

  @computed
  get dealers() {
    //проверка пока можно перемещаться без выбора бренда
    const brand = calculatorStore.brand || 'Volkswagen';
    if (this.currentBrand !== brand && !this.dealersIsLoading) {
      this.getDealers(brand);
    }
    return this._dealers;
  }

  @computed
  get documentListAll() {
    if (!this._documentListAll.length && !this.docsIsLoading) {
      this.getDocumentList();
    }
    return this._documentListAll;
  }

  @action.bound
  getDealers(brand) {
    this.dealersIsLoading = true;

    return api.getDealers(brand).then(res => {
      this._dealers = res;
      this.currentBrand = brand;
      this.dealersIsLoading = false;
    });
  }

  @action.bound
  getDocumentList() {
    this.docsIsLoading = true;
    return api.getDocumentList().then(res => {
      this._documentListAll = res;
      this.docsIsLoading = false;
    });
  }

  @action.bound
  setForm(formName, form, fields, ref) {
    this.forms.set(formName, { ...form, fields, ref });
  }

  checkRequiredForms = async () => {
    for (let name of this.requiredForms) {
      if (!this.forms.has(name) || this.formErrors[name]) {
        this.redirectToForm(name);
        return;
      }
    }
    return true;
  };

  redirectToForm(name) {
    Promise.resolve(globalState.modeOn(name)).then(() => {
      const form = this.forms.get(name);
      form.submit();
    });
  }

  validateForm(name) {
    if (this.forms.has(name)) {
      const form = this.forms.get(name);
      form
        .validateFields()
        .then(() => this.setFormErrors(name, []))
        .catch(({ errorFields }) => this.setFormErrors(name, errorFields));
    }
  }

  setFormErrors = (formName, errors) => {
    const err = errors.reduce((res, e) => {
      res = [...res, ...e.name];
      return res;
    }, []);
    this.formErrors[formName] = err.length ? err : undefined;
  };

  validateDocs = fileStore =>
    !this.docList.some(
      id => !fileStore.getFilesByDoc(id).length || fileStore.hasErrorByDoc(id)
    );

  //todo нормально сделать формирование параметров методов в зависимости от шага
  get signedDocs() {
    const signedDocs = this.optID ? this.consents('type') : [];
    const fileList = this.fileStore.fileList;
    const docs = signedDocs.concat(fileList).map(o => ({
      ...o,
      optID: this.optID,
      number: authStore.authEntity.clientPhoneMobile,
    }));

    return { signedDoc: docs };
  }

  get unSignedDocs() {
    const signedDocs = this.optID ? this.consents('type') : [];
    const fileList = this.fileStore.fileList;
    const docs = signedDocs.concat(fileList).map(o => ({
      ...o,
      number: authStore.authEntity.clientPhoneMobile,
    }));

    return { signedDoc: docs };
  }

  get reworkSignedDocs() {
    const signedDocs = this.optID ? this.consents('type') : [];
    const fileList = this.reworkFileStore.fileList;
    const docs = signedDocs.concat(fileList).map(o => ({
      ...o,
      number: authStore.authEntity.clientPhoneMobile,
    }));

    return { signedDoc: docs };
  }

  updateLead = async () => {
    const updateRequest = createLeadRequest({
      creditData: calculatorStore.calculatorEntity,
      authData: authStore.authEntity,
      personalData: this.personalEntity,
      passportData: this.passportEntity,
      incomeData: this.incomeEntity,
      additionalData: this.additionalInfoEntity,
      dealer: this.dealerEntity,
      clientAddressList: this.clientAddressList,
      additionalDocs: this.additionalDocs,
    });
    try {
      const leadID = api.getLeadId();
      await api.updateCRM(updateRequest, leadID, this.signedDocs);
      this.requestUpdated = false;
    } catch (e) {
      dialogStore.catch(e);
    }
  };

  postLead = async () => {
    const request = createPOSRequest({
      creditData: calculatorStore.calculatorEntity,
      authData: authStore.authEntity,
      personalData: this.personalEntity,
      passportData: this.passportEntity,
      incomeData: this.incomeEntity,
      additionalData: this.additionalInfoEntity,
      dealer: this.dealerEntity,
      clientAddressList: this.clientAddressList,
      additionalDocs: this.additionalDocs,
    });
    try {
      return await api.createLoanApplication(request, this.unSignedDocs);
    } catch (e) {
      dialogStore.catch(e);
    }
  };

  getLead = async () => {
    const leadID = await api.getLeadId();
    const lead = await api.getCRM(leadID);

    this.updateFields(lead);
    this.requestUpdated = true;
  };

  refreshForms() {
    if (this.needUpdate) {
      this.getLead();
    }
  }

  updateFields(lead) {
    [
      authStore,
      calculatorStore.calculatorEntity,
      this.passportEntity,
      this.personalEntity,
      this.dealerEntity,
      this.incomeEntity,
      this.additionalInfoEntity,
      this.requestReworkEntity,
    ].forEach(entity => {
      entity.updateFields(lead);
    });
    if (lead.signedDocs) {
      for (let doc of lead.signedDocs.signedDoc) {
        this.fileStore.addFile({ fileName: doc.filename, docId: doc.type });
      }
    }
  }

  signAndUpdateLead = async reSend => {
    if (reSend && !pinStore.reSendAble) {
      return;
    }
    pinStore.setError(false);
    loaderStore.on();
    this.optID = await api.codeSign({
      client: {
        phone: authStore.authEntity.clientPhoneMobile,
      },
      documents: {
        list: this.consents('description'),
      },
    });

    pinStore.run(() => {
      this.updateLead();
      globalState.goNext();
    }, api.codeConfirm.bind(api, this.optID));
  };

  signAndCreatePOS = async () => {
    loaderStore.on();
    const res = await this.postLead();
    // проверка актуальности условий
    const conditionsStatus = await calculatorStore.checkConditions();
    if (conditionsStatus === 'invalid') {
      globalState.modeOn('calculator');
      dialogStore.onConditionsInvalid();
    } else if (conditionsStatus === 'changed') {
      dialogStore.onConditionsChanged();
    } else if (conditionsStatus === 'valid') {
      if (res) {
        this.optID = res.optID;
        pinStore.run(() => {
          dialogStore.onRequestSent(this.documentNumber);
        }, api.confirmLoanApplication.bind(api, this.optID));
      }
    }
  };

  updatePOS = async () => {
    loaderStore.on();
    const request = createPOSRequest({
      creditData: calculatorStore.calculatorEntity,
      authData: authStore.authEntity,
      personalData: this.personalEntity,
      passportData: this.passportEntity,
      incomeData: this.incomeEntity,
      additionalData: this.additionalInfoEntity,
      dealer: this.dealerEntity,
      clientAddressList: this.clientAddressList,
      additionalDocs: this.additionalDocs,
      comment: this.requestReworkEntity.comment,
    });
    try {
      return await api.updateLoanApplication(request, this.reworkSignedDocs);
    } catch (e) {
      dialogStore.catch(e);
    }
  };

  cancelRequest = async () => {
    helperStore.onOpen({
      message: (
        <Text className={'dialog-header'}>
          Вы уверены, что хотите отказаться от заявки?
        </Text>
      ),
      method: 'cancel',
      buttons: {
        ok: async () => {
          try {
            await api.cancelCRM();
            globalState.modeOn('calculator');
            globalState.setStatus('');
            helperStore.onOpen({
              method: 'info',
              message: (
                <div className={'cancel-info'}>
                  <Text className={'dialog-header'}>
                    Запрос на отказ от заявки принят Банком
                  </Text>
                </div>
              ),
            });
          } catch (e) {
            dialogStore.catch(e);
          }
        },
      },
    });
  };

  sendToBank = async () => {
    const res = await this.updatePOS();
    try {
      pinStore.run(() => {}, api.confirmLoanApplication.bind(api, res.optID));
    } catch (e) {
      dialogStore.catch(e);
    }
  };

  async getCountryName(countryCode) {
    return await api.getCountryName(countryCode);
  }

  get clientAddressList() {
    const addresses = [];
    [
      this.passportEntity.addressRegistrationFIAS,
      this.passportEntity.addressLivingFIAS,
      this.incomeEntity.employerAddressFIAS,
    ].forEach(address => {
      if (address?.type) {
        addresses.push(address);
      }
    });

    return addresses.length ? { clientAddress: addresses } : undefined;
  }

  get additionalDocs() {
    const {
      docNumber,
      _docType,
      docSeries,
      docIssueDate,
      docEndDate,
      docIssueBy,
    } = this.personalEntity;
    return this.personalEntity.docNumber
      ? {
          additional: [
            {
              docType: _docType,
              docSeries: docSeries,
              docNumber: docNumber,
              docIssueDate: docIssueDate,
              docEndDate: docEndDate,
              docIssueBy: docIssueBy,
            },
          ],
        }
      : undefined;
  }

  @computed
  get requestReworkButtonDisable() {
    return (
      !this.requestReworkEntity.comment && !this.reworkFileStore.fileList.length
    );
  }
}

const requestStore = new RequestStore();
export default requestStore;
