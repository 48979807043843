import React from 'react';
import InputDropdownBox from 'src/components/InputDropdownBox';
import useStores from 'src/hooks/useStores';

const TaxRateDropdownBox = props => {
  const { requestStore } = useStores();
  const { incomeEntity } = requestStore;
  const { rateNalogList } = incomeEntity;
  const { label } = props;
  return (
    <InputDropdownBox
      items={rateNalogList.map(o => o.label)}
      label={label}
      placeholder="Выберите из списка"
      {...props}
    />
  );
};

export default TaxRateDropdownBox;
