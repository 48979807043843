export default {
  project: 'Проект',
  sentToBank: 'Направлена в Банк',
  checkDocuments: 'Проверка документов',
  reapproval: 'Переодобрение',
  input: 'Введено',
  additionalCheck: 'Дополнительная проверка',
  AMLCheck: 'Проверка AML',
  error: 'Ошибка',
  creditAnalysis: 'Кредитный анализ',
  autoCheck: 'Выполнение автоматических проверок',
  underwriting: 'Андеррайтинг',
  expertRecommendation: 'Рекомендация специалиста',
  expertDecision: 'Принятия решения специалистом',
  HUDecision: 'Принятие решения HU',
  rejectionNotice: 'Уведомление клиента об отказе',
  AMLCheckPassed: 'Проверка AML пройдена',
  onConsideration: 'На рассмотрении Банком',
  rework: 'Доработка заявки',
  preliminary: 'Предварительная заявка',
  additionalDocsRequest: 'Запрос доп. документов',
  additionalInfoRequest: 'Требуется предоставить дополнительную информацию',
  carInPledge: 'авто в залоге (с автоматическим комментарием)',
  changeCarRequest: 'Требуется заменить автомобиль',
  finWarranterRequest: 'Запрос фин. поручителя',
  cancelled: 'Аннулирована',
  altOffer: 'Альт.предложение',
  alternativeOffer: 'Альтернативное предложение',
  approved: 'Одобрено',
  dealExpecting: 'Ожидание сделки',
  approvedRequest: 'Одобрена',
  clientRejection: 'Отказ клиента',
  rejectedRequest: 'Заявка отменена',
  bankRejection: 'Отказ Банка',
  clientRejected: 'Отказ клиенту',
  nationalHunterRejection: 'Отказ по National Hunter',
  negativeCreditHistoryRejection: 'Отказ по негативной кредитной истории',
  AMLRejection: 'Отказ клиенту по AML',
  outdated: 'Просрочено',
  reworkOutdated: 'Просрочена доработка',
  approvalOutdated: 'Просрочено одобрение',
  outdatedRequest: 'Просрочена',
};
