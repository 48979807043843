import React from 'react';
import { Row, Select, Typography } from 'antd';
import IconArrow from './icons/IconArrow';

const { Text } = Typography;

const { Option } = Select;

const DropdownInput = props => {
  const {
    label,
    helperText,
    items = [],
    value,
    placeholder = 'Выберите из списка',
    ...other
  } = props;

  return (
    <div className={'dropdown'}>
      {label && (
        <Row>
          <Text className={'input-label'}>{label}</Text>
        </Row>
      )}
      <Row
        style={{
          paddingTop: label ? 0 : '36px',
        }}
      >
        <Select
          showSearch
          style={{ width: '100%' }}
          dropdownClassName={'dropdown-menu'}
          value={value}
          placeholder={placeholder}
          suffixIcon={<IconArrow />}
          {...other}
        >
          {items.map(item => {
            if (typeof item === 'object') {
              return (
                <Option value={item.value} key={item.key} className={'item'}>
                  {item.value}
                </Option>
              );
            }
            return (
              <Option value={item} key={item} className={'item'}>
                {item}
              </Option>
            );
          })}
        </Select>
      </Row>
      <div className={'helper-text'}>
        {helperText && (
          <Row>
            <Text>{helperText}</Text>
          </Row>
        )}
      </div>
    </div>
  );
};
export default DropdownInput;
