import React from 'react';

export default class IconSend extends React.Component {
  render() {
    return (
      <svg
        width="19"
        height="19"
        viewBox="10 4 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.5203 13.5667L10.968 4.66014C10.4723 4.40861 9.91004 4.86277 10.0524 5.40051L12.1448 13.3072C12.2108 13.5567 12.419 13.7427 12.6742 13.7804L15.1233 14.1421L12.6742 14.5038C12.419 14.5415 12.2107 14.7276 12.1447 14.977L10.0523 22.8837C9.90994 23.4219 10.4727 23.8754 10.968 23.6241L28.5203 14.7175C28.9915 14.4782 28.9894 13.8046 28.5203 13.5667ZM11.668 21.8218L13.2833 15.7181L19.6334 14.7803C19.9499 14.7335 20.1843 14.4619 20.1843 14.142C20.1843 13.8222 19.9499 13.5505 19.6334 13.5038L13.2833 12.566L11.6681 6.46229L26.8027 14.1421L11.668 21.8218Z"
          fill="#004666"
        />
      </svg>
    );
  }
}
