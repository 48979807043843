import React from 'react';

import { Typography, Row } from 'antd';
import IconPlus from './icons/IconPlus';

const { Text } = Typography;

const AddMoreDocBox = props => {
  const { onClick } = props;

  return (
    <Row justify={'center'} align={'middle'} className={'add-doc'}>
      <IconPlus onClick={onClick} />
      <Text>ЕЩЕ ОДИН ДОКУМЕНТ</Text>
    </Row>
  );
};

export default AddMoreDocBox;
