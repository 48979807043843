import React from 'react';
import { inject, observer } from 'mobx-react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

Spin.setDefaultIndicator(Icon);

const LoaderPage = props => {
  return <Spin spinning={props.loaderStore.open}>{props.children}</Spin>;
};

export default inject('loaderStore')(observer(LoaderPage));
