import React from 'react';
import { Input, Row, Typography } from 'antd';

const { Text } = Typography;

const InputBox = props => {
  let { label, suffix, helperText, onChange, paddingTop, ...other } = props;

  const onInput = e => onChange(e.target.value);

  return (
    <div
      className={suffix ? 'input-box suffix' : 'input-box'}
      // style={helperText ? {marginBottom: -30} : {}}
    >
      {label && (
        <Row>
          <Text className={'input-label'}>{label}</Text>
        </Row>
      )}
      <Row
        style={{
          width: '100%',
          paddingTop: label
            ? 0
            : paddingTop !== undefined
            ? paddingTop
            : '36px',
        }}
      >
        <Input addonAfter={suffix} onChange={onInput} {...other} />
      </Row>
      <div className={'helper-text'}>
        {helperText && (
          <Row>
            <Text>{helperText}</Text>
          </Row>
        )}
      </div>
    </div>
  );
};
export default InputBox;
