import React from 'react';

import { Button } from 'antd';

const ButtonSecondaryBox = props => {
  const { children, icon = null, ...other } = props;

  return (
    <div className={'button-secondary'}>
      {icon}
      <Button {...other}>{children}</Button>
    </div>
  );
};

export default ButtonSecondaryBox;
