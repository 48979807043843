import { observable, action, computed } from 'mobx';
import { authStore, requestStore } from 'src/stores/index';

export class GlobalState {
  @observable modes = [
    { name: 'calculator', status: false, label: 'Подбор кредитной программы' },
    { name: 'auth', status: false, label: 'Регистрация' },
    { name: 'passportData', status: false, label: 'Паспортные данные' },
    { name: 'personalData', status: false, label: 'Личные данные' },
    { name: 'incomeData', status: false, label: 'Доходы и занятость' },
    {
      name: 'additionalData',
      status: false,
      label: 'Дополнительная информация',
    },
  ];

  @observable step;
  @observable isRegister = false;
  //todo привязать статус
  @observable formsReadOnly = false;

  @observable requestStatus;

  constructor() {
    this.isDisabledStep = this.isDisabledStep.bind(this);
    this.modeOn('calculator');
  }

  @computed
  get list() {
    return this.modes.map(o => o.label);
  }

  @action
  modeOn(mode) {
    this.modes.forEach((o, i) => {
      if (o.name === mode) {
        this.setStep(i);
      }
    });
  }

  @action.bound
  setStep(step) {
    if (requestStore) {
      requestStore.validateForm(this.activeMode);
    }
    // если пройден шаг Паспортные данные, то его форма блокируется (PRVWDBOR-571)
    this.formsReadOnly = step === 2 && this.isRegister;

    this.step = step;
    this.modes.map((o, i) => {
      return (o.status = i === step);
    });
  }

  @computed
  get activeStep() {
    return this.modes.findIndex(o => o.status);
  }

  @computed
  get activeMode() {
    if (this.modes.find(o => o.status)) {
      return this.modes.find(o => o.status).name;
    } else {
      return '';
    }
  }

  disableForm() {
    return this.activeStep !== this.step;
  }

  @computed
  get isAuth() {
    return authStore.status;
  }

  @action.bound
  goNext() {
    if (this.step === 2) {
      this.isRegister = true;
    }
    // if (this.isAuth && this.step === 0) {
    //   return this.modeOn(this.modes[this.step + 2].name);
    // }
    if (this.step === 5) {
      return;
    }

    this.modeOn(this.modes[this.step + 1].name);
  }

  isDisabledStep(point) {
    // return;
    if (!this.isAuth) {
      if (point === 0 || point === 1) {
        return false;
      }
      return true;
    }
    if (this.isAuth) {
      if (point === 1) {
        return true;
      }

      return;

      // if (!this.isRegister && this.step <= 2) {
      //   return this.step < point;
      // }
    }
  }

  @action.bound
  setStatus(status) {
    this.requestStatus = status;
  }
}

const globalState = new GlobalState();
export default globalState;

//заказчик хотел вынести кнопки из виджета.
//когда виджет будет собираться скриптом перенести в настройки виджета.

window.rework.onclick = () => {
  authStore.showLoginForm();
};
