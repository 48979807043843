import React from 'react';
import Text from 'antd/es/typography/Text';

const RejectInfoBox = () => {
  return (
    <div className={'reject-info'}>
      <Text>
        Ваша заявка может быть рассмотрена при условии предоставления
        финансового поручителя. Для этого Вам необходимо обратиться в выбранный
        ДЦ и подать новую заявку. Поручителю необходимо предоставить аналогичный
        комплект документов.
      </Text>
    </div>
  );
};

export default RejectInfoBox;
