import React from 'react';
import { Row, Col, Form } from 'antd';
import InputNumberBox from '../components/InputNumberBox';
import CheckBox from '../components/CheckBox';
import { required, income, otherIncome } from '../services/validators';
import InputBox from '../components/InputBox';
import TaxRateDropdownBox from 'src/components/TaxRateDropdownBox';
import AdditionalIncomeDropdownBox from 'src/components/AdditionalIncomeDropdownBox';

const SalaryForm = props => {
  const { isOtherAdditionalIncome, additionalIncomeFlag, formDisabled } = props;

  return (
    <>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Form.Item
            validateTrigger={'onBlur'}
            name={'incomesOneMonth'}
            rules={[required, income]}
          >
            <InputNumberBox
              label={'Ежемесячный доход в рублях*'}
              placeholder={'Укажите доход с основного места работы'}
              helperText={'До вычета налогов и других вычетов'}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={6} className={'nowrap'}>
              <Form.Item name={'rateNalog'} rules={[required]}>
                <TaxRateDropdownBox
                  label={'Ставка налогообложения*'}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        gutter={[26, 0]}
        style={{
          marginTop: 16,
          marginBottom: additionalIncomeFlag ? -44 : 0,
        }}
      >
        <Col style={{ width: '100%' }}>
          <Form.Item name={'additionalIncomeFlag'} valuePropName={'checked'}>
            <CheckBox
              small={true}
              label={'Дополнительный доход'}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
      </Row>

      {additionalIncomeFlag && (
        <>
          <Row gutter={[26, 32]}>
            <Col span={12}>
              <Form.Item name={'addIncomesOneMonth'} rules={[required]}>
                <InputNumberBox
                  placeholder={
                    'Укажите сумму ежемесячного дополнительного дохода*'
                  }
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={6} className={'nowrap'}>
                  <Form.Item name={'addrateNalog'} rules={[required]}>
                    <TaxRateDropdownBox
                      label={'Ставка налогообложения(на доп. доход)*'}
                      disabled={formDisabled}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[26, 32]}>
            <Col span={12}>
              <Form.Item name={'sourceOfAdditionalIncome'} rules={[required]}>
                <AdditionalIncomeDropdownBox disabled={formDisabled} />
              </Form.Item>
            </Col>
            {isOtherAdditionalIncome && (
              <Col span={12}>
                <Form.Item
                  name={'sourceOfAdditionalIncomeOther'}
                  rules={[required, otherIncome]}
                >
                  <InputBox
                    placeholder={'Укажите тип дополнительного дохода*'}
                    disabled={formDisabled}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default SalaryForm;
