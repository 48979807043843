import React from 'react';
import { Row, Col, Form } from 'antd';
import InputBox from '../components/InputBox';
import CheckBox from '../components/CheckBox';
import { phone, companyPhone, required } from '../services/validators';
import InputMaskedBox from '../components/InputMaskedBox';
import InputNumberBox from '../components/InputNumberBox';
import EmployeeQtyDropdownBox from 'src/components/EmployeeQtyDropdownBox';

const JobContactsForm = props => {
  const { hasPhoneAdditional, formDisabled, clientPhoneMobile } = props;

  return (
    <>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Row gutter={9}>
            <Col span={12} className={'nowrap'}>
              <Form.Item
                dependencies={['clientState', 'clientCompanyPhoneNumber2']}
                name={'clientCompanyPhoneNumber1'}
                rules={[
                  required,
                  phone,
                  form =>
                    companyPhone(
                      clientPhoneMobile,
                      form.getFieldValue('clientState'),
                      form.getFieldValue('clientCompanyPhoneNumber2')
                    ),
                ]}
                validateTrigger={'onBlur'}
              >
                <InputMaskedBox
                  mask={'+7(111)111-11-11'}
                  label={'Номер телефона работодателя*'}
                  placeholder={'+7(XXX)XXX-XX-XX'}
                  helperText={'Стационарный или мобильный'}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'clientCompanyPhoneInternalNumber1'}>
                <InputNumberBox
                  thousandSeparator={false}
                  helperText={'Добавочный'}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row gutter={9}>
            <Col
              span={24}
              style={{
                width: '100%',
                marginTop: hasPhoneAdditional ? 0 : 44,
                marginBottom: hasPhoneAdditional ? -32 : 0,
              }}
            >
              <Form.Item name={'hasPhoneAdditional'} valuePropName={'checked'}>
                <CheckBox
                  small={true}
                  label={'Дополнительный телефон работодателя'}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
            {hasPhoneAdditional && (
              <>
                <Col span={12}>
                  <Form.Item
                    name={'clientCompanyPhoneNumber2'}
                    rules={[phone]}
                    validateTrigger={'onBlur'}
                  >
                    <InputMaskedBox
                      autoComplete={'nope'}
                      placeholder={'+7(XXX)XXX-XX-XX'}
                      mask={'+7(111)111-11-11'}
                      helperText={'Стационарный или мобильный'}
                      disabled={formDisabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={'clientCompanyPhoneInternalNumber2'}>
                    <InputNumberBox
                      helperText={'Добавочный'}
                      thousandSeparator={false}
                      disabled={formDisabled}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Form.Item name={'webPage'}>
            <InputBox label={'Web-сайт'} disabled={formDisabled} />
          </Form.Item>
        </Col>
        <Col span={6} className={'nowrap'}>
          <Form.Item name={'employeesNumber'} rules={[required]}>
            <EmployeeQtyDropdownBox disabled={formDisabled} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default JobContactsForm;
