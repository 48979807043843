import React from 'react';
import CalculatorInfoBox from './CalculatorInfoBox';
import CollapseBox from './CollapseBox';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const CalculatorCollapseBox = props => {
  const { header, defaultActiveKey } = props;
  return (
    <CollapseBox defaultActiveKey={defaultActiveKey}>
      <Panel key={'1'} header={header}>
        <CalculatorInfoBox />
      </Panel>
    </CollapseBox>
  );
};

export default CalculatorCollapseBox;
