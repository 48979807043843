export const createLeadRequest = ({
  creditData = {},
  authData = {},
  personalData = {},
  passportData = {},
  incomeData = {},
  additionalData = {},
  dealer = {},
  clientAddressList = undefined,
  additionalDocs = undefined,
  comment = undefined,
}) => {
  return {
    appClientParams: {
      firstName: authData.firstName,
      middleName: authData.middleName,
      lastName: authData.lastName,
      mainIdentityDocType: 'PassportRF',
      clientCitizenship: passportData.citizenship,
      mainIdentityDocSeries: passportData.mainIdentityDocSeries,
      mainIdentityDocNumber: passportData.mainIdentityDocNumber,
      mainIdentityDocIssueDate: passportData.mainIdentityDocIssueDate,
      mainIdentityDocIssuedBy: passportData.mainIdentityDocIssuedBy,
      mainIdentityDocIssuerCode: passportData.mainIdentityDocIssuerCode,
      tablAdditional: additionalDocs,
      clientGender: passportData.clientGender,
      clientBirthdate: passportData.clientBirthdate,
      clientBirthcountry: passportData.countryCode,
      clientBirthplace: passportData.clientBirthplace,
      clientEducationType: personalData._clientEducationType,
      clientEducationOther: personalData.clientEducationOther,
      clientMaritalStatus: personalData._clientMaritalStatus,
      amountChild: personalData.amountChild,
      // clientAddressRegType: '',
      clientAddressRegDate: passportData.clientAddressRegDate,
      clientAddressList: clientAddressList,
      clientAddressActualSameAsRegistration:
        passportData.clientAddressActualSameAsRegistration,
      clientPhoneMobile: authData.clientPhoneMobile,
      clientEmail: authData.clientEmail,
      clientPassword: authData.clientPassword,
      clientState: incomeData._clientState,
      clientStateOther: incomeData.clientStateOther,
      clientOfficialPosition: incomeData._clientOfficialPosition,
      clientPosition: incomeData.clientPosition,
      clientCompanyName: incomeData.clientCompanyName,
      employeesNumber: incomeData._employeesNumber,
      clientRelativeIsWorkerVWBR: additionalData.clientRelativeIsWorkerVWBR,
      clientIsWorkerVWBR: false,
      clientCompanyTaxCode: incomeData.clientCompanyTaxCode,
      clientCompanyLineOfBusiness: incomeData._clientCompanyLineOfBusiness,
      clientCompanyTypeOther: incomeData.clientCompanyTypeOther,
      clientProfessionalLengthOfThisJobYear:
        incomeData.clientProfessionalLengthOfThisJobYear,
      clientProfessionalLengthOfThisJobMonths:
        incomeData.clientProfessionalLengthOfThisJobMonths,
      clientProfessionalLengthYear: incomeData.clientProfessionalLengthYear,
      clientProfessionalLengthMonths: incomeData.clientProfessionalLengthMonths,
      clientCompanyPhoneNumber1: incomeData.clientCompanyPhoneNumber1,
      clientCompanyPhoneInternalNumber1:
        incomeData.clientCompanyPhoneInternalNumber1,
      clientCompanyPhoneNumber2: incomeData.clientCompanyPhoneNumber2,
      clientCompanyPhoneInternalNumber2:
        incomeData.clientCompanyPhoneInternalNumber2,
      webPage: incomeData.webPage,
      rateNalog: incomeData.taxRate,
      incomesOneMonth: incomeData.incomesOneMonth,
      sourceOfIncome: 'Доходы от основного места работы',
      addrateNalog: incomeData.addrateNalogValue,
      addIncomesOneMonth: incomeData.addIncomesOneMonth,
      sourceOfAdditionalIncome: incomeData.sourceOfAdditionalIncome,
      sourceOfAdditionalIncomeOther: incomeData.sourceOfAdditionalIncomeOther,
      foreignPublicExecutive: additionalData.foreignPublicExecutive,
      relativeForeignPublicExecutive:
        additionalData.relativeForeignPublicExecutive,
      fioForeignPublicExecutive: additionalData.fioForeignPublicExecutive,
      isDebtorCloseRelative: additionalData.isDebtorCloseRelative,
      foreignPublicCompanyExecutive:
        additionalData.foreignPublicCompanyExecutive,
      stateExecutive: additionalData.stateExecutive,
      bankRFExecutive: additionalData.bankRFExecutive,
      isPersonWhoCanControl: additionalData.isPersonWhoCanControl,
      dealersWhereStocks: additionalData.dealersWhereStocks,
      infoFromSMS: additionalData.infoFromSMS,
      infoFromPush: additionalData.infoFromPush,
      bankruptcyInTheLastFiveYears: additionalData.bankruptcyInTheLastFiveYears,
      accordancePersonInfo: authData.accordancePersonInfo,
      infoFromPromotionalMaterials: authData.infoFromPromotionalMaterials,
      accordanceInformationToBKI: passportData.AccordanceInformationToBKI,
      consentDate: passportData.consentDate,
    },
    loanAppStatus: '',
    dateReceive: new Date(),
    dateSend: new Date(),
    leadID: authData.leadID,
    approvedParams: {},
    comment: comment,
    appGeneralConditions: {
      monthlyPaymentWidget: creditData.monthlyPaymentWidget,
      loanAmountWidget: creditData.loanAmountWidget,
      addServices: creditData.addServices,
      clientCategoryIdPos: '1',
      clientCategoryName: 'Новый',
      carMarkName: creditData.brand,
      carMarkDwhId: creditData.brandID,
      carModelName: creditData.model,
      carModelDwhId: creditData.modelID,
      carAmount: creditData.carCost,
      loanTerm: creditData.creditTerm,
      firstPayment: creditData.beginPayment,
      firstPaymentPercent: creditData.beginPaymentPercent,
      loanAmount: creditData.creditAmountFull,
      restPaymentAmount: creditData.residualPayment,
      restPaymentPercent: creditData.residualPaymentPercent,
      insurancePremiumFromCredit: false,
      insurancePremiumFromCash: true,
      insurancePremiumAmount: '1.00',
      insurancePremiumAmountFromCash: '1.00',
      insurancePremiumCurrency: 'RUB',
      insuranceLife: creditData.insuranceInCredit,
      insuranceLifeProgramPosId: creditData.insuranceLifeProgramPosId,
      insuranceLifeCompanyPosId: creditData.insuranceLifeCompanyPosId,
      insuranceLifeFromCredit: creditData.insuranceLifeFromCredit,
      insuranceLifePremiumAmount: creditData.feeForInsurance,
      carYear: creditData.carYear,
      loanInterestRate: creditData.creditRate,
      baseRateIdPos: creditData.baseRateIdPos,
      monthlyPayment: creditData.monthlyPaymentFull,
      usedCarFlag: false,
      stateSubsidyFlag: creditData.govProgram,
      stateSubsidyType: creditData.subsidyType,
      dealerSalePointCode: dealer.dealerCode,
      dealerSalePointName: dealer.dealerSalePointName,
      dealerCity: dealer.dealerCity,
      bankPartnerName: additionalData.bankPartnerName,
      bankPartnerInn: additionalData.bankPartnerInn,
      carAmountCurrency: 'RUB',
      loanCurrency: 'RUB',
      firstPaymentCurrency: 'RUB',
      insuranceLifePremiumCurrency: 'RUB',
      docsPackage: 'TWO_DOC',
    },
  };
};

export const createPOSRequest = ({
  creditData = {},
  authData = {},
  personalData = {},
  passportData = {},
  incomeData = {},
  additionalData = {},
  dealer = {},
  clientAddressList = undefined,
  additionalDocs = undefined,
  comment = undefined,
}) => {
  return {
    appClientParams: {
      firstName: authData.firstName,
      middleName: authData.middleName,
      lastName: authData.lastName,
      mainIdentityDocType: 'PassportRF',
      clientCitizenship: passportData.citizenship,
      mainIdentityDocSeries: passportData.mainIdentityDocSeries,
      mainIdentityDocNumber: passportData.mainIdentityDocNumber,
      mainIdentityDocIssueDate: passportData.mainIdentityDocIssueDate,
      mainIdentityDocIssuedBy: passportData.mainIdentityDocIssuedBy,
      mainIdentityDocIssuerCode: passportData.mainIdentityDocIssuerCode,
      tablAdditional: additionalDocs,
      clientGender: passportData.clientGender,
      clientBirthdate: passportData.clientBirthdate,
      clientBirthcountry: passportData.countryCode,
      clientBirthplace: passportData.clientBirthplace,
      clientEducationType: personalData._clientEducationType,
      clientEducationOther: personalData.clientEducationOther,
      clientMaritalStatus: personalData._clientMaritalStatus,
      amountChild: personalData.amountChild,
      // clientAddressRegType: '',
      clientAddressRegDate: passportData.clientAddressRegDate,
      clientAddressList: clientAddressList,
      clientAddressActualSameAsRegistration:
        passportData.clientAddressActualSameAsRegistration,
      clientPhoneMobile: authData.clientPhoneMobile,
      clientEmail: authData.clientEmail,
      clientPassword: authData.clientPassword,
      clientState: incomeData._clientState,
      clientStateOther: incomeData.clientStateOther,
      clientOfficialPosition: incomeData._clientOfficialPosition,
      clientPosition: incomeData.clientPosition,
      clientCompanyName: incomeData.clientCompanyName,
      employeesNumber: incomeData._employeesNumber,
      clientRelativeIsWorkerVWBR: additionalData.clientRelativeIsWorkerVWBR,
      clientIsWorkerVWBR: false,
      clientCompanyTaxCode: incomeData.clientCompanyTaxCode,
      clientCompanyLineOfBusiness: incomeData._clientCompanyLineOfBusiness,
      clientCompanyTypeOther: incomeData.clientCompanyTypeOther,
      clientProfessionalLengthOfThisJobYear:
        incomeData.clientProfessionalLengthOfThisJobYear,
      clientProfessionalLengthOfThisJobMonths:
        incomeData.clientProfessionalLengthOfThisJobMonths,
      clientProfessionalLengthYear: incomeData.clientProfessionalLengthYear,
      clientProfessionalLengthMonths: incomeData.clientProfessionalLengthMonths,
      clientCompanyPhoneNumber1: incomeData.clientCompanyPhoneNumber1,
      clientCompanyPhoneInternalNumber1:
        incomeData.clientCompanyPhoneInternalNumber1,
      clientCompanyPhoneNumber2: incomeData.clientCompanyPhoneNumber2,
      clientCompanyPhoneInternalNumber2:
        incomeData.clientCompanyPhoneInternalNumber2,
      webPage: incomeData.webPage,
      rateNalog: incomeData.taxRate,
      incomesOneMonth: incomeData.incomesOneMonth,
      sourceOfIncome: 'Доходы от основного места работы',
      addrateNalog: incomeData.addrateNalogValue,
      addIncomesOneMonth: incomeData.addIncomesOneMonth,
      sourceOfAdditionalIncome: incomeData.sourceOfAdditionalIncome,
      sourceOfAdditionalIncomeOther: incomeData.sourceOfAdditionalIncomeOther,
      foreignPublicExecutive: additionalData.foreignPublicExecutive,
      relativeForeignPublicExecutive:
        additionalData.relativeForeignPublicExecutive,
      fioForeignPublicExecutive: additionalData.fioForeignPublicExecutive,
      isDebtorCloseRelative: additionalData.isDebtorCloseRelative,
      foreignPublicCompanyExecutive:
        additionalData.foreignPublicCompanyExecutive,
      stateExecutive: additionalData.stateExecutive,
      bankRFExecutive: additionalData.bankRFExecutive,
      isPersonWhoCanControl: additionalData.isPersonWhoCanControl,
      dealersWhereStocks: additionalData.dealersWhereStocks,
      infoFromSMS: additionalData.infoFromSMS,
      infoFromPush: additionalData.infoFromPush,
      bankruptcyInTheLastFiveYears: additionalData.bankruptcyInTheLastFiveYears,
      accordancePersonInfo: authData.accordancePersonInfo,
      infoFromPromotionalMaterials: authData.infoFromPromotionalMaterials,
      accordanceInformationToBKI: passportData.AccordanceInformationToBKI,
      consentDate: passportData.consentDate,
    },
    loanAppStatus: '',
    dateReceive: new Date(),
    dateSend: new Date(),
    leadID: authData.leadID,
    approvedParams: {},
    comment: comment,
    appGeneralConditions: {
      clientCategoryIdPos: '1',
      clientCategoryName: 'Новый',
      carMarkName: creditData.brand,
      carMarkDwhId: creditData.brandID,
      carModelName: creditData.model,
      carModelDwhId: creditData.modelID,
      carAmount: creditData.carCost,
      loanTerm: creditData.creditTerm,
      firstPayment: creditData.beginPayment,
      firstPaymentPercent: creditData.beginPaymentPercent,
      loanAmount: creditData.creditAmountFull,
      restPaymentAmount: creditData.residualPayment,
      restPaymentPercent: creditData.residualPaymentPercent,
      insurancePremiumFromCredit: false,
      insurancePremiumFromCash: true,
      insurancePremiumAmount: '1.00',
      insurancePremiumAmountFromCash: '1.00',
      insurancePremiumCurrency: 'RUB',
      insuranceLife: creditData.insuranceInCredit,
      insuranceLifeProgramPosId: creditData.insuranceLifeProgramPosId,
      insuranceLifeCompanyPosId: creditData.insuranceLifeCompanyPosId,
      insuranceLifeFromCredit: creditData.insuranceLifeFromCredit,
      insuranceLifePremiumAmount: creditData.feeForInsurance,
      carYear: creditData.carYear,
      loanInterestRate: creditData.creditRate,
      baseRateIdPos: creditData.baseRateIdPos,
      monthlyPayment: creditData.monthlyPaymentFull,
      usedCarFlag: false,
      stateSubsidyFlag: creditData.govProgram,
      stateSubsidyType: creditData.subsidyType,
      dealerSalePointCode: dealer.dealerCode,
      dealerSalePointName: dealer.dealerSalePointName,
      dealerCity: dealer.dealerCity,
      bankPartnerName: additionalData.bankPartnerName,
      bankPartnerInn: additionalData.bankPartnerInn,
      carAmountCurrency: 'RUB',
      loanCurrency: 'RUB',
      firstPaymentCurrency: 'RUB',
      insuranceLifePremiumCurrency: 'RUB',
      docsPackage: 'TWO_DOC',
    },
  };
};
