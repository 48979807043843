import { action, computed, observable } from 'mobx';
import AddressEntity from 'src/stores/entities/AddressEntity';

export default class PassportEntity {
  @observable store;

  @observable clientCitizenship = true;
  @observable clientGender = 'MALE';
  @observable clientBirthdate;
  @observable _birthCountry = undefined;
  @observable countryCode = 'RU';
  @observable clientBirthplace;
  @observable mainIdentityDocIssueDate;
  @observable mainIdentityDocSeries;
  @observable mainIdentityDocNumber;
  @observable mainIdentityDocIssuedBy;
  @observable mainIdentityDocIssuerCode;
  @observable addressLiving;
  @observable addressLivingRandom;
  @observable addressRegistration;
  @observable addressRegistrationRandom;
  @observable _flatLiving;
  @observable _flatRegister;
  @observable clientAddressRegDate;
  @observable UserAgreement = false;
  @observable AccordanceInformationToBKI = false;
  @observable clientAddressActualSameAsRegistration = true;
  @observable addressRegistrationFIAS = new AddressEntity();
  @observable addressLivingFIAS = new AddressEntity();
  @observable isLivingRandom = false;
  @observable isRegistrationRandom = false;
  _consentDate;

  clientGenderSelect = [
    { value: 'MALE', name: 'МУЖСКОЙ' },
    { value: 'FEMALE', name: 'ЖЕНСКИЙ' },
  ];

  constructor(store) {
    this.store = store;
    this.getCountryName = this.getCountryName.bind(this);
  }

  get consentDate() {
    return (this.AccordanceInformationToBKI && this._consentDate) || new Date();
  }

  set consentDate(date) {
    if (date) {
      this._consentDate = date;
    }
  }

  get citizenship() {
    return this.clientCitizenship ? 'RU' : undefined;
  }

  @action
  async updateFields(data) {
    for (let key in data.appClientParams) {
      const value = data.appClientParams[key];
      if (this.hasOwnProperty(key)) {
        if (key.toLowerCase().includes('date') && value) {
          this[key] = new Date(value);
        } else {
          this[key] = value;
        }
      } else if (key === 'clientAddressList' && value && value.clientAddress) {
        for (let address of value.clientAddress) {
          if (address.type === 'REGISTRATION') {
            this.addressRegistrationFIAS.set(address);
            this.addressRegistration = await this.addressRegistrationFIAS.toString();
          } else if (address.type === 'ACTUAL') {
            this.addressLivingFIAS.set(address);
            this.addressLiving = await this.addressLivingFIAS.toString();
          }
        }
      } else if (key === 'clientBirthcountry' && value) {
        this.countryCode = value;
        this.birthCountry = await this.getCountryName();
      }
    }
    if (data.signedDocs) {
      for (let doc of data.signedDocs.signedDoc) {
        this[doc.type] = true;
      }
    }
  }

  set birthCountry(args) {
    this._birthCountry = args;
  }

  @computed
  get birthCountry() {
    return this._birthCountry === undefined ? 'Россия' : this._birthCountry;
  }

  @action.bound
  setCountryCode(code) {
    this.countryCode = code;
  }

  getCountryName() {
    return this.store.getCountryName(this.countryCode);
  }

  @action.bound
  toggleLivingRandomFlag() {
    this.isLivingRandom = !this.isLivingRandom;
    this.addressLivingFIAS.fias(this.isLivingRandom);
  }

  @action.bound
  toggleRegistrationRandomFlag() {
    this.isRegistrationRandom = !this.isRegistrationRandom;
    this.addressRegistrationFIAS.fias(this.isRegistrationRandom);
  }

  @action.bound
  setClientGender(v) {
    if (v && v !== 'UNKNOWN') {
      this.clientGender = v;
    }
  }

  @action.bound
  setDepartment(v) {
    this.mainIdentityDocIssuedBy = v;
  }

  @action.bound
  onPassportFieldChange(info) {
    const changedFields = info.changedFields[0];
    if (!changedFields) {
      return;
    }

    const field = changedFields.name[0];
    const value = changedFields.value;

    if (field === 'flatLiving') {
      this.addressLivingFIAS.addFlat(value);
    } else if (field === 'flatRegister') {
      this.addressRegistrationFIAS.addFlat(value);
    } else if (field === 'addressRegistrationRandom') {
      this.addressRegistrationFIAS.addComment(value);
    } else if (field === 'addressLivingRandom') {
      this.addressLivingFIAS.addComment(value);
    } else if (field === 'mainIdentityDocIssuerCode') {
      info.forms.PassportData.validateFields(['mainIdentityDocIssuedBy']);
    }

    if (this.hasOwnProperty(field)) {
      this[field] = value;
    }
  }

  @action.bound
  setAddressRegistrationFIAS(v) {
    this.addressRegistrationFIAS.clean();
    v && this.addressRegistrationFIAS.update('REGISTRATION', v.data);
  }

  @action.bound
  setAddressLivingFIAS(v) {
    this.addressLivingFIAS.clean();
    v && this.addressLivingFIAS.update('ACTUAL', v.data);
  }

  set flatRegister(v) {
    this._flatRegister = v;
  }

  @computed
  get flatRegister() {
    return this._flatRegister || this.addressRegistrationFIAS.flat;
  }

  set flatLiving(v) {
    this._flatLiving = v;
  }

  @computed
  get flatLiving() {
    return this._flatLiving || this.addressLivingFIAS.flat;
  }
}
