import { observable, action } from 'mobx';

export class HelperStore {
  @observable open = false;
  @observable method = 'defaultMessage';
  @observable buttons = null;
  @observable btnLabels = {};
  messageBox = null;

  constructor() {
    this.onClose = this.onClose.bind(this);
    this.onOpen = this.onOpen.bind(this);
  }

  @action
  onClose() {
    this.open = false;
    this.messageBox = null;
  }

  @action
  onOpen({
    message,
    method = 'defaultMessage',
    buttons = null,
    btnLabels = {},
  }) {
    this.open = true;
    this.messageBox = message;
    this.method = method;
    this.buttons = buttons;
    this.btnLabels = btnLabels;
  }
}

const helperStore = new HelperStore();
export default helperStore;
