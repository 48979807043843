export default {
  'Первый автомобиль': [
    'Ранее Вы не заключали кредитных договоров на приобретение автомобиля',
    'До настоящего момента Вы не имели в собственности автомобиль',
    'У Вас есть водительское удостоверение',
  ],
  'Семейный автомобиль': [
    'Вы не заключали с 01.01.2019 года иных кредитных договоров на приобретение автомобиля',
    'Вы имеете одного и более несовершеннолетних детей',
    'У Вас есть водительское удостоверение',
  ],
  Медработник: [
    'Вы не заключали с 01.01.2019 года иных кредитных договоров на приобретение автомобиля',
    'У вас есть справка с работы (2-НДФЛ или по форме Банка), подтверждающая, что вы являетесь работником государственной системы здравоохранения',
    'У Вас есть водительское удостоверение',
  ],
  'Trade-in': [
    'Вы не заключали с 01.01.2019 года иных кредитных договоров на приобретение автомобиля',
    'Вы продаете Дилеру свой автомобиль возрастом свыше 6 лет при условии владения им не менее 1 года (согласно ПТС) в счет уплаты части первоначального взноса по новому автомобилю',
    'У Вас есть водительское удостоверение',
  ],
};
