import React from 'react';
import { Steps } from 'antd';
import { inject, observer } from 'mobx-react';

const { Step } = Steps;

const StepBox = props => {
  const { globalState } = props;
  const { setStep, activeStep, list, isDisabledStep } = globalState;

  return (
    <div className={'step-box'}>
      <Steps
        onChange={setStep}
        current={activeStep}
        labelPlacement={'vertical'}
        className="site-navigation-steps"
      >
        {list.map((item, i) => {
          return (
            <Step
              icon={i + 1}
              title={item}
              key={item}
              disabled={isDisabledStep(i)}
            />
          );
        })}
      </Steps>
    </div>
  );
};
export default inject('globalState')(observer(StepBox));
