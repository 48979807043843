import React from 'react';
import { Row } from 'antd';
import Text from 'antd/es/typography/Text';

const NoteBox = () => {
  return (
    <Row className={'helper-text info-text'}>
      <Text>*Поля, отмеченные звездочкой, обязательны к заполнению</Text>
    </Row>
  );
};

export default NoteBox;
