import React from 'react';
import { Form } from 'antd';
import CheckBoxWithLink from '../components/CheckBoxWithLink';

const ConsentNoticeForm = props => {
  const { formDisabled } = props;
  return (
    <>
      <Form.Item valuePropName={'checked'} name={'infoFromSMS'}>
        <CheckBoxWithLink
          text={
            'Я согласен(а) получать SMS-уведомления на указанный мобильный телефон'
          }
          disabled={formDisabled}
        />
      </Form.Item>
      <Form.Item valuePropName={'checked'} name={'infoFromPush'}>
        <CheckBoxWithLink
          text={'Я согласен(а) на получение push-уведомлений из приложения'}
          disabled={formDisabled}
        />
      </Form.Item>
    </>
  );
};

export default ConsentNoticeForm;
