import React from 'react';
import { Row, Col, Form } from 'antd';
import InputBox from '../components/InputBox';
import DaDataBox from '../components/DaDataBox';
import { email, name, required } from '../services/validators';
import requestStore from 'src/stores/RequestStore';

const UserDataForm = props => {
  const { passportEntity } = requestStore;
  const { setClientGender } = passportEntity;
  const { formDisabled } = props;

  const setSexFn = e => setClientGender(e ? e.data.gender : '');
  return (
    <>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Form.Item
            name={'lastName'}
            rules={[required, name]}
            validateTrigger={'onBlur'}
          >
            <DaDataBox
              service={'fio'}
              specialRequestOptions={{ parts: 'SURNAME' }}
              label={'Фамилия*'}
              mutationFn={setSexFn}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'firstName'}
            rules={[required, name]}
            validateTrigger={'onBlur'}
          >
            <DaDataBox
              service={'fio'}
              specialRequestOptions={{ parts: 'NAME' }}
              label={'Имя*'}
              mutationFn={setSexFn}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Form.Item
            name={'middleName'}
            rules={[name]}
            validateTrigger={'onBlur'}
          >
            <DaDataBox
              service={'fio'}
              specialRequestOptions={{ parts: 'PATRONYMIC' }}
              label={'Отчество (* при наличии такового, как в паспорте)'}
              helperText={'Укажите Ваше отчество, если имеется'}
              mutationFn={setSexFn}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'clientEmail'}
            rules={[email]}
            validateTrigger={'onBlur'}
          >
            <InputBox
              label={'E-mail'}
              helperText={'Введите адрес Вашей электронной почты'}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default UserDataForm;
