import React from 'react';
import { Typography, Col, Row, Switch } from 'antd';

const { Text } = Typography;

const SwitchBox = props => {
  const { label, visible = true, ...other } = props;

  if (!visible) {
    return null;
  }

  return (
    <Row gutter={18} className={'switch'} justify={'space-between'}>
      <Col className={'switch-label'}>
        <Text>{label}</Text>
      </Col>
      <Col>
        Нет <Switch style={{ marginLeft: 8 }} {...other} /> Да
      </Col>
    </Row>
  );
};

export default SwitchBox;
