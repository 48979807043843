import Text from 'antd/es/typography/Text';
import React from 'react';

const QuestionnaireConfirmationInfoBox = () => {
  return (
    <div className={'confirmation-info'}>
      <Text>
        <strong>Заполнив и подписав настоящую Анкету:</strong>
        <br />
        <br />
        <strong>Я подтверждаю</strong> достоверность сведений обо мне, указанных
        в настоящей Анкете. <br />
        <strong>Я подтверждаю</strong>, что все сведения, содержащиеся в
        настоящей Анкете, а также все затребованные Банком сведения и документы
        предоставлены исключительно для получения кредита, в том числе с целью
        принятия Банком решения о предоставлении кредита. Документы,
        предоставленные мной Банку для получения кредита, в том числе с целью
        принятия Банком решения о предоставлении кредита, обратно мне не
        возвращаются. <br />
        <strong>Я соглашаюсь</strong> с условиями предоставления, использования
        и возврата кредита, указанными в настоящей Анкете.
        <br />
        <strong>Я соглашаюсь</strong> с оказанием мне дополнительных услуг,
        оказываемых Банком и/или третьими лицами, указанными в настоящей Анкете.{' '}
        <br />
        <strong>Я подтверждаю</strong>, что Банк проинформировал меня, что, если
        в течение 1 (одного) года общий размер платежей по всем имеющимся у меня
        на дату обращения к Банку о предоставлении
        потребительского кредита обязательствам по кредитным договорам,
        договорам займа, включая платежи по предоставляемому потребительскому
        кредиту (займу), будет превышать 50% (пятьдесят процентов) моего
        годового дохода, для меня существует риск неисполнения мною обязательств
        по договору потребительского кредита (займа) и применения ко мне
        штрафных санкций.
      </Text>
    </div>
  );
};

export default QuestionnaireConfirmationInfoBox;
