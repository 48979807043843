import React from 'react';

import { Col, Row, Typography } from 'antd';

const { Text } = Typography;

const ButtonsTopBox = props => {
  const { buttons, active, onClick, disabled } = props;

  const getClass = value => {
    if (value === active) {
      return 'button active';
    }
    return 'button';
  };

  return (
    <Row className={'navigate-buttons'}>
      {buttons.map(o => (
        <Col
          className={getClass(o.value)}
          key={o.value}
          onClick={() => !disabled && onClick(o.value)}
        >
          <Text>{o.name}</Text>
        </Col>
      ))}
      <Col
        flex="auto"
        style={{
          height: '100%',
          borderBottom: '1px solid #A8ADB3',
        }}
      />
    </Row>
  );
};

export default ButtonsTopBox;
