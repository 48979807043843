import React from 'react';
import { Popover } from 'antd';
import IconInfo from './icons/IconInfo';

const SliderSuffix = props => {
  const { value } = props;

  return (
    <>
      <span style={{ color: 'inherit', paddingRight: 4 }}>{` ₽ (${
        value ? value : 20
      }%`}</span>
      <Popover
        className={'slider'}
        placement="topLeft"
        content={'Значение в процентах от стоимости автомобиля'}
        trigger="click"
      >
        <IconInfo />
      </Popover>
      <span style={{ color: 'inherit' }}>)</span>
    </>
  );
};

export default SliderSuffix;
