import React from 'react';
import { Layout } from 'antd';
import HeadersBox from './HeadersBox';

const MainLayout = props => (
  <Layout
    style={{
      border: '1px solid grey',
      padding: 32,
      backgroundColor: 'white',
    }}
  >
    <HeadersBox />
    {props.children}
  </Layout>
);

export default MainLayout;
