import { observable } from 'mobx';
import api from '../../services/api';

export default class AddressEntity {
  @observable type;
  @observable house;

  notFiasFlag;
  notFiasComment;
  flat;

  constructor() {
    this.toString = this.toString.bind(this);
  }

  update(type, addressData) {
    if (!type || !addressData) {
      return;
    }

    this.type = type;
    if (addressData) {
      Object.keys(addressData).forEach(key => {
        if (AddressEntity.fields.hasOwnProperty(key)) {
          this[AddressEntity.fields[key]] = addressData[key];
          if (
            key === 'region_type_full' ||
            key === 'city_type_full' ||
            key === 'street_type_full' ||
            key === 'settlement_type_full'
          ) {
            this[AddressEntity.fields[key]] =
              typeof this[AddressEntity.fields[key]] === 'string'
                ? this[AddressEntity.fields[key]]
                    .replaceAll(' ', '_')
                    .toUpperCase()
                : this[AddressEntity.fields[key]];
          }
        }
      });
    }
  }

  set(address) {
    for (let key in address) {
      this[key] = address[key];
    }
  }

  clean() {
    for (let field in AddressEntity.fields) {
      this[field] = undefined;
    }
  }

  async toString() {
    const { city, town, street, house } = this;
    const place = city || town;

    if (!place || !street || !house) {
      return '';
    }

    const result = await AddressEntity.getAddress(
      `${place} ${street} ${house}`
    );
    return result;
  }

  addFlat(v) {
    this.flat = v;
  }

  addComment(address) {
    this.notFiasComment = address;
  }

  fias(flag) {
    this.notFiasFlag = flag;
  }
}

AddressEntity.fields = {
  postal_code: 'postalCode',
  region: 'region',
  region_type_full: 'regionType',
  city_type_full: 'cityType',
  city: 'city',
  street: 'street',
  street_type_full: 'streetType',
  house: 'house',
  block: 'building',
  street_kladr_id: 'streetCode',
  settlement: 'town',
  settlement_type_full: 'townType',
};

AddressEntity.getAddress = async string => await api.getAddressString(string);
