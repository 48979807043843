import React from 'react';
import { DatePicker, Row, Typography } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ru_RU';
import IconCalendar from './icons/IconCalendar';

const { Text } = Typography;
const dateFormat = 'DD.MM.YYYY';

const DatePickerBox = props => {
  const { label, value, onChange, ...other } = props;
  const onSelect = momentInst => {
    //хак для учета часового пояса и корректного преобразования к строке
    const date = new Date(momentInst.format('YYYY-MM-DD'));
    const deltaDate = moment(date);
    onChange(new Date(deltaDate.format('YYYY-MM-DDTHH:mm:ss')));
  };

  return (
    <div
      className={'date-picker'}
      style={{
        width: '100%',
        paddingTop: label ? 0 : '36px',
      }}
    >
      <Row>
        {label && <Text className={'input-label'}>{label}</Text>}
        <DatePicker
          locale={locale}
          autoComplete={'disabled'}
          format={dateFormat}
          suffixIcon={<IconCalendar />}
          value={value ? moment(value, dateFormat) : undefined}
          placeholder={'ДД.ММ.ГГГГ'}
          onChange={onSelect}
          {...other}
        />
      </Row>
    </div>
  );
};
export default DatePickerBox;
