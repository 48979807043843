import React from 'react';
import InputDropdownBox from 'src/components/InputDropdownBox';
import useStores from 'src/hooks/useStores';

const DropdownDocumentsBox = props => {
  const { requestStore } = useStores();
  const { personalEntity } = requestStore;
  const { docTypeList } = personalEntity;
  return (
    <InputDropdownBox
      items={docTypeList.map(o => o.label)}
      label={'Второй документ*'}
      placeholder="Выберите из списка"
      {...props}
    />
  );
};
export default DropdownDocumentsBox;
