import React from 'react';
import { Typography, Row, Checkbox, Col } from 'antd';
import LinkText from './LinkText';

const { Text } = Typography;

const CheckBoxWithLink = props => {
  const { text, linkText, to, ...other } = props;
  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <div className={'checkbox'}>
          <Checkbox className={'checkbox'} {...other}>
            <Row className={'checkbox-label'}>
              <Text>{text}</Text>
              {linkText && <LinkText href={to}>{linkText}</LinkText>}
            </Row>
          </Checkbox>
        </div>
      </Col>
    </Row>
  );
};
export default CheckBoxWithLink;
