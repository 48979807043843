import { Col, Row } from 'antd';
import React from 'react';
import SwitchBox from './SwitchBox';

const QuestionSwitchBox = props => {
  const { className, ...other } = props;

  return (
    <Row gutter={[0, 40]} className={className}>
      <Col span={24}>
        <SwitchBox {...other} />
      </Col>
    </Row>
  );
};
export default QuestionSwitchBox;
