import React from 'react';
import { Col, Typography } from 'antd';

const { Text } = Typography;

const StatusRow = props => {
  const { status, color } = props;

  return (
    <Col className={'status-text'}>
      <Text className={'status-text-title'}>Статус заявки: </Text>
      <Text className={'status-text-name'} style={{ color }}>
        {status}
      </Text>
    </Col>
  );
};

export default StatusRow;
