import React, { useEffect } from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import useStores from 'src/hooks/useStores';
import { calculatorStore } from 'src/stores';

const RequestPageLayout = props => {
  const { requestStore, authStore } = useStores();
  const { children } = props;
  const { onCalculatorFieldChange } = calculatorStore.calculatorEntity;

  const { onAuthFieldChange } = authStore.authEntity;

  const { onPassportFieldChange } = requestStore.passportEntity;

  const { onPersonalFieldChange } = requestStore.personalEntity;

  const { onIncomeFieldChange } = requestStore.incomeEntity;

  const { onAdditionalFieldChange } = requestStore.additionalInfoEntity;

  const { onDealerFieldChange } = requestStore.dealerEntity;

  useEffect(() => {
    const refresh = async () => await requestStore.refreshForms();
    refresh();
  }, [requestStore]);

  const onFinish = form => {
    if (form === 'AdditionalData') {
      calculatorStore.checkSubsidy();
    }
  };

  return (
    <Form.Provider
      onFormFinish={onFinish}
      onFormChange={(form, info) => {
        onCalculatorFieldChange(info);
        onAuthFieldChange(info);
        onPassportFieldChange(info);
        onIncomeFieldChange(info);
        onPersonalFieldChange(info);
        onAdditionalFieldChange(info);
        onDealerFieldChange(info);
      }}
    >
      {children}
    </Form.Provider>
  );
};

export default observer(RequestPageLayout);
