import React from 'react';
import { observer } from 'mobx-react';
import { Col, Form, Row } from 'antd';
import ButtonsTopBox from '../components/ButtonsTopBox';
import ButtonPrimaryBox from '../components/ButtonPrimaryBox';
import CalculationResultBox from '../components/CalculationResultBox';
import CreditConditionsForm from '../forms/CreditConditionsForm';
import CarPropertiesForm from '../forms/CarPropertiesForm';
import useStores from 'src/hooks/useStores';
import RequestPageLayout from 'src/layouts/RequestPageLayout';

const CalculatorPage = () => {
  const { calculatorStore, globalState } = useStores();
  const { formsReadOnly } = globalState;
  const {
    calculatorEntity,
    brands,
    models,
    onSubmit,
    creditPrograms,
    reCalc,
  } = calculatorStore;
  const {
    creditProgram,
    setCreditProgram,
    brand,
    setBrand,
    model,
    setModel,
    carCost,
    setCost,
    toggleGovProgram,
    insuranceInCredit,
    toggleInsurance,
    govProgram,
    beginPayment,
    beginPaymentMax,
    beginPaymentMin,
    residualPayment,
    residualPaymentMin,
    residualPaymentMax,
    beginPaymentPercent,
    residualPaymentPercent,
    calcDisabled,
    creditTerm,
    creditTermMax,
    creditTermMin,
    isResidualProgram,
    govProgramName,
    govProgramDisabled,
    setGovProgram,
    subsidyPossibility,
    creditTermMultiplicity,
    setCreditTerm,
  } = calculatorEntity;

  const onFinish = () => {
    onSubmit();
  };

  return (
    <>
      <RequestPageLayout>
        <Form
          name={'calculator'}
          onFinish={onFinish}
          initialValues={{
            govProgram,
            govProgramName,
            carCost,
            creditTerm,
            beginPayment,
            residualPayment,
          }}
          fields={[
            { name: 'govProgram', value: govProgram },
            { name: 'creditTerm', value: creditTerm },
            { name: 'beginPayment', value: beginPayment },
            { name: 'residualPayment', value: residualPayment },
          ]}
        >
          <ButtonsTopBox
            buttons={creditPrograms}
            onClick={setCreditProgram}
            active={creditProgram}
            disabled={formsReadOnly}
          />
          <CarPropertiesForm
            carCost={carCost}
            brand={brand}
            model={model}
            setModel={setModel}
            setBrand={setBrand}
            toggleGovProgram={toggleGovProgram}
            govProgram={govProgram}
            setGovProgram={setGovProgram}
            govProgramDisabled={govProgramDisabled}
            subsidyPossibility={subsidyPossibility}
            brands={brands}
            models={models}
            setCost={setCost}
            formDisabled={formsReadOnly}
          />

          <Row gutter={[40, 0]}>
            <Col span={12}>
              <CreditConditionsForm
                beginPaymentMax={beginPaymentMax}
                beginPaymentMin={beginPaymentMin}
                carCost={carCost}
                residualPaymentMin={residualPaymentMin}
                residualPaymentMax={residualPaymentMax}
                beginPaymentPercent={beginPaymentPercent}
                residualPaymentPercent={residualPaymentPercent}
                calcDisabled={calcDisabled}
                insuranceInCredit={insuranceInCredit}
                creditTermMax={creditTermMax}
                creditTermMin={creditTermMin}
                isResidualProgram={isResidualProgram}
                toggleInsurance={toggleInsurance}
                creditTermMultiplicity={creditTermMultiplicity}
                formDisabled={formsReadOnly}
                setCreditTerm={setCreditTerm}
              />
            </Col>
            <Col span={12}>
              <CalculationResultBox formDisabled={formsReadOnly} />
            </Col>
          </Row>
          <Form.Item className={'calculator-request-button'}>
            {reCalc ? (
              <ButtonPrimaryBox
                htmlType={'submit'}
                disabled={calcDisabled || formsReadOnly}
              >
                СОХРАНИТЬ
              </ButtonPrimaryBox>
            ) : (
              <ButtonPrimaryBox
                htmlType={'submit'}
                disabled={calcDisabled || formsReadOnly}
              >
                ПОДАТЬ ЗАЯВКУ
              </ButtonPrimaryBox>
            )}
          </Form.Item>
        </Form>
      </RequestPageLayout>
    </>
  );
};

export default observer(CalculatorPage);
