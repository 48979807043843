import React from 'react';
import { Col, Form, Row, Switch, Typography } from 'antd';
import InputBox from './InputBox';
import { observer } from 'mobx-react';
import useStores from 'src/hooks/useStores';
import { required } from '../services/validators';

const { Text } = Typography;

const RelativeQuestionBox = props => {
  const { requestStore } = useStores();
  const { additionalInfoEntity } = requestStore;
  const { setRelativeName, setRelativeType } = additionalInfoEntity;
  const { label, value, className, ...other } = props;

  return (
    <Row gutter={[0, 40]} className={className}>
      <Col span={24}>
        <Row gutter={18} className={'switch'} justify={'space-between'}>
          <Col className={'switch-label'} span={12}>
            <Text>{label}</Text>
          </Col>
          <Col className={'qa-inputs'} span={11}>
            <div>
              Нет{' '}
              <Switch style={{ marginLeft: 8 }} {...other} checked={value} /> Да
            </div>
            {value && (
              <>
                <Form.Item name={'relativeName'} rules={[required]}>
                  <InputBox
                    placeholder={'Укажите ФИО публичного лица'}
                    onChange={setRelativeName}
                  />
                </Form.Item>
                <Form.Item name={'relativeType'} rules={[required]}>
                  <InputBox
                    placeholder={'Укажите степень родства'}
                    onChange={setRelativeType}
                  />
                </Form.Item>
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default observer(RelativeQuestionBox);
