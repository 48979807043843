import React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'antd';
import UploadBox from '../components/UploadBox';
import UploadAdditionalBox from 'src/components/UploadAdditionalBox';

const UploadModal = props => {
  const { requestStore } = props;
  const { uploadShow, closeUpload } = requestStore;
  const { personalEntity, fileStore } = requestStore;
  const { docType, secondDocId } = personalEntity;

  return (
    <Modal
      className={'modal upload-modal'}
      centered
      visible={uploadShow}
      title={'Скан или фото. Максимальный размер 5Мб. Тип файла: JPG, PDF'}
      footer={null}
      onCancel={closeUpload}
      width={600}
    >
      <UploadBox label={'Паспорт'} id={'1'} fileStore={fileStore} />
      <UploadBox label={docType} id={secondDocId} fileStore={fileStore} />
      <UploadAdditionalBox />
    </Modal>
  );
};

export default inject('requestStore')(observer(UploadModal));
