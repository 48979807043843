import React from 'react';

import { Row, Typography } from 'antd';
import CalculatorLineBox from './CalculatorLineBox';
import formatters from '../services/formatters';
import { inject, observer } from 'mobx-react';

const { Text } = Typography;

const CalculationResultBox = props => {
  const { calculatorStore } = props;
  const {
    creditAmount,
    monthlyPayment,
    creditRate,
  } = calculatorStore.calculatorEntity;

  return (
    <div className={'result-box'}>
      <Row>
        <Text className={'title calculator-result-text-bold'}>
          {'Результаты расчета:'}
        </Text>
      </Row>
      <CalculatorLineBox
        label={'Ежемесячный платеж:'}
        value={formatters.toMoney(monthlyPayment)}
        disabled={!monthlyPayment}
      />
      <CalculatorLineBox
        label={'Сумма кредита:'}
        value={formatters.toMoney(creditAmount)}
        disabled={!creditAmount}
      />
      <CalculatorLineBox
        label={'Процентная ставка:'}
        value={formatters.toPercent(creditRate)}
        additional={' годовых'}
        disabled={!creditRate}
      />
    </div>
  );
};

export default inject('calculatorStore')(observer(CalculationResultBox));
