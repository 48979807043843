import Text from 'antd/es/typography/Text';
import React from 'react';

const SubsidyDocumentWarningMessage = () => {
  return (
    <>
      <Text style={{ color: '#004666' }}>
        <b>Обратите внимание! </b>
      </Text>
      <Text>
        Вы выбрали госпрограмму. Для продолжения оформления заявки по
        госпрограмме необходимо указать водительское удостоверение.
      </Text>
    </>
  );
};

export default SubsidyDocumentWarningMessage;
