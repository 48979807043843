import { action, computed, observable } from 'mobx';
import subsidyConditionsList from 'src/stores/lists/subsidyConditionsList';
import { subsidyNameList } from '../../lists/lists';

export default class CalculatorEntity {
  @observable store;

  @observable creditProgram = 'С_остаточным_платежом';
  @observable _beginPayment = 0;
  @observable _residualPayment = 0;
  @observable beginPaymentMin;
  @observable beginPaymentMax;
  @observable residualPaymentMin;
  @observable residualPaymentMax;
  @observable carCost = undefined;
  @observable govProgram = false;
  @observable govProgramName;
  @observable insuranceInCredit = true;
  @observable brand = undefined;
  @observable model = undefined;
  @observable _creditTerm = 0;
  @observable creditTermMin = 12;
  @observable creditTermMax = 60;
  @observable _carYear = undefined;
  @observable _creditAmount = undefined;
  @observable _creditRate = undefined;
  @observable _monthlyPayment = undefined;
  @observable feeForInsurance = undefined;
  @observable cascoInCredit = true;
  @observable _subsidyPossibility = false;
  @observable monthlyPaymentFull = undefined;
  @observable creditAmountFull = undefined;
  @observable finished = false;
  @observable calculated;
  @observable monthlyPaymentWidget;
  @observable loanAmountWidget;
  @observable addServices;
  @observable addServiceId;
  @observable addServiceAmount;
  @observable addServiceFromCredit;

  baseRateIdPos;
  insuranceCompanyPosId;
  insuranceProgramPosId;

  creditTermMultiplicity = 6;

  useType = 1;

  subsidyNameList = subsidyNameList;

  @computed
  get creditAmount() {
    return !this.calcDisabled && this._creditAmount;
  }

  set creditAmount(v) {
    return (this._creditAmount = v);
  }

  @computed
  get creditRate() {
    return !this.calcDisabled && this._creditRate;
  }

  set creditRate(v) {
    return (this._creditRate = v);
  }

  @computed
  get monthlyPayment() {
    return !this.calcDisabled && this._monthlyPayment;
  }

  set monthlyPayment(v) {
    return (this._monthlyPayment = v);
  }

  get insuranceLifeFromCredit() {
    return this.insuranceInCredit || '';
  }

  constructor(store) {
    this.store = store;
  }

  updateFields(data) {
    this.calculated = true;
    if (data.appGeneralConditions) {
      this.brand = data.appGeneralConditions.carMarkName;
      this.model = data.appGeneralConditions.carModelName;
      this.carCost = +data.appGeneralConditions.carAmount;
      this._creditTerm = +data.appGeneralConditions.loanTerm;
      this.beginPayment = +data.appGeneralConditions.firstPayment;
      this.residualPayment = +data.appGeneralConditions.restPaymentAmount;
      this.creditAmount = +data.appGeneralConditions.loanAmountWidget;
      this.creditRate = +data.appGeneralConditions.loanInterestRate;
      this.monthlyPayment = +data.appGeneralConditions.monthlyPaymentWidget;
      this.insuranceInCredit = data.appGeneralConditions.insuranceLife;
    }
  }

  @action
  updateCalculation = (result, isLoad = false) => {
    this.status = true;

    if (isLoad) {
      Object.keys(result).forEach(k => {
        if (this.isNotEmpty(result[k])) {
          this[k] = result[k];
        }
      });
    }
    if (result) {
      Object.keys(result).forEach(k => {
        if (this.isNotEmpty(result[k])) {
          if (!this.store.localChanges.has(k)) {
            this[k] = result[k];
          }
        }
      });
    }
    if (result.documents) {
      this.store.setDocuments(result.documents);
    }
    this.calculated = result.calculated;
    this.beginPaymentMin = result.beginPaymentMin;
    this.beginPaymentMax = result.beginPaymentMax;
    this.residualPaymentMin = result.residualPaymentMin;
    this.residualPaymentMax = result.residualPaymentMax;
    this.creditTermMin = result.creditTermMin;
    this.creditTermMax = result.creditTermMax;
    this.monthlyPaymentFull = result.monthlyPaymentFull;
    this.creditAmountFull = result.creditAmountFull;

    if (result.legalAssistanceAmount !== 0) {
      this.monthlyPaymentWidget = this.monthlyPayment;
      this.loanAmountWidget = this.creditAmount;
      this.addServiceId = 6;
      this.addServiceAmount = result.legalAssistanceAmount;
      this.addServiceFromCredit = true;
      this.addServices = {
        addService: [
          {
            addServiceId: this.addServiceId,
            addServiceAmount: this.addServiceAmount,
            addServiceFromCredit: this.addServiceFromCredit,
            classType: 'java.util.HashMap',
          },
        ],
        classType: 'java.util.ArrayList',
      };
    } else {
      this.monthlyPaymentWidget = undefined;
      this.loanAmountWidget = undefined;
      this.addServices = undefined;
      this.addServiceId = undefined;
      this.addServiceAmount = undefined;
      this.addServiceFromCredit = undefined;
    }

    let { beginPayment, residualPayment, creditTerm } = {};

    if (this.creditTerm < result.creditTermMin) {
      creditTerm = result.creditTermMin;
    } else if (this.creditTerm > result.creditTermMax) {
      creditTerm = result.creditTermMax;
    }
    if (creditTerm < 0) {
      creditTerm = 0;
    }

    if (this.beginPayment < result.beginPaymentMin) {
      beginPayment = result.beginPaymentMin;
    } else if (this.beginPayment > result.beginPaymentMax) {
      beginPayment = result.beginPaymentMax;
    }
    if (beginPayment < 0) {
      beginPayment = 0;
    }
    if (this.residualPayment < result.residualPaymentMin) {
      residualPayment = result.residualPaymentMin;
    } else if (this.residualPayment > result.residualPaymentMax) {
      residualPayment = result.residualPaymentMax;
    }
    if (residualPayment < 0) {
      residualPayment = 0;
    }

    if (creditTerm !== undefined) {
      this.creditTerm = creditTerm;
    }
    if (beginPayment !== undefined) {
      this.beginPayment = beginPayment;
    }
    if (residualPayment !== undefined) {
      this.residualPayment = residualPayment;
    }
  };

  @computed
  get govProgramDisabled() {
    return (
      this.carCost === undefined || this.carCost > 1.5e6 || this.useType !== 1
    );
  }

  @action.bound
  setCost(v) {
    this.carCost = isNaN(v) ? 0 : v;
    if (this.govProgramDisabled) {
      this.govProgram = false;
    }
    this.store.onCalc('carCost');
  }

  @action.bound
  setBrand(brand) {
    this.model = undefined;
    this.brand = brand;
    this.store.onCalc('brand');
  }

  @action.bound
  setModel(model) {
    this.model = model;
    this.store.onCalc('model');
    this.store.getSubsidyPossibility();
  }

  get brandID() {
    const find = this.store.brandsAll.find(brand => brand.brand === this.brand);
    return find ? find.id : undefined;
  }

  get modelID() {
    const modelArr =
      this.brand && this.store.modelsAll[this.brand]
        ? this.store.modelsAll[this.brand]
        : [];
    const find = modelArr.find(model => model.model === this.model);
    return find ? find.id : undefined;
  }

  set creditTerm(v) {
    let value = parseFloat(v);
    value = isNaN(value) ? 0 : value - (value % this.creditTermMultiplicity);
    this._creditTerm =
      value > this.creditTermMax
        ? this.creditTermMax
        : value < this.creditTermMin
        ? this.creditTermMin
        : value;
  }

  @action.bound
  setCreditTerm(v) {
    this.creditTerm = v;
    this.store.runCalc('creditTerm');
  }

  @action.bound
  setCreditProgram(value) {
    this.creditProgram = value;
    this.store.onCalc('creditProgram');
  }

  @computed
  get beginPayment() {
    return this.calcDisabled ? undefined : this._beginPayment;
  }

  set beginPayment(v) {
    this._beginPayment = this.getIntegerValue(v, this._beginPayment);
  }

  @computed
  get residualPayment() {
    return this.calcDisabled ? undefined : this._residualPayment;
  }

  set residualPayment(v) {
    this._residualPayment = this.getIntegerValue(v, this._residualPayment);
  }

  @computed
  get creditTerm() {
    return this.calcDisabled ? undefined : this._creditTerm;
  }

  @computed
  get beginPaymentPercent() {
    return Math.round((this.beginPayment / this.carCost) * 100) || 0;
  }

  @computed
  get residualPaymentPercent() {
    return Math.round((this.residualPayment / this.carCost) * 100) || 0;
  }

  @computed
  get calcDisabled() {
    return this.requestBlock || !this.calculated;
  }

  @computed
  get requestBlock() {
    const { carCost = 0, model, brand, govProgram, govProgramName } = this;
    return (
      !brand || !model || +carCost < 5e5 || (govProgram && !govProgramName)
    );
  }

  @computed
  get isResidualProgram() {
    return this.creditProgram === 'С_остаточным_платежом';
  }

  @computed
  get subsidyPossibility() {
    return this.model && this._subsidyPossibility;
  }

  set subsidyPossibility(value) {
    this._subsidyPossibility = value;
    if (value === false) {
      this.toggleGovProgram(false);
    }
  }

  getIntegerValue = (v, prev) => {
    const result = `${v}`.length > 0 ? parseInt(v) : '';
    return `${result}` !== 'NaN' ? result : prev;
  };

  @computed
  get carUseType() {
    return this.useType ? 'Новый' : 'Подержаный'; //1 - new
  }

  @action.bound
  toggleGovProgram(value) {
    this.govProgram = value;
    this.store.onCalc('govProgram');
  }

  @action.bound
  setGovProgram(v) {
    this.govProgramName = v;
    this.store.onCalc('govProgramName');
  }

  //для запроса
  get subsidyType() {
    const found = this.subsidyNameList.find(o => {
      return o.label === this.govProgramName;
    });
    return found && found.value;
  }

  @action.bound
  toggleInsurance(value) {
    this.insuranceInCredit = value;
    this.store.onCalc('insuranceInCredit');
  }

  @computed
  get carYear() {
    if (!this._carYear) {
      const now = new Date();
      return now.getFullYear();
    }

    return this._carYear;
  }

  isNotEmpty(v) {
    return v === 0 || v === false || !!v;
  }

  @computed
  get subsidyConditions() {
    return subsidyConditionsList[this.govProgramName];
  }

  get insuranceLifeCompanyPosId() {
    return this.insuranceInCredit ? this.insuranceCompanyPosId : '';
  }

  get insuranceLifeProgramPosId() {
    return this.insuranceInCredit ? this.insuranceProgramPosId : '';
  }

  @action.bound
  onCalculatorFieldChange(info) {
    const changedFields = info.changedFields[0];
    if (!changedFields) {
      return;
    }
    const field = changedFields.name[0];
    const value = changedFields.value;

    if (
      field === 'creditTerm' ||
      field === 'beginPayment' ||
      field === 'residualPayment'
    ) {
      this.setIntervalValue(field, value);
    }
  }

  setIntervalValue = (fieldName, value) => {
    if (!this[fieldName]) {
      return;
    }
    this[fieldName] = value;
    this.store.runCalc(fieldName);
  };
}
