import moment from 'moment';

/**
 * Валидаторы полей форм
 */

const msgRequired = 'Обязательно для заполнения';
const msgEmpty = 'Введите данные';
const msgFormat = 'Неверный формат значения';
const msgRules = 'Ознакомьтесь с требованиями к заемщику';
const msgAddress = 'Укажите наиболее близкий адрес';
const msgPassport = 'Требуется замена паспорта';
const msgProfessionalLength =
  'Стаж работы на текущем месте не может быть больше общего стажа, пожалуйста, уточните данные';
const businessman = 'Индивидуальный предприниматель';
const msgCost = () =>
  `Необходимо увеличить стоимость автомобиля, сумма кредита не соответствует условиям`;
/**
 * Обязательное
 * @param {*} value Значение
 */
export const required = { required: true, message: msgEmpty };

/**
 * Только латиница, длиной 9-15 символов;
 * @param {*} value Значение
 */
export const codePhrase = () => ({
  validator(rule, value) {
    const regEx = /^[a-zA-Z]{5,19}$/;
    if (!value || regEx.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Email
 * @param {*} value Значение
 */
export const email = () => ({
  validator(rule, value) {
    const regEx = /^[a-zA-Z0-9-_.]+[@][a-zA-Z0-9-_.]+\.[a-zA-Z]{2,5}$|^$/;
    if (!value || regEx.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Цена автомобиля. Не менее 500т.р.
 * @param {*} value Значение
 */
export const cost = (_, number) => ({
  validator(rule, value) {
    if (!value || value >= number) {
      return Promise.resolve();
    }
    return Promise.reject(msgCost(number));
  },
});

/**
 * СНИЛС. Проверка контрольной суммы
 * @param {*} value Значение
 */
export const snils = () => ({
  validator(rule, value) {
    if (!value) {
      return Promise.resolve();
    }
    if (value.length < 11) {
      return Promise.reject(msgFormat);
    }
    const numbers = value.split('');
    const firstNumbers = numbers.slice(0, 9).reverse();
    const lastNumbers = numbers.slice(-2).join('');
    const s1 = firstNumbers.reduce(
      (prev, curr, i) => prev + +curr * (i + 1),
      0
    );
    let result;
    if (s1 < 100) {
      result = `${s1}` === lastNumbers;
    } else if (s1 === 100 || s1 === 101) {
      result = lastNumbers === '00';
    } else if (s1 > 101) {
      let last = `${s1 % 101}`;
      if (last.length < 2) {
        last = 0 + last;
      }
      result = last === lastNumbers;
    }

    if (result) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * ИНН. Проверка контрольной суммы
 * Не может состоять из повторяющейся цифры
 * При значении Род занятости = Индивидуальный предприниматель должно быть 12 цифр
 * @param {*} value Значение
 */
export const inn = ({ getFieldValue }) => ({
  validator(rule, value) {
    if (!value) {
      return Promise.resolve();
    }
    if (/(?=(.))\1{10,}/.test(value)) {
      return Promise.reject(msgFormat);
    }
    const clientState = getFieldValue('clientState');
    if (clientState === businessman && value.length !== 12) {
      return Promise.reject('ИНН ИП должен состоять из 12 символов');
    }
    const getRest = number => (number % 11 === 10 ? 0 : number % 11);
    const numbers = value.split('');

    if (value.length === 12) {
      const vector = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
      const vector2 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8];
      const firstNumbers = numbers.slice(0, 10);
      const lastNumbers = numbers.slice(-2).join('');

      const s1 = firstNumbers.reduce(
        (prev, curr, i) => prev + curr * vector[i],
        0
      );
      const c1 = getRest(s1);
      const s2 = [...firstNumbers, c1].reduce(
        (prev, curr, i) => prev + curr * vector2[i],
        0
      );
      const c2 = getRest(s2);

      const result = '' + c1 + c2 === lastNumbers;

      if (result) {
        return Promise.resolve();
      }
    } else if (value.length === 10) {
      const vector = [2, 4, 10, 3, 5, 9, 4, 6, 8];
      const firstNumbers = numbers.slice(0, 9);
      const lastNumber = numbers.slice(-1).join('');

      const s1 = firstNumbers.reduce(
        (prev, curr, i) => prev + curr * vector[i],
        0
      );
      const c1 = getRest(s1);

      const result = '' + c1 === lastNumber;
      if (result) {
        return Promise.resolve();
      }
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Количество иждивенцев
 * Не более 2х знаков
 */
export const childrenAmount = () => ({
  validator(rule, value) {
    if (!value || value <= 99) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Телефон. Не допускается повтор одной цифры во всем номере
 * @param {*} value Значение
 */
export const phone = () => ({
  validator(rule, value) {
    const regEx = /7(?=(.))\1{10,}/;
    if (!value || (value.length === 11 && !regEx.test(value))) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Телефон работодателя. Не должен совпадать с мобильным телефоном
 * @param {*} clientPhoneMobile Мобильный телефон клиента
 * @param {*} clientState Род занятости
 * @param {*} clientCompanyPhoneNumber2 Дополнительный телефон работодателя
 */
export const companyPhone = (
  clientPhoneMobile,
  clientState,
  clientCompanyPhoneNumber2
) => ({
  validator(rule, value) {
    if (clientState === businessman) {
      return Promise.resolve();
    }
    if (clientPhoneMobile !== value) {
      return Promise.resolve();
    }
    if (clientCompanyPhoneNumber2 && clientCompanyPhoneNumber2 !== value) {
      return Promise.resolve();
    }
    return Promise.reject(
      'Номер телефона работодателя совпадает с Вашим мобильным телефоном. ' +
        'Укажите второй номер телефона'
    );
  },
});

/**
 * Фамилия, имя, отчество. Только кириллица, пробел и знак «-». Вокруг знака «-» или пробела должны располагаться буквы
 */
export const name = () => ({
  validator(rule, value) {
    if (!value) {
      return Promise.resolve();
    }

    const regEx = /^[а-яА-ЯёЁ]((-[а-яА-ЯёЁ]| [а-яА-ЯёЁ]|[а-яА-ЯёЁ])|)*$/;
    if (regEx.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Checkbox. Обязательно для заполнения
 * @param {*} value Значение
 */
export const requiredCheckBox = () => ({
  validator(rule, value) {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject(msgRequired);
  },
});

/**
 * Checkbox. Обязательно для заполнения
 * @param {*} value Значение
 */
export const citizenship = () => ({
  validator(rule, value) {
    if (value) {
      return Promise.resolve();
    }
    return Promise.reject(msgRules);
  },
});

/**
 * Дата выдачи паспорта
 * @param {*} date Дата
 */
export const passportDate = ({ getFieldValue }) => ({
  validator(rule, value) {
    const issueDate = moment(value, 'YYYY-MM-DDTHH:mm:ss');
    const now = moment();

    if (issueDate > now) {
      return Promise.reject(
        'Дата выдачи паспорта не может быть больше текущей даты'
      );
    }

    const birth = getFieldValue('clientBirthdate');
    const birthDate = moment(birth, 'YYYY-MM-DDTHH:mm:ss');

    if (!birth || !value) {
      return Promise.resolve();
    }

    if (
      birthDate.date() === issueDate.date() &&
      birthDate.month() === issueDate.month() &&
      birthDate.year() === issueDate.year()
    ) {
      return Promise.reject(
        'Дата выдачи паспорта не может быть равна дате рождения'
      );
    }

    const diff = issueDate.diff(birthDate, 'years');
    if (diff < 14) {
      return Promise.reject(
        'На момент выдачи паспорта должно быть больше 14 лет'
      );
    }

    const diffNow = now.diff(birthDate, 'years');
    if (diffNow >= 45) {
      const newIssueDate = moment(birth, 'YYYY-MM-DDTHH:mm:ss').add(
        45,
        'years'
      );
      const diff = issueDate.diff(newIssueDate, 'days');
      if (diff <= 0) {
        return Promise.reject(msgPassport);
      }
    }

    if (value) {
      return Promise.resolve();
    }
  },
});

/**
 * Возраст
 *  * @param {*} string Дата рождения
 */
export const birthDate = (_, term) => ({
  validator(rule, date) {
    const MAX = 65;
    const MIN = 21;

    const maxYears = moment(date, 'YYYY-MM-DDTHH:mm:ss').add(MAX, 'years');
    const minYears = moment(date, 'YYYY-MM-DDTHH:mm:ss').add(MIN, 'years');
    const now = moment();
    const creditEnd = moment().add(term / 12, 'years');

    const diffMax = maxYears.diff(creditEnd, 'minutes');
    const diffMin = minYears.diff(now, 'minutes');

    if (diffMax <= 0 || diffMin >= 0) {
      return Promise.reject(msgRules);
    }
    return Promise.resolve();
  },
});

/**
 * Место рождения
 * Только русские буквы, цифры, знаки препинания, пробел
 * Латинские символы запрещены. Только цифры, знаки препинания, пробелы запрещены.
 */
export const birthPlace = () => ({
  validator(rule, value) {
    const regEx = /^(?=.*[а-яА-ЯёЁ]+)[а-яА-ЯёЁ0-9.,?!:;"'\-()/ ]*$/;

    if (!value || regEx.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Серия и номер паспорта
 * Не могут состоять из повторяющейся цифры (например, 0000 000000)
 *  * @param {*} string Серия/номер
 */
export const passportNumber = secondField => ({
  validator(rule, value) {
    let regEx;
    if (rule.field === 'mainIdentityDocSeries') {
      regEx = /^\d{2}\s\d{2}$/;
    } else if (rule.field === 'mainIdentityDocNumber') {
      regEx = /^\d{6}$/;
    }
    if (!value || regEx.test(value)) {
      if (secondField) {
        if (
          /(?=(.))\1{10,}/.test(
            value.replaceAll(' ', '') + secondField.replaceAll(' ', '')
          )
        ) {
          return Promise.reject(msgFormat);
        }
      }
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Код подразделения
 * Не может состоять из повторяющейся цифры
 */
export const issuerCode = () => ({
  validator(rule, value) {
    const regEx1 = /\d{3}-\d{3}/,
      regEx2 = /(?=(\d))\1{3,}-\1{3,}/;

    if (!value || (regEx1.test(value) && !regEx2.test(value))) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Кем выдан
 * Только русские буквы, цифры, знаки препинания, символы, пробел
 * Латинские символы запрещены. Только цифры, знаки препинания, пробелы запрещены.
 */
export const issuer = () => ({
  validator(rule, value) {
    const regEx = /^(?=.*[а-яА-ЯёЁ]+)[^a-zA-Z]*$/;

    if (!value || regEx.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Дата регистрации
 */
export const registrationDate = ({ getFieldValue }) => ({
  validator(rule, value) {
    const registrationDate = moment(value, 'YYYY-MM-DDTHH:mm:ss');
    const now = moment();

    if (registrationDate > now) {
      return Promise.reject(
        'Дата регистрации не может быть больше текущей даты'
      );
    }

    const birth = getFieldValue('clientBirthdate');
    const birthDate = moment(birth, 'YYYY-MM-DDTHH:mm:ss');

    if (!birth || !value) {
      return Promise.resolve();
    }

    if (registrationDate < birthDate) {
      return Promise.reject(
        'Дата регистрации не может быть меньше даты рождения'
      );
    }

    if (value) {
      return Promise.resolve();
    }
  },
});

/**
 * Наименование работодателя
 * Русские буквы, латинские символы, цифры, знаки препинания, символы, пробел.
 * Только цифры, знаки препинания, символы, пробелы запрещены.
 */
export const companyName = () => ({
  validator(rule, value) {
    const regEx = /^(?=.*[а-яА-ЯёЁa-zA-Z]+).*$/;

    if (!value || regEx.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Текущий трудовой стаж
 * @param {*} date Дата
 */
export const seniority = ({ getFieldValue }) => ({
  validator(rule, value) {
    const month = getFieldValue('clientProfessionalLengthOfThisJobMonths')
      ? getFieldValue('clientProfessionalLengthOfThisJobMonths')
      : 0;
    const occupation = getFieldValue('clientState');

    if (occupation !== 'Наемный работник') {
      if (value === 0) {
        return Promise.reject(msgRules);
      }
      return Promise.resolve();
    }

    if (value === 0 && month < 3) {
      return Promise.reject(msgRules);
    }
    return Promise.resolve();
  },
});

/**
 * Занятость
 * @param {*} date Дата
 */
export const occupation = ({ validateFields }) => ({
  validator(rule, value) {
    if (value === 'Пенсионер') {
      return Promise.reject(msgRules);
    }

    validateFields(['clientCompanyTaxCode']);
    return Promise.resolve();
  },
});

/**
 * Род занятости (Другое)
 * Только русские буквы, знаки препинания, пробел
 * Латинские символы и цифры запрещены. Только знаки препинания, пробелы запрещены.
 */
export const clientStateOther = () => ({
  validator(rule, value) {
    const regEx = /^(?=.*[а-яА-ЯёЁ]+)[а-яА-ЯёЁ .,?!\-:;()]*$/;

    if (!value || regEx.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Отрасль (Другое)
 * Только русские буквы, знаки препинания, пробел
 * Латинские символы и цифры запрещены. Только знаки препинания, пробелы запрещены.
 */
export const clientCompanyTypeOther = () => ({
  validator(rule, value) {
    const regEx = /^(?=.*[а-яА-ЯёЁ]+)[а-яА-ЯёЁ .,?!\-:;()]*$/;

    if (!value || regEx.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Должность
 * Русские буквы, латинские символы, цифры, знаки препинания, символы, пробел.
 * Только цифры, знаки препинания, символы, пробелы запрещены.
 */
export const clientPosition = () => ({
  validator(rule, value) {
    const regEx = /^(?=.*[а-яА-ЯёЁa-zA-Z]+).*$/;

    if (!value || regEx.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Общий трудовой стаж
 * @param {*} date Дата
 */
export const seniorityAll = ({ getFieldValue }) => ({
  validator(rule, value) {
    const thisJobYear = getFieldValue('clientProfessionalLengthOfThisJobYear');
    const thisJobMonth = getFieldValue(
      'clientProfessionalLengthOfThisJobMonths'
    );
    const totalMonth = getFieldValue('clientProfessionalLengthMonths');
    if (value === 0) {
      return Promise.reject(msgRules);
    }
    if (thisJobYear + thisJobMonth / 12 > value + totalMonth / 12) {
      return Promise.reject(msgProfessionalLength);
    }

    return Promise.resolve();
  },
});

/**
 * Страна
 */
export const country = countryCode => ({
  validator() {
    if (!countryCode) {
      return Promise.reject(msgFormat);
    }
    return Promise.resolve();
  },
});

/**
 * Адрес
 */
export const addressRequired = (_, fias, addressRandom) => ({
  validator(rule, value) {
    const { city, town, house, street } = fias;
    if (!value && !addressRandom) {
      return Promise.reject(msgEmpty);
    }

    if (!value && addressRandom) {
      return Promise.reject(msgAddress);
    }

    const place = city || town;
    if (value && (!place || !house || !street)) {
      return Promise.reject(msgFormat);
    }
    return Promise.resolve();
  },
});

/**
 * Минимальный доход
 * @param {*} date Дата
 */
export const income = ({ getFieldValue }) => ({
  validator(rule, value) {
    let additionalIncome = getFieldValue('addIncomesOneMonth');
    if (!additionalIncome) {
      additionalIncome = 0;
    }
    const MIN = 3e4;
    if ((!value && isNaN(value)) || +value + +additionalIncome >= MIN) {
      return Promise.resolve();
    }
    return Promise.reject('Ознакомьтесь с требованиями к заемщику');
  },
});

/**
 * Тип дополнительного дохода (Иное)
 * Только русские буквы, знаки препинания, пробел
 * Латинские символы и цифры запрещены. Только знаки препинания, пробелы запрещены.
 */
export const otherIncome = () => ({
  validator(rule, value) {
    const regEx = /^(?=.*[а-яА-ЯёЁ]+)[а-яА-ЯёЁ .,?!\-:;()]*$/;

    if (!value || regEx.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Серия и номер водительского удостоверения/загранпаспорта
 *  * @param {*} string Серия/номер
 */
export const secondDocNumber = (isForeignPassport, secondField) => ({
  validator(rule, value) {
    let regEx;
    if (rule.field === 'docSeries') {
      if (isForeignPassport) {
        regEx = /^\d{2}$/;
      } else {
        regEx = /^\d{2}\s[А-Яа-я0-9]{2}$/;
      }
    } else if (rule.field === 'docNumber') {
      if (isForeignPassport) {
        regEx = /^\d{7}$/;
      } else {
        regEx = /^\d{6}$/;
      }
    }
    if (!value || regEx.test(value)) {
      if (secondField) {
        if (isForeignPassport) {
          if (/(?=(.))\1{9,}/.test(value + secondField)) {
            return Promise.reject(msgFormat);
          }
        } else {
          if (
            /(?=(.))\1{10,}/.test(
              value.replaceAll(' ', '') + secondField.replaceAll(' ', '')
            )
          ) {
            return Promise.reject(msgFormat);
          }
        }
      }
      return Promise.resolve();
    }
    return Promise.reject(msgFormat);
  },
});

/**
 * Дата выдачи загранпаспорта/водительского удостоверения
 * @param {*} clientBirthdate Дата рождения
 */
export const secondDocIssueDate = clientBirthdate => ({
  validator(rule, value) {
    const birthDate = moment(clientBirthdate, 'YYYY-MM-DDTHH:mm:ss');
    const now = moment();

    if (!clientBirthdate || !value) {
      return Promise.resolve();
    }

    const issueDate = moment(value, 'YYYY-MM-DDTHH:mm:ss');
    if (issueDate < birthDate) {
      return Promise.reject('Дата выдачи не может быть меньше даты рождения');
    }

    if (issueDate > now) {
      return Promise.reject('Дата выдачи не может быть больше текущей даты');
    }

    if (value) {
      return Promise.resolve();
    }
  },
});

/**
 * Дата действия загранпаспорта
 */
export const expirationDate = () => ({
  validator(rule, value) {
    if (!value) {
      return Promise.resolve();
    }

    const expirationDate = moment(value, 'YYYY-MM-DD');
    const now = moment();

    if (expirationDate.diff(now, 'days') < 0) {
      return Promise.reject('Дата действия не может быть меньше текущей даты');
    }

    if (value) {
      return Promise.resolve();
    }
  },
});
