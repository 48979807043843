import React from 'react';
import { Radio, Row, Typography } from 'antd';

const { Text } = Typography;

const RadioBox = props => {
  const { label, options, onChange, ...other } = props;

  const onCheck = e => onChange(e.target.value);

  return (
    <div className={'radiobox'}>
      <Text>{label}</Text>
      <Row>
        <Radio.Group {...other} onChange={onCheck}>
          {options.map(o => (
            <Radio key={o.value} value={o.value}>
              {o.name}
            </Radio>
          ))}
        </Radio.Group>
      </Row>
    </div>
  );
};

export default RadioBox;
