import React from 'react';
import StepBox from '../components/StepBox';
import CalculatorCollapseBox from '../components/CalculatorCollapseBox';
import { observer } from 'mobx-react';
import useStores from 'src/hooks/useStores';
// import CorrespondenceCollapseBox from "../components/CorrespondenceCollapseBox";

const HeadersBox = () => {
  const { globalState } = useStores();

  if (globalState.step === 0) {
    return null;
  }

  return (
    <>
      <CalculatorCollapseBox header={'Выбранные условия кредитования'} />
      {/*{<CorrespondenceCollapseBox/>} //todo design*/}
      <StepBox />
    </>
  );
};

export default observer(HeadersBox);
