import React from 'react';
import { observer } from 'mobx-react';
import { Col, Form, Row } from 'antd';
import UserDataForm from '../forms/UserDataForm';
import RadioBox from '../components/RadioBox';
import AddressForm from '../forms/AddressForm';
import PassportForm from '../forms/PassportForm';
import ConsentForm from '../forms/ConsentForm';
import RequestPageLayout from '../layouts/RequestPageLayout';
import PhoneInputBox from '../components/PhoneInputBox';
import useStores from 'src/hooks/useStores';
import NoteBox from 'src/components/NoteBox';
import ButtonPrimaryBox from 'src/components/ButtonPrimaryBox';
import { scrollToError } from 'src/helpers/helpers';

const PassportDataPage = () => {
  const { authStore, requestStore, calculatorStore, globalState } = useStores();
  const { onSubmit, verify } = requestStore;
  const { formsReadOnly } = globalState;
  const {
    clientGender,
    clientCitizenship,
    clientBirthdate,
    birthCountry,
    countryCode,
    clientBirthplace,
    mainIdentityDocSeries,
    mainIdentityDocNumber,
    mainIdentityDocIssuedBy,
    mainIdentityDocIssuerCode,
    mainIdentityDocIssueDate,
    addressLiving,
    addressRegistration,
    addressRegistrationRandom,
    addressLivingRandom,
    flatRegister,
    flatLiving,
    clientAddressRegDate,
    UserAgreement,
    AccordanceInformationToBKI,
    clientGenderSelect,
    toggleRegistrationLiving,
    addressRegistrationFIAS,
    addressLivingFIAS,
    setClientGender,
    clientAddressActualSameAsRegistration,
    setAddressRegistrationFIAS,
    setAddressLivingFIAS,
    setDepartment,
    setCountryCode,
    isLivingRandom,
    isRegistrationRandom,
    toggleRegistrationRandomFlag,
    toggleLivingRandomFlag,
  } = requestStore.passportEntity;

  const { creditTerm } = calculatorStore.calculatorEntity;

  const {
    firstName,
    lastName,
    middleName,
    clientEmail,
    clientPassword,
    clientPhoneMobile,
    accordancePersonInfo,
  } = authStore.authEntity;

  const onFinish = () => {
    onSubmit('passportData');
  };

  return (
    <RequestPageLayout>
      <Form
        name={'PassportData'}
        initialValues={{
          firstName,
          lastName,
          middleName,
          clientEmail,
          clientPassword,
          clientPhoneMobile,
          accordancePersonInfo,
          clientGender,
          clientCitizenship,
          clientBirthdate,
          birthCountry,
          clientBirthplace,
          mainIdentityDocSeries,
          mainIdentityDocNumber,
          mainIdentityDocIssuedBy,
          mainIdentityDocIssuerCode,
          mainIdentityDocIssueDate,
          addressLiving,
          addressRegistration,
          addressRegistrationRandom,
          addressLivingRandom,
          flatRegister,
          flatLiving,
          clientAddressRegDate,
          UserAgreement,
          AccordanceInformationToBKI,
          clientAddressActualSameAsRegistration,
        }}
        fields={[
          { name: 'firstName', value: firstName },
          { name: 'middleName', value: middleName },
          { name: 'lastName', value: lastName },
          { name: 'clientPhoneMobile', value: clientPhoneMobile },
          { name: 'clientEmail', value: clientEmail },
          { name: 'clientGender', value: clientGender },
          { name: 'mainIdentityDocIssuedBy', value: mainIdentityDocIssuedBy },
          { name: 'mainIdentityDocNumber', value: mainIdentityDocNumber },
          { name: 'mainIdentityDocSeries', value: mainIdentityDocSeries },
          {
            name: 'mainIdentityDocIssueDate',
            value: mainIdentityDocIssueDate,
          },
          {
            name: 'mainIdentityDocIssuerCode',
            value: mainIdentityDocIssuerCode,
          },
          { name: 'clientBirthdate', value: clientBirthdate },
          { name: 'clientBirthplace', value: clientBirthplace },
          { name: 'birthCountry', value: birthCountry },
          { name: 'addressRegistration', value: addressRegistration },
          { name: 'addressLiving', value: addressLiving },
          { name: 'flatLiving', value: flatLiving },
          { name: 'flatRegister', value: flatRegister },
          { name: 'clientAddressRegDate', value: clientAddressRegDate },
          {
            name: 'clientAddressActualSameAsRegistration',
            value: clientAddressActualSameAsRegistration,
          },
          { name: 'UserAgreement', value: UserAgreement },
          {
            name: 'AccordanceInformationToBKI',
            value: AccordanceInformationToBKI,
          },
        ]}
        onFinish={onFinish}
        onFinishFailed={scrollToError}
      >
        <UserDataForm
          setClientGender={setClientGender}
          formDisabled={formsReadOnly}
        />
        <Row gutter={[26, 32]}>
          <PhoneInputBox disabled={true} />
          <Col span={12}>
            <Form.Item name={'clientGender'}>
              <RadioBox
                label={'Пол*'}
                options={clientGenderSelect}
                disabled={formsReadOnly}
              />
            </Form.Item>
          </Col>
        </Row>

        <PassportForm
          setDepartment={setDepartment}
          setCountryCode={setCountryCode}
          creditTerm={creditTerm}
          verify={verify}
          formDisabled={formsReadOnly}
          countryCode={countryCode}
        />

        <AddressForm
          clientAddressActualSameAsRegistration={
            clientAddressActualSameAsRegistration
          }
          toggleRegistrationLiving={toggleRegistrationLiving}
          addressRegistrationFIAS={addressRegistrationFIAS}
          addressLivingFIAS={addressLivingFIAS}
          setAddressLivingFIAS={setAddressLivingFIAS}
          setAddressRegistrationFIAS={setAddressRegistrationFIAS}
          isLivingRandom={isLivingRandom}
          isRegistrationRandom={isRegistrationRandom}
          toggleRegistrationRandomFlag={toggleRegistrationRandomFlag}
          toggleLivingRandomFlag={toggleLivingRandomFlag}
          formDisabled={formsReadOnly}
        />
        <ConsentForm formDisabled={formsReadOnly} />
        <NoteBox />
        <Form.Item>
          <ButtonPrimaryBox htmlType={'submit'} disabled={formsReadOnly}>
            ДАЛЕЕ
          </ButtonPrimaryBox>
        </Form.Item>
      </Form>
    </RequestPageLayout>
  );
};

export default observer(PassportDataPage);
