import { observable, action, computed } from 'mobx';
import api from '../services/api';
import { timer } from '../services/timer';
import { dialogStore, loaderStore } from './index';

export class PinStore {
  @observable show = false;
  @observable hasError = false;
  @observable pinCode = '';
  @observable btnDisabled = false;
  @observable _counter;

  source = {
    storageKey: 'widget.pinCode.source',
    set: value => {
      localStorage.setItem(this.source.storageKey, value);
    },
    get: () => {
      return localStorage.getItem(this.source.storageKey);
    },
  };

  timer = {
    storageKey: 'widget.pinCode.startTime',
    start: () => {
      timer.start(60, count => (this.counter = count));
      localStorage.setItem(this.timer.storageKey, timer.startTime);
    },
    restore: () => {
      timer.restore(
        localStorage.getItem(this.timer.storageKey),
        count => (this.counter = count)
      );
    },
    stop: () => {
      timer.clear();
      this.counter = 0;
      localStorage.setItem(this.timer.storageKey, undefined);
    },
  };

  constructor() {
    this.timer.restore();
    this.onError = this.onError.bind(this);
  }

  @computed
  get counter() {
    let counter = this._counter;
    const secs = counter % 60;
    const mins = (counter - secs) / 60;

    function toSting(num) {
      const string = '' + num;
      return num < 10 ? '0' + string : string;
    }

    return `${toSting(mins)}:${toSting(secs)}`;
  }

  set counter(v) {
    this._counter = v;
  }

  run = (successFn, confirmRequest) => {
    this.successFn = successFn;
    this.confirmRequest = confirmRequest;
    this.btnDisabled = false;
    this.open();
    if (this.available) {
      this.timer.start();
    }
  };

  @action.bound
  async onSend() {
    if (!this.pinCode) {
      return this.setError('Введите код');
    }
    const code = this.pinCode;
    loaderStore.on();
    const confirmRequest = this.confirmRequest || api.auth2FA.bind(api);
    try {
      const result = await confirmRequest(code);
      this.close();
      this.timer.stop();
      this.successFn(result);
    } catch (e) {
      this.onError(e);
      loaderStore.off();
    }
  }

  @action.bound
  async onReSend() {
    if (!this.reSendAble) {
      return;
    }
    this.btnDisabled = false;
    this.setError(false);
    loaderStore.on();
    try {
      await api.auth2FARefresh();
      loaderStore.off();
      this.timer.start();
    } catch (e) {
      this.onError(e);
    }
    return this;
  }

  @action.bound
  onError(e) {
    let message;
    if (e.code === 404) {
      this.setError('message');
    } else if (e.code === 103) {
      const n = +e.message;
      const wordTries =
        n === 1 ? 'попытка' : n === 2 || n === 3 ? 'попытки' : 'попыток';
      const wordLeft = n === 1 ? 'Осталась' : 'Осталось';
      if (n > 0) {
        message = `Неверный код!  ${wordLeft + ' ' + n + ' ' + wordTries}`;
      }
      if (n === -1) {
        message = `Введен неверный код`;
      }
      if (n === 0 || n < -1) {
        message = `Превышено количество попыток входа. Запросите код заново`;
        this.btnDisabled = true;
      }
      this.setError(message);
    } else if (e.code === 102) {
      // истёк срок действия accessToken
      const e = new Error();
      e.message = `Превышено время ожидания. Повторите операцию заново`;
      dialogStore.catch(e);
      this.close();
    } else if (e.message === 'expired code') {
      message = `Срок действия ранее направленного кода истек. Запросите код повторно`;
      this.btnDisabled = true;
      this.setError(message);
    } else {
      dialogStore.catch(e);
      this.timer.stop();
    }
  }

  @computed
  get reSendAble() {
    return this._counter === 0;
  }

  @action.bound
  setCode(value) {
    this.pinCode = value;
  }

  @action.bound
  setError(value) {
    this.hasError = value;
  }

  @action.bound
  close() {
    loaderStore.off();
    this.setError(false);
    this.show = false;
    this.timer.stop();
  }

  @action.bound
  open() {
    this.pinCode = '';
    this.show = true;
  }

  @computed
  get available() {
    return !this._counter;
  }
}

const pinStore = new PinStore();
export default pinStore;
