import React from 'react';
import { Row, Col, Form } from 'antd';
import InputBox from '../components/InputBox';
import DatePickerBox from '../components/DatePickerBox';
import CheckBoxYesNo from '../components/CheckBoxYesNo';
import InputMaskedBox from '../components/InputMaskedBox';
import DaDataBox from '../components/DaDataBox';
import {
  required,
  citizenship,
  passportDate,
  birthDate,
  country,
  passportNumber,
  issuerCode,
  issuer,
  birthPlace,
} from '../services/validators';

const PassportForm = props => {
  const {
    setDepartment,
    setCountryCode,
    creditTerm,
    formDisabled,
    verify,
    countryCode,
  } = props;

  const validatorMessage = {};
  if (!verify) {
    validatorMessage.help =
      'По данным ФМС паспорт недействительный. Рекомендуем обратиться в ФМС';
  } else {
    delete validatorMessage.help;
  }

  return (
    <>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Row gutter={9}>
            <Col span={12} style={{ alignSelf: 'flex-start' }}>
              <Form.Item
                name={'clientCitizenship'}
                valuePropName={'checked'}
                rules={[citizenship]}
              >
                <CheckBoxYesNo
                  label={'Гражданство РФ'}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'clientBirthdate'}
                rules={[required, _ => birthDate(_, creditTerm || 60)]}
              >
                <DatePickerBox
                  label={'Дата рождения*'}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={9}>
            <Col span={12}>
              <Form.Item
                name={'birthCountry'}
                rules={[() => country(countryCode), required]}
              >
                <DaDataBox
                  service={'country'}
                  label={'Страна рождения*'}
                  mutationFn={e => {
                    setCountryCode(e ? e.data.alfa2 : '');
                  }}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'clientBirthplace'}
                rules={[required, birthPlace]}
              >
                <InputBox
                  label={'Место рождения*'}
                  placeholder={'Город, населенный пункт'}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Row gutter={9}>
            <Col span={12}>
              <Form.Item
                dependencies={['mainIdentityDocNumber']}
                name={'mainIdentityDocSeries'}
                rules={[
                  required,
                  form =>
                    passportNumber(form.getFieldValue('mainIdentityDocNumber')),
                ]}
                validateStatus={verify ? 'success' : 'error'}
                {...validatorMessage}
              >
                <InputMaskedBox
                  mask={'11 11'}
                  placeholder={'00 00'}
                  label={'Серия и номер паспорта*'}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                dependencies={['mainIdentityDocSeries']}
                name={'mainIdentityDocNumber'}
                rules={[
                  required,
                  form =>
                    passportNumber(form.getFieldValue('mainIdentityDocSeries')),
                ]}
                validateStatus={verify ? 'success' : 'error'}
              >
                <InputMaskedBox
                  mask={'111111'}
                  placeholder={'000000'}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={9}>
            <Col span={12}>
              <Form.Item
                name={'mainIdentityDocIssueDate'}
                rules={[required, passportDate]}
              >
                <DatePickerBox label={'Дата выдачи*'} disabled={formDisabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={'mainIdentityDocIssuerCode'}
                rules={[required, issuerCode]}
              >
                <DaDataBox
                  searchIcon={false}
                  mask={'111-111'}
                  placeholder={'000-000'}
                  label={'Код подразделения*'}
                  service={'fms_unit'}
                  query={'data.code'}
                  suggestionsFormatter={suggestion =>
                    suggestion.data && ` ${suggestion.data.code}`
                  }
                  selectedSuggestionFormatter={suggestion =>
                    suggestion && `${suggestion.data.code}`
                  }
                  mutationFn={e => setDepartment(e ? e.data.name : '')}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[26, 32]}>
        <Col span={24}>
          <Form.Item
            name={'mainIdentityDocIssuedBy'}
            rules={[required, issuer]}
          >
            <InputBox label={'Кем выдан*'} disabled={formDisabled} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PassportForm;
