import * as moment from 'moment';
import 'moment/locale/ru';

moment.locale('ru');

const formatters = {
  /**
   * Преобразует значение к строке
   * @param {*} value Значение
   * @returns {string}
   */
  toString(value) {
    return value ? `${value}` : '';
  },

  /**
   * Преобразует значение к строке
   * @param {*} value Значение
   * @returns {string}
   */
  toDistance(value) {
    return `${value} км`;
  },

  /**
   * Преобразует значение к числу
   * @param {*} value Значение
   * @returns {number}
   */
  toFloat(value) {
    const float = parseFloat(`${value}`.replace(/\s+/gim));
    return isNaN(float) ? 0 : float;
  },

  /**
   * Преобразует значение к процентам '%'
   * @param {*} value Значение
   * @returns {number}
   */
  toPercent(value) {
    if (!value) {
      return '%';
    }
    return formatters.toFloat(value) + '%';
  },

  /**
   * Преобразует значение к процентам '% годовых' с 3я знаками после запятой
   * @param {*} value Значение
   * @returns {number}
   */
  toFixedPercent(value) {
    const pointInx = value.indexOf('.');
    const cut = +value.substring(0, pointInx + 4);
    return cut.toFixed(3) + '% годовых';
  },

  /**
   * Преобразует значение к процентам
   * @param {*} value Значение
   * @returns {number}
   */
  toPercentShort(value) {
    return formatters.toFloat(value) + '%';
  },

  /**
   * Преобразует значение к формату dd.mm.YYYY
   * @param {*} value Значение
   * @returns {string}
   */
  toDate(value) {
    if (!value) {
      return '';
    }
    return moment(value).format('DD.MM.YYYY');
  },

  /**
   * Преобразует значение к формату d month YYYY года
   * @param {*} value Значение
   * @returns {string}
   */
  toDateLong(value) {
    if (!value) {
      return '';
    }
    return moment(value).format('D MMMM YYYY');
  },

  /**
   * Преобразует значение к формату d month YYYY, hh:mm
   * @param {*} value Значение
   * @returns {string}
   */
  toDateTime(value) {
    if (!value) {
      return '';
    }
    return moment(value).format('D MMMM YYYY, HH:mm');
  },

  /**
   * Преобразует значение к формату d month
   * @param {*} value Значение
   * @returns {string}
   */
  toDateShort(value) {
    if (!value) {
      return '';
    }
    return moment(value).format('D MMMM');
  },

  /**
   * Преобразует значение к денежному формату
   * @param {*} value Значение
   * @returns {string}
   */
  toMoney(value) {
    if (!value) {
      return '₽';
    }
    return formatters.toMoneyClear(value) + ' ₽';
  },

  toMoneyAlways(value) {
    if (!value && isNaN(value)) {
      return '0 ₽';
    }
    return formatters.toMoneyClear(value) + ' ₽';
  },

  /**
   * Преобразует значение к формату value ₽ (percent%).
   * @param {*} value Значение
   * @returns {string}
   */

  toMoneyAndPercent(value, percent) {
    return `${formatters.toMoneyAlways(value)} (${percent || 0}%)`;
  },

  /**
   * Преобразует значение к формату n мес.
   * @param {*} value Значение
   * @returns {string}
   */
  toMonth(value) {
    if (!value) {
      return 'мес.';
    }
    return value + ' мес.';
  },

  toBoolean(boolean) {
    return boolean ? 'Да' : 'Нет';
  },

  toRemain(value) {
    if (!value && +value !== 0) {
      return String.fromCharCode(8212);
    }
    return formatters.toMoneyClear(parseFloat(value)) + ' ₽';
  },

  /**
   * Преобразует значение к денежному формату
   * @param {*} value Значение
   * @returns {string}
   */
  toMoneyClear(value) {
    if (!value) {
      return '0.00';
    }
    return parseFloat(value)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$& ');
  },

  /**
   * Преобразует значение к денежному формату без копеек
   * @param {*} value Значение
   * @returns {string}
   */
  toMoneyShort(value) {
    if (!value) {
      return '0';
    }
    return formatters.toMoneyShortClear(value) + ' ₽';
  },

  /**
   * Преобразует значение к денежному формату без копеек
   * @param {*} value Значение
   * @returns {string}
   */
  toMoneyShortClear(value) {
    if (!value) {
      return '0';
    }
    return parseInt(value)
      .toFixed(0)
      .replace(/\d(?=(\d{3})+$)/g, '$& ');
  },

  toCreditPaymentStatus(value, item) {
    if (item.prepaymentFlag === 1) {
      return 'Досрочный платеж';
    }
    return {
      overdue: 'Просрочен',
      payed: 'Оплачен',
      scheduled: 'К оплате',
      next: 'Следующий платеж',
    }[value];
  },

  /**
   * Преобразует значение к временным интервалам вида пн-вт 8:00 - 20:00
   * При значении всех свойств null возвращает false
   * @param {*} value Значение
   * @returns {string|boolean}
   */
  toBusinessTime(value) {
    if (Object.keys(value).filter(item => !value[item]).length) {
      return false;
    }

    let res = new Array(7);
    const arr = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    const arr2 = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

    for (let i = 0; i < arr.length; i++) {
      res[i] = value[arr[i]];
    }

    function makeIntervals(arr, position) {
      let lastIdx = position;
      let i = position;
      while (arr[i] === arr[i + 1]) {
        lastIdx = i + 1;
        i++;
      }
      return lastIdx;
    }

    let lastIdx = 0;
    let firstIdx = 0;
    let result = '';

    while (firstIdx < arr.length) {
      lastIdx = makeIntervals(res, firstIdx);
      let time = res[firstIdx] ? res[firstIdx] : 'выходной';

      if (lastIdx === firstIdx) {
        result += `${arr2[firstIdx]} ${time}, `;
      } else {
        result += `${arr2[firstIdx]} - ${arr2[lastIdx]} ${time}, `;
      }
      firstIdx = lastIdx + 1;
    }
    return result.substr(0, result.length - 2);
  },

  /**
   * Преобразует значение к формату value месяцев
   * @param {*} value Значение
   * @returns {string}
   */
  toMonthlyPeriod(value) {
    let data = +value;
    if (isNaN(value) || value < 12) {
      data = 12;
    }
    const forms = ['месяц', 'месяца', 'месяцев'];
    return `${data} ${
      data % 10 === 1 && data % 100 !== 11
        ? forms[0]
        : data % 10 >= 2 &&
          data % 10 <= 4 &&
          (data % 100 < 10 || data % 100 >= 20)
        ? forms[1]
        : forms[2]
    }`;
  },

  /**
   * Добавляет разделитель через 4 символа
   * @param {*} value Значение
   * @returns {string}
   */
  toAccountNumber(value) {
    if (!value) {
      return '0';
    }
    return value
      .match(/(\d{5})(\d{3})(\d)(\d{4})(\d+)/)
      .slice(1)
      .join(' ');
  },
};

export default formatters;
