import { action, observable, ObservableMap } from 'mobx';

class CacheStore {
  @observable storage = new Map();

  @action
  init(name) {
    if (!this.storage.has(name)) {
      this.storage.set(name, new CacheStorage());
    }
    return this.storage.get(name);
  }

  clear() {
    this.storage.clear();
  }
}

class CacheStorage extends ObservableMap {
  @action
  load(id, loader) {
    const stringId = `${id}`;
    if (!this.has(stringId)) {
      loader(stringId);
    }
    return this.get(stringId);
  }
}

const cacheStore = new CacheStore();
export default cacheStore;
