import { observable, action } from 'mobx';

export class LoaderStore {
  @observable open = false;

  @action
  on() {
    this.open = true;
  }

  @action
  off() {
    this.open = false;
  }
}

const loaderStore = new LoaderStore();
export default loaderStore;
