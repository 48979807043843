import React from 'react';
import { Collapse } from 'antd';
import IconArrow from './icons/IconArrow';
const CollapseBox = props => {
  const { children, ...other } = props;

  return (
    <Collapse
      className={'collapse-box'}
      expandIcon={({ isActive }) => (
        <IconArrow
          rotate={isActive ? '180deg' : '0deg'}
          text={isActive ? 'СВЕРНУТЬ' : 'ПОКАЗАТЬ'}
        />
      )}
      {...other}
    >
      {children}
    </Collapse>
  );
};

export default CollapseBox;
