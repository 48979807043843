import { action, computed, observable } from 'mobx';
import { globalState, helperStore, requestStore } from './index';
import api from '../services/api';
import { CalculatorEntity } from './entities/index';

import { debounce } from 'throttle-debounce';
import SubsidyConfirmMessage from 'src/components/SubsidyConfirmMessage';
import React from 'react';
import SubsidyErrorMessage from 'src/components/SubsidyErrorMessage';
import SubsidyWarningMessage from 'src/components/SubsidyWarningMessage';

export class CalculatorStore {
  @observable calculatorEntity = new CalculatorEntity(this);
  @observable brandsAll = [];
  @observable modelsAll = [];
  @observable localChanges = new Set();
  @observable reCalc = undefined;
  @observable changedValues = {
    beginPayment: undefined,
    creditAmount: undefined,
    creditRate: undefined,
    creditTerm: undefined,
    monthlyPayment: undefined,
    residualPayment: undefined,
  };

  save = false;

  creditPrograms = [
    { value: 'С_остаточным_платежом', name: 'Кредит с остаточным платежом' },
    { value: 'Стандарт', name: 'Стандартный кредит' },
  ];

  constructor() {
    this.onSubmit = this.onSubmit.bind(this);
    this.submit = this.submit.bind(this);
  }

  @computed
  get disableAll() {
    return globalState.disableForm('calculator');
  }

  @action
  async getBrands() {
    this.brandsAll = await api.getBrands();
  }

  @action
  async getModels() {
    this.modelsAll = await api.getModels();
  }

  @computed
  get brands() {
    if (this.brandsAll.length === 0) {
      this.getBrands();
    }

    const usedBrands = this.brandsAll
      .filter(item => !item.useType)
      .map(({ brand }) => brand)
      .sort();
    const newBrands = this.brandsAll
      .filter(item => item.useType)
      .map(({ brand }) => brand)
      .sort();

    return this.calculatorEntity.useType ? newBrands : usedBrands;
  }

  @computed
  get models() {
    if (this.modelsAll.length === 0) {
      this.getModels();
    }
    const { brand, useType } = this.calculatorEntity;
    return brand && this.modelsAll[brand]
      ? this.modelsAll[brand]
          .filter(model => model.useType === useType)
          .map(({ model }) => model)
          .sort()
      : [];
  }

  checkSubsidy = () =>
    new Promise(resolve => {
      const { govProgram, toggleGovProgram } = this.calculatorEntity;
      if (!govProgram) {
        return resolve(true);
      }

      if (globalState.activeMode === 'calculator') {
        return helperStore.onOpen({
          message: <SubsidyWarningMessage />,
          method: 'confirm',
          btnLabels: {
            ok: 'ПОДАТЬ ЗАЯВКУ',
            cancel: 'ВЫБРАТЬ ДРУГИЕ УСЛОВИЯ',
          },
          buttons: {
            cancel: () => {
              toggleGovProgram(false);
              resolve();
            },
            ok: () => {
              resolve(true);
            },
          },
        });
      }

      if (govProgram && !requestStore.isDrivingLicense) {
        return helperStore.onOpen({
          message: <SubsidyErrorMessage />,
          method: 'confirm',
          btnLabels: {
            ok: 'ПОНЯТНО',
          },
          buttons: {
            cancel: () => {
              toggleGovProgram(false);
              resolve();
            },
            ok: () => {
              globalState.modeOn('personalData');
              resolve(true);
            },
          },
        });
      }
      helperStore.onOpen({
        message: <SubsidyConfirmMessage />,
        method: 'confirm',
        buttons: {
          cancel: () => {
            toggleGovProgram(false);
            resolve();
          },
          ok: () => resolve(true),
        },
      });
    });

  async onSubmit() {
    const verify = await this.checkSubsidy();
    if (verify) {
      await this.submit({ save: true });
    }
  }

  onCalc = field => {
    this.localChanges.add(field);
    this.runCalc(field);
  };

  runCalc = debounce(250, field => {
    return this.submit({ save: false, field });
  });

  async getSubsidyPossibility() {
    const { brand, model } = this.calculatorEntity;
    if (!brand || !model) {
      return;
    }

    try {
      this.calculatorEntity.subsidyPossibility = await api.getSubsidyPossibility(
        { brand, model }
      );
    } catch (e) {
      console.error(e);
    }
  }

  async submit({ save, field = null }) {
    this.save = save;
    if (this.calculatorEntity.requestBlock) {
      return;
    }

    const {
      useType,
      carCost = 0,
      model,
      brand,
      creditProgram,
      govProgram,
      govProgramName,
      insuranceInCredit,
      carYear,
      beginPayment = 0,
      residualPayment = 0,
      creditTerm = 0,
    } = this.calculatorEntity;

    const target = {
      govProgram,
      govProgramName,
      carCost,
      model,
      brand,
      creditProgram,
      useType,
      carYear,
      insuranceInCredit,
      beginPayment,
      residualPayment,
      creditTerm,
    };

    if (this.hasRequest) {
      return this.hasRequest;
    }

    try {
      this.hasRequest = await api.setCalculation({ target, save });
      this.calculatorEntity.updateCalculation(this.hasRequest.result);
      if (save) {
        if (this.reCalc) {
          globalState.modeOn('additionalData');
        } else if (globalState.isRegister === true) {
          globalState.modeOn('passportData');
        } else {
          globalState.goNext();
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.hasRequest = null;
    }
  }

  async checkConditions() {
    const {
      useType,
      carCost = 0,
      model,
      brand,
      creditProgram,
      govProgram,
      govProgramName,
      insuranceInCredit,
      carYear,
      beginPayment = 0,
      residualPayment = 0,
      creditTerm = 0,
      creditAmount,
      creditRate,
      monthlyPayment,
    } = this.calculatorEntity;

    const target = {
      govProgram,
      govProgramName,
      carCost,
      model,
      brand,
      creditProgram,
      useType,
      carYear,
      insuranceInCredit,
      beginPayment,
      residualPayment,
      creditTerm,
    };

    if (this.hasRequest) {
      return this.hasRequest;
    }

    try {
      this.hasRequest = await api.setCalculation({ target, save: false });

      if (this.hasRequest.error.message) {
        console.log(this.hasRequest.error.message);
        this.reCalc = true;
        // возврат на Калькулятор
        return 'invalid';
      }

      this.changedValues = {
        beginPayment: beginPayment !== this.hasRequest.result.beginPayment,
        creditAmount: creditAmount !== this.hasRequest.result.creditAmount,
        creditRate: creditRate !== this.hasRequest.result.creditRate,
        creditTerm: creditTerm !== this.hasRequest.result.creditTerm,
        monthlyPayment:
          monthlyPayment !== this.hasRequest.result.monthlyPayment,
        residualPayment:
          residualPayment !== this.hasRequest.result.residualPayment,
      };

      if (
        this.changedValues.beginPayment ||
        this.changedValues.creditTerm ||
        this.changedValues.residualPayment
      ) {
        this.calculatorEntity.updateCalculation(this.hasRequest.result);
        this.reCalc = true;
        // возврат на Калькулятор
        return 'invalid';
      }

      if (
        this.changedValues.creditAmount ||
        this.changedValues.creditRate ||
        this.changedValues.monthlyPayment
      ) {
        this.calculatorEntity.updateCalculation(this.hasRequest.result);
        this.reCalc = true;
        // сообщение: изменились условия
        return 'changed';
      }

      this.calculatorEntity.updateCalculation(this.hasRequest.result);
      return 'valid';
    } catch (e) {
      console.error(e);
    } finally {
      this.hasRequest = null;
    }
  }

  setDocuments(docs) {
    requestStore.setDocuments(docs);
  }

  @computed
  get hasSubsidy() {
    return this.calculatorEntity.govProgram;
  }

  @computed
  get brand() {
    return this.calculatorEntity.brand;
  }
}

const calculatorStore = new CalculatorStore();
export default calculatorStore;
