import React from 'react';
import { observer, Provider } from 'mobx-react';

import * as stores from './stores/';
import ErrorBoundary from './components/ErrorBoundry';
import LoaderPage from './components/LoaderPage';

import PinCodeModal from './pages/PinCodeModal';
import HelperBox from './components/HelperBox';
import UploadModal from './pages/UploadModal';
import DialogModal from './pages/DialogModal';
import RouterLayout from 'src/layouts/RouterLayout';
import LoginModal from 'src/pages/LoginModal';
import UploadAdditionalModal from './pages/UploadAdditionalModal';

import './App.scss';
import './scss/index.scss';
import './assets/fonts/fonts.css';

const App = () => (
  <ErrorBoundary>
    <Provider {...stores}>
      <LoaderPage>
        <RouterLayout />
        <PinCodeModal />
        <LoginModal />
        <HelperBox />
        <UploadModal />
        <DialogModal />
        <UploadAdditionalModal />
      </LoaderPage>
    </Provider>
  </ErrorBoundary>
);

export default observer(App);
