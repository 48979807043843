import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Suggestion from './Suggestion';
import { getProp } from '../helpers';

const getStatus = suggestion =>
  getProp(suggestion, ['data', 'state', 'status']);

const SuggestionsList = props => {
  const { suggestions, visible, selected, getParent } = props;
  let elementRef = React.createRef();

  const updatePosition = () => {
    const parent = getParent();
    if (
      parent &&
      parent.current !== null &&
      elementRef &&
      elementRef.current !== null
    ) {
      const { top, left } = parent.getBoundingClientRect();
      const { width } = getComputedStyle(parent);
      const topPage = top + window.pageYOffset;
      const leftPage = left + window.pageXOffset;
      elementRef.style.top = topPage + 46 + 'px'; //todo compute top style
      elementRef.style.left = leftPage + 'px';
      elementRef.style.width = width;
    }
  };

  useEffect(() => {
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return window.removeEventListener('resize', updatePosition);
  });

  if (!!suggestions.length && visible) {
    return (
      <div
        className="suggestions-wrapper"
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <div
          className="suggestions-suggestions"
          style={{ position: 'absolute' }}
          ref={ref => (elementRef = ref)}
        >
          <div className="suggestions-hint">{props.hint}</div>
          {suggestions.map((suggestion, index) => (
            <Suggestion
              key={
                index
              } /* @todo our planet needs something better than this */
              index={index}
              selected={index === selected}
              onSelect={props.onSelect(index)}
              searchWords={props.highlighting ? props.searchWords() : []}
              highlighting={props.highlighting}
              value={props.suggestionsFormatter(suggestion)}
              subtext={props.subtextFormatter(suggestion)}
              status={getStatus(suggestion)}
            />
          ))}
        </div>
      </div>
    );
  }
  return null;
};

SuggestionsList.propTypes = {
  suggestions: PropTypes.array.isRequired,
  hint: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  suggestionsFormatter: PropTypes.func.isRequired,
  subtextFormatter: PropTypes.func.isRequired,
  searchWords: PropTypes.func.isRequired,
  highlighting: PropTypes.bool.isRequired,
};
SuggestionsList.defaultProps = {};

export default SuggestionsList;
