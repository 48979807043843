import React from 'react';
import { Row, Col, Form } from 'antd';
import InputBox from '../components/InputBox';
import InputMaskedBox from '../components/InputMaskedBox';
import DatePickerBox from '../components/DatePickerBox';
import {
  required,
  snils,
  inn as innCheck,
  secondDocNumber,
  secondDocIssueDate,
  expirationDate,
  issuer,
} from '../services/validators';
import DropdownDocumentsBox from 'src/components/DropdownDocumentsBox';

const SecondDocumentForm = props => {
  const {
    clientBirthdate,
    isForeignPassport,
    isDrivingLicense,
    isInn,
    formDisabled,
  } = props;

  return (
    <>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Form.Item name={'docType'}>
            <DropdownDocumentsBox disabled={formDisabled} />
          </Form.Item>
        </Col>

        {(isForeignPassport || isDrivingLicense) && (
          <Col span={12}>
            <Form.Item name={'docIssueBy'} rules={[required, issuer]}>
              <InputBox label={'Кем выдан*'} disabled={formDisabled} />
            </Form.Item>
          </Col>
        )}
        {!isForeignPassport && !isDrivingLicense && (
          <Col span={12}>
            <Form.Item
              name={'docNumber'}
              validateTrigger={'onBlur'}
              rules={isInn ? [required, innCheck] : [required, snils]}
            >
              <InputMaskedBox
                mask={isInn ? '111111111111' : '111-111-111-11'}
                placeholder={isInn ? '000000000000' : '000-000-000-00'}
                label={'Номер*'}
                disabled={formDisabled}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
      {(isForeignPassport || isDrivingLicense) && (
        <Row gutter={[26, 32]}>
          <Col span={24}>
            <Row gutter={26}>
              <Col span={6}>
                <Row gutter={10}>
                  <Col span={10} className={'nowrap'}>
                    <Form.Item
                      dependencies={['docNumber']}
                      name={'docSeries'}
                      rules={[
                        required,
                        form =>
                          secondDocNumber(
                            isForeignPassport,
                            form.getFieldValue('docNumber')
                          ),
                      ]}
                    >
                      <InputMaskedBox
                        mask={isForeignPassport ? '11' : '11 ЯЯ'}
                        formatCharacters={{
                          Я: {
                            validate(char) {
                              return /[А-Яа-я0-9]/.test(char);
                            },
                            transform(char) {
                              return char.toUpperCase();
                            },
                          },
                        }}
                        placeholder={isForeignPassport ? '00' : '00 AA'}
                        label={'Серия и номер*'}
                        disabled={formDisabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={14}>
                    <Form.Item
                      dependencies={['docSeries']}
                      name={'docNumber'}
                      rules={[
                        required,
                        form =>
                          secondDocNumber(
                            isForeignPassport,
                            form.getFieldValue('docSeries')
                          ),
                      ]}
                    >
                      <InputMaskedBox
                        mask={isForeignPassport ? '1111111' : '111111'}
                        placeholder={isForeignPassport ? '0000000' : '000000'}
                        disabled={formDisabled}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {isDrivingLicense && (
                <Col span={6}>
                  <Form.Item
                    name={'docIssueDate'}
                    rules={[required, secondDocIssueDate(clientBirthdate)]}
                  >
                    <DatePickerBox
                      label={'Дата выдачи*'}
                      disabled={formDisabled}
                    />
                  </Form.Item>
                </Col>
              )}
              {isForeignPassport && (
                <>
                  <Col span={6}>
                    <Form.Item
                      name={'docIssueDate'}
                      rules={[required, secondDocIssueDate(clientBirthdate)]}
                    >
                      <DatePickerBox
                        label={'Дата выдачи*'}
                        disabled={formDisabled}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      name={'docEndDate'}
                      rules={[required, expirationDate]}
                    >
                      <DatePickerBox
                        label={'Дата действия*'}
                        disabled={formDisabled}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SecondDocumentForm;
