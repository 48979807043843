import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'antd-mask-input';

const QueryInput = props => {
  const { mask, loading, query, onKeyPress, ...other } = props;
  const className = `suggestions-input${loading ? ' loading' : ''}`;

  if (mask) {
    return (
      <MaskedInput
        mask={mask}
        className={className}
        autoComplete="disabled"
        value={query}
        onKeyDown={onKeyPress}
        onKeyPress={onKeyPress}
        placeholderChar={' '}
        {...other}
      />
    );
  }

  return (
    <input
      type="text"
      className={className}
      autoComplete="disabled"
      value={query}
      onKeyDown={onKeyPress}
      onKeyPress={onKeyPress}
      {...other}
    />
  );
};

QueryInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  query: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

QueryInput.defaultProps = {
  loading: false,
  placeholder: '',
};

export default QueryInput;
