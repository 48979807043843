import React from 'react';
import { Row, Col, Form } from 'antd';
import InputBox from '../components/InputBox';
import DropdownInput from '../components/InputDropdownBox';
import {
  clientCompanyTypeOther,
  clientPosition,
  clientStateOther,
  occupation,
  required,
  seniority,
  seniorityAll,
} from '../services/validators';
import OccupationDropdownBox from 'src/components/OccupationDropdownBox';
import IndustryDropdownBox from 'src/components/IndustryDropdownBox';
import PositionDropdownBox from 'src/components/PositionDropdownBox';

const JobDataForm = props => {
  const {
    clientProfessionalLengthOfThisJobYearList,
    clientProfessionalLengthOfThisJobMonthsList,
    isOtherOccupation,
    isOtherIndustry,
    formDisabled,
  } = props;

  return (
    <>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Form.Item name={'clientState'} rules={[required, occupation]}>
            <OccupationDropdownBox disabled={formDisabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'clientCompanyLineOfBusiness'} rules={[required]}>
            <IndustryDropdownBox disabled={formDisabled} />
          </Form.Item>
        </Col>
      </Row>
      {(isOtherOccupation || isOtherIndustry) && (
        <Row
          gutter={[26, 32]}
          justify={isOtherIndustry && !isOtherOccupation ? 'end' : 'start'}
        >
          {isOtherOccupation && (
            <Col span={12}>
              <Form.Item
                name={'clientStateOther'}
                rules={[required, clientStateOther]}
              >
                <InputBox
                  placeholder={'Укажите род занятости'}
                  paddingTop={0}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
          )}
          {isOtherIndustry && (
            <Col span={12}>
              <Form.Item
                name={'clientCompanyTypeOther'}
                rules={[required, clientCompanyTypeOther]}
              >
                <InputBox
                  placeholder={'Укажите отрасль'}
                  paddingTop={0}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Form.Item name={'clientOfficialPosition'} rules={[required]}>
            <PositionDropdownBox disabled={formDisabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'clientPosition'} rules={[required, clientPosition]}>
            <InputBox
              label={'Должность*'}
              placeholder={'Укажите название должности'}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Row>
            <Col span={12}>
              <Row gutter={9}>
                <Col span={12} className={'nowrap'}>
                  <Form.Item
                    dependencies={[
                      'clientProfessionalLengthOfThisJobMonths',
                      'clientState',
                    ]}
                    name={'clientProfessionalLengthOfThisJobYear'}
                    rules={[required, seniority]}
                  >
                    <DropdownInput
                      placeholder={'Лет'}
                      label={'Стаж работы на текущем месте*'}
                      items={clientProfessionalLengthOfThisJobYearList}
                      disabled={formDisabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={'clientProfessionalLengthOfThisJobMonths'}
                    rules={[required]}
                  >
                    <DropdownInput
                      placeholder={'Месяцев'}
                      items={clientProfessionalLengthOfThisJobMonthsList}
                      disabled={formDisabled}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Col span={12}>
              <Row gutter={9}>
                <Col span={12} className={'nowrap'}>
                  <Form.Item
                    name={'clientProfessionalLengthYear'}
                    rules={[required, seniorityAll]}
                  >
                    <DropdownInput
                      placeholder={'Лет'}
                      label={'Общий стаж работы*'}
                      items={clientProfessionalLengthOfThisJobYearList}
                      disabled={formDisabled}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={'clientProfessionalLengthMonths'}
                    rules={[required]}
                  >
                    <DropdownInput
                      placeholder={'Месяцев'}
                      items={clientProfessionalLengthOfThisJobMonthsList}
                      disabled={formDisabled}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default JobDataForm;
