import React from 'react';
import { Typography, Row, Slider, Col } from 'antd';
import IconPencil from './icons/IconPencil';
import InputSliderNumberBox from './InputSliderNumberBox';

const { Text } = Typography;

const InputSliderBox = props => {
  const {
    total,
    min,
    max,
    suffix,
    current,
    label,
    placeholder,
    disabled = false,
    step,
    marks,
    ...other
  } = props;

  const relativeMin = total ? Math.round((min / total) * 100) : min || 0;
  const relativeMax = total ? Math.round((max / total) * 100) : max || 100;

  const moneyMarks = {
    0: {
      label: `${disabled || isNaN(relativeMin) ? 0 : relativeMin}%`,
      style: {
        transform: 'none',
        left: 'unset',
      },
    },
    100: {
      label: `${disabled || isNaN(relativeMin) ? 100 : relativeMax}%`,
      style: {
        transform: 'none',
        right: 0,
        left: 'unset',
      },
    },
  };

  const renderMarks = marks ? marks : moneyMarks;
  const className = marks ? 'marked' : 'unmarked';
  let elementRef = React.createRef();
  const onAfterChange = () => {
    elementRef.blur();
  };

  return (
    <div className={'slider'}>
      <Row justify={label ? 'space-between' : 'end'} className={'slider-input'}>
        {label && <Text>{label}</Text>}

        <InputSliderNumberBox
          placeholder={placeholder}
          icon={<IconPencil />}
          suffix={suffix || current}
          disabled={disabled}
          {...other}
        />
      </Row>
      <Row>
        <Col span={24}>
          <Slider
            className={className}
            min={min}
            max={max}
            marks={renderMarks}
            disabled={disabled}
            step={step}
            tooltipVisible={false}
            onAfterChange={onAfterChange}
            ref={ref => (elementRef = ref)}
            {...other}
          />
        </Col>
      </Row>
    </div>
  );
};

export default InputSliderBox;
