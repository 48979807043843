import React from 'react';

import { Button } from 'antd';

const ButtonCancelBox = props => {
  return (
    <div className={'button-cancel'}>
      <Button {...props}>ОТКАЗАТЬСЯ ОТ ЗАЯВКИ</Button>
    </div>
  );
};

export default ButtonCancelBox;
