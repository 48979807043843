import Text from 'antd/es/typography/Text';
import React from 'react';

const SubsidyErrorMessage = () => {
  return (
    <Text>
      Вы выбрали госпрограмму. Для продолжения оформления заявки по госпрограмме
      необходимо указать водительское удостоверение в разделе «Личные данные».
      Вы можете продолжить оформление заявки без применения госпрограммы.
    </Text>
  );
};

export default SubsidyErrorMessage;
