import { Col, Input, Row, Typography } from 'antd';
import React from 'react';

const { TextArea } = Input;
const { Text } = Typography;

const QuestionTextBox = props => {
  const { label, className, ...other } = props;

  return (
    <Row gutter={[0, 40]} justify={'space-between'} className={className}>
      <Col span={12} className={'switch-label'}>
        <Text>{label}</Text>
      </Col>
      <Col span={11}>
        <TextArea {...other} />
      </Col>
    </Row>
  );
};
export default QuestionTextBox;
