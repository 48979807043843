import { observable, action } from 'mobx';
import { globalState, loaderStore } from './index';
import React from 'react';
import Paragraph from 'antd/es/typography/Paragraph';
import CalculatorInfoBox from '../components/CalculatorInfoBox';
import ButtonPrimaryBox from '../components/ButtonPrimaryBox';
import ButtonSecondaryBox from '../components/ButtonSecondaryBox';
import { Row } from 'antd';
import calculatorStore from './CalculatorStore';
import requestStore from './RequestStore';

export class DialogStore {
  @observable open = false;
  @observable method = 'message';
  @observable message = '';
  @observable header = '';
  @observable actions = new Map();
  @observable button = '';
  @observable cancelButton = '';
  @observable width = undefined;

  @action.bound
  catch = e => {
    console.error('ERROR', e);
    let method = 'defaultError';
    let message =
      e.message === 'Failed to fetch'
        ? 'Возникла ошибка при отправке. ' +
          'Пожалуйста, проверьте соединение с интернетом и повторите отправку. ' +
          'Если ошибка повторится, попробуйте выполнить отправку позже.'
        : e.message;
    this.header = 'Внимание!';
    this.method = method;
    this.message = message;
    this.open = true;
    loaderStore.off();
  };

  @action.bound
  onRequestSent = n => {
    let method = 'defaultMessage';
    let message = (
      <Paragraph>
        Ваша заявка <b style={{ color: '#004666' }}>№ {n}</b> направлена в Банк.
        <br />
        Сохраните этот номер и используйте его для доступа к просмотру статуса
        по заявке на сайте в разделе «Мои заявки»
      </Paragraph>
    );
    this.header = 'Готово!';
    this.method = method;
    this.message = message;
    this.open = true;
    loaderStore.off();
  };

  @action.bound
  onConditionsChanged = () => {
    let method = 'defaultMessage';
    let message = (
      <>
        <Paragraph>Изменились условия кредитования</Paragraph>
        <div className={'dialog-conditions-box'}>
          <CalculatorInfoBox />
        </div>
        <Row justify={'center'}>
          <ButtonPrimaryBox key="submit" onClick={this.onSubmit}>
            {'ОТПРАВИТЬ В БАНК'}
          </ButtonPrimaryBox>
          <ButtonSecondaryBox
            key="recalc"
            onClick={this.onRecalc}
            style={{ marginLeft: 16 }}
          >
            {'ПОДОБРАТЬ ДРУГУЮ ПРОГРАММУ'}
          </ButtonSecondaryBox>
        </Row>
      </>
    );
    this.header = 'Внимание!';
    this.method = method;
    this.message = message;
    this.open = true;
    this.width = 900;
    loaderStore.off();
  };

  @action.bound
  onConditionsInvalid = () => {
    let method = 'defaultMessage';
    let message = (
      <Paragraph>
        Выбранные условия кредитования больше не действительны.
        <br /> Пожалуйста, сделайте новый расчёт.
      </Paragraph>
    );
    this.header = 'Внимание!';
    this.method = method;
    this.message = message;
    this.open = true;
    this.width = 700;
    loaderStore.off();
  };

  @action
  onClose = () => {
    this.open = false;
  };

  onSubmit = () => {
    this.onClose();
    calculatorStore.changedValues = {
      beginPayment: undefined,
      creditAmount: undefined,
      creditRate: undefined,
      creditTerm: undefined,
      monthlyPayment: undefined,
      residualPayment: undefined,
    };
    requestStore.onSubmit('additionalData');
  };

  onRecalc = () => {
    globalState.modeOn('calculator');
    this.onClose();
  };
}

const dialogStore = new DialogStore();
export default dialogStore;
