import React from 'react';
import { Col, Form, Row, Typography } from 'antd';

import InputBox from '../components/InputBox';
import ButtonPrimaryBox from '../components/ButtonPrimaryBox';

const { Text } = Typography;

const PinCodeForm = props => {
  const {
    pinCode,
    setCode,
    onSend,
    hasError,
    counter,
    onReSend,
    reSendAble,
    btnDisabled,
  } = props;

  const linkStyle = {
    cursor: 'pointer',
    textDecoration: 'underline',
  };
  return (
    <Form onFinish={onSend} fields={[{ name: 'pinCode', value: pinCode }]}>
      <Text className={'pin-code-message'}>
        На Ваш номер мобильного телефона мы отправили SMS с кодом подтверждения.
        Пожалуйста, введите его в поле:
      </Text>
      <Row gutter={8} style={{ width: '100%', margin: 0 }} align={'bottom'}>
        <Col flex={'auto'} style={{ padding: 0 }}>
          <Row justify={'space-between'}>
            <Col>
              <Text>Код из SMS</Text>
            </Col>
            <Col>
              <Row>
                <Col>
                  <Text
                    style={reSendAble ? linkStyle : {}}
                    className={'pin-code-timer pin-code-resend'}
                    onClick={onReSend}
                  >
                    Отправить код повторно
                  </Text>
                </Col>
                <Col>
                  <Text className={'pin-code-timer'}>{`(${counter})`}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
          <Form.Item name={'pinCode'}>
            <InputBox onChange={setCode} paddingTop={0} />
          </Form.Item>
        </Col>
        <Col flex={'37%'}>
          <Form.Item>
            <ButtonPrimaryBox htmlType={'submit'} disabled={btnDisabled}>
              ПРОДОЛЖИТЬ
            </ButtonPrimaryBox>
          </Form.Item>
        </Col>
      </Row>
      {hasError && <Text className={'pin-code-error'}>{hasError}</Text>}
    </Form>
  );
};

export default PinCodeForm;
