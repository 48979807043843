import React from 'react';

import { Button } from 'antd';

const ButtonPrimaryBox = props => {
  const { children, ...other } = props;

  return (
    <div className={'button-primary'}>
      <Button {...other}>{children}</Button>
    </div>
  );
};

export default ButtonPrimaryBox;
