import { action, observable } from 'mobx';

export default class FileManager {
  @observable files = new Map();
  index = 0;

  async saveFile(file, id, error = false) {
    if (error) {
      return this.addFile({
        fileName: file.name,
        docId: id,
        error: true,
        file,
      });
    }
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = async () => {
      const startIndex = 7 + reader.result.indexOf('base64');
      const base64 = reader.result.substring(startIndex);
      this.addFile({ fileName: file.name, docId: id, data: base64, file });
    };
    reader.onerror = function() {
      console.error(reader.error);
    };
  }

  get fileList() {
    let list = [];
    for (let [key, value] of this.files) {
      list = list.concat(
        [...value].map(file => ({
          type: key,
          file: file.data,
          filename: file.fileName,
        }))
      );
    }
    return list;
  }

  @action.bound
  addFile({ fileName, docId, data, error = false, file }) {
    this.index++;
    const list = this.files.get(docId) || [];
    const obj = {
      index: this.index,
      fileName,
      data,
      error,
      file,
    };
    list.push(obj);
    this.files.set(docId, list);
  }

  @action.bound
  async save(fileList, id) {
    const files = [...fileList];
    await setTimeout(() => {
      try {
        files.forEach(file => {
          if (!this.verifyFormat(file) || !this.verifySize(file)) {
            return this.saveFile(file, id, true);
          }
          this.saveFile(file, id);
        });
      } catch (e) {
        throw e;
      }
    }, 0);
  }

  @action.bound
  removeFile(docID, index) {
    const list = this.getFilesByDoc(docID);
    const newList = list.filter(object => object.index !== index);
    this.files.set(docID, newList);
  }

  verifyFormat(file) {
    const regex = /\.jpg$|\.pdf|.JPG$|\.PDF/;
    if (file.name.match(regex)) {
      return true;
    }
  }

  verifySize(file) {
    return file.size <= 5242880;
  }

  hasErrorByDoc = docID => {
    const files = this.getFilesByDoc(docID);
    return files.some(file => file.error);
  };

  getNamesByDoc = docID => {
    const files = this.getFilesByDoc(docID);
    return files.map(({ index, fileName, error }) => ({
      index,
      fileName,
      error,
    }));
  };

  getFilesByDoc = docID => {
    return this.files.get(docID) || [];
  };
}
