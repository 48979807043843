import Text from 'antd/es/typography/Text';
import React from 'react';
import useStores from 'src/hooks/useStores';
import { observer } from 'mobx-react';

const SubsidyConfirmMessage = () => {
  const { calculatorStore } = useStores();
  const { calculatorEntity } = calculatorStore;
  const { govProgramName, subsidyConditions } = calculatorEntity;
  return (
    <>
      <Text>
        Для продолжения оформления заявки по госпрограмме
        <strong>{` «${govProgramName}» `}</strong>
        необходимо подтвердить выполнение указанных условий:
      </Text>
      <ul>
        {subsidyConditions.map(cond => (
          <li key={cond}>{cond}</li>
        ))}
      </ul>
      <Text style={{ paddingTop: 30 }}>
        При несоответствии указанным условиям Вы можете продолжить оформление
        заявки без применения госпрограммы
        <strong>{` «${govProgramName}»`}</strong>
      </Text>
    </>
  );
};

export default observer(SubsidyConfirmMessage);
