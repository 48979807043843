import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import PinCodeForm from '../components/PinCodeForm';
import useStores from 'src/hooks/useStores';

const PinCodeModal = () => {
  const { pinStore, requestStore, globalState } = useStores();
  const {
    show,
    close,
    pinCode,
    setCode,
    onSend,
    onReSend,
    hasError,
    counter,
    reSendAble,
    btnDisabled,
  } = pinStore;

  const { signAndUpdateLead } = requestStore;

  return (
    <Modal
      width={684}
      className={'modal pin-code-modal'}
      centered
      visible={show}
      footer={null}
      onOk={console.log}
      onCancel={close}
      maskClosable={false}
    >
      <PinCodeForm
        pinCode={pinCode}
        setCode={setCode}
        onSend={onSend}
        hasError={hasError}
        counter={counter}
        onReSend={
          globalState.activeMode === 'passportData'
            ? signAndUpdateLead
            : onReSend
        }
        reSendAble={reSendAble}
        btnDisabled={btnDisabled}
      />
    </Modal>
  );
};

export default observer(PinCodeModal);
