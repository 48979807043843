import React from 'react';
import { Typography, Row, Switch } from 'antd';

const { Text } = Typography;

const GovernmentSwitchBox = props => {
  const { label, visible = true, checked, ...other } = props;

  if (!visible) {
    return null;
  }

  const text = checked ? 'Включена' : 'Не включена';
  return (
    <div className={'switch vertical-switch'}>
      <Row style={{ paddingBottom: 14 }}>
        <div>{label}</div>
      </Row>
      <Row style={{ paddingBottom: 12 }}>
        <Switch {...other} checked={checked} />
        <Text className={'vertical-switch-text'}>{text}</Text>
      </Row>
    </div>
  );
};

export default GovernmentSwitchBox;
