import React from 'react';
import InputDropdownBox from 'src/components/InputDropdownBox';
import useStores from 'src/hooks/useStores';

const SubsidyNameDropdownBox = props => {
  const { calculatorStore } = useStores();
  const { calculatorEntity } = calculatorStore;
  const { subsidyNameList } = calculatorEntity;
  return (
    <InputDropdownBox
      items={subsidyNameList.map(o => o.label)}
      label={'Субсидия'}
      placeholder={'Выберите субсидию'}
      {...props}
    />
  );
};

export default SubsidyNameDropdownBox;
