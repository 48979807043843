import React from 'react';
import { Checkbox } from 'antd';

const CheckBox = props => {
  const { label, small, ...other } = props;

  return (
    <div className={small ? 'checkbox small' : 'checkbox'}>
      <Checkbox {...other}>{label}</Checkbox>
    </div>
  );
};

export default CheckBox;
