import React from 'react';
import { Col, Row, Typography } from 'antd';

import InputBox from '../components/InputBox';
import ButtonPrimaryBox from '../components/ButtonPrimaryBox';

const { Text } = Typography;

const LoginForm = props => {
  const { btnDisabled, documentNumber, setLead, hasError, onSend } = props;

  return (
    <>
      <Text className={'pin-code-message'}>
        Вы хотите проверить статус вашей заявки или отредактировать созданную
        вами заявку на автокредит? Укажите номер заявки, направленной Вам в SMS
        при регистрации заявки:
      </Text>
      <Row gutter={8} style={{ width: '100%', margin: 0 }} align={'bottom'}>
        <Col flex={'auto'} style={{ padding: 0 }}>
          <Row justify={'space-between'}>
            <Col>
              <Text>Номер заявки*</Text>
            </Col>
          </Row>
          <InputBox value={documentNumber} onChange={setLead} paddingTop={0} />
        </Col>
        <Col flex={'37%'}>
          <ButtonPrimaryBox
            onClick={onSend}
            htmlType={'submit'}
            disabled={btnDisabled}
          >
            ВОЙТИ
          </ButtonPrimaryBox>
        </Col>
      </Row>
      {hasError && <Text className={'pin-code-error'}>{hasError}</Text>}
    </>
  );
};

export default LoginForm;
