import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;
const { Paragraph } = Typography;

const DialogBox = props => {
  const { message, header } = props;

  return (
    <>
      <Paragraph className={'dialog-header'}>{header}</Paragraph>
      <Text className={'dialog-message'}>{message}</Text>
    </>
  );
};

export default DialogBox;
