import React from 'react';
import { Col, Modal, Row } from 'antd';
import { observer } from 'mobx-react';
import ButtonPrimaryBox from 'src/components/ButtonPrimaryBox';
import ButtonSecondaryBox from 'src/components/ButtonSecondaryBox';
import useStores from 'src/hooks/useStores';

const HelperBox = () => {
  const { helperStore } = useStores();
  const { open, messageBox, method, buttons, btnLabels } = helperStore;
  const { ok, cancel } = btnLabels;
  if (!open) {
    return null;
  }

  const submit = () => {
    if (buttons.ok) {
      buttons.ok();
    }
    helperStore.onClose();
  };

  const close = () => {
    if (buttons.cancel) {
      buttons.cancel();
    }
    helperStore.onClose();
  };

  const component = {
    defaultMessage: () => (
      <Modal
        width={'unset'}
        className={'modal helper-box'}
        centered
        visible={open}
        mask={false}
        footer={null}
        onCancel={helperStore.onClose}
      >
        {messageBox}
      </Modal>
    ),
    confirm: () => (
      <Modal
        width={'unset'}
        className={'modal helper-box'}
        centered
        visible={open}
        onCancel={helperStore.onClose}
        onOk={helperStore.close}
        footer={
          <Row justify={'end'} align={'center'}>
            <ButtonPrimaryBox key="submit" onClick={submit}>
              {ok ? ok : 'ПОДТВЕРЖДАЮ'}
            </ButtonPrimaryBox>
            <ButtonSecondaryBox
              key="back"
              onClick={close}
              style={{ marginLeft: 16 }}
            >
              {cancel ? cancel : 'ПРОДОЛЖИТЬ БЕЗ ГОСПРОГРАММЫ'}
            </ButtonSecondaryBox>
            ,
          </Row>
        }
      >
        {messageBox}
      </Modal>
    ),
    cancel: () => (
      <Modal
        width={'unset'}
        className={'modal helper-box'}
        centered
        visible={open}
        onCancel={helperStore.onClose}
        onOk={helperStore.close}
        footer={
          <Row justify={'center'} style={{ width: '100%' }}>
            <Col span={10}>
              <ButtonSecondaryBox key="submit" onClick={submit}>
                {ok ? ok : 'ДА'}
              </ButtonSecondaryBox>
            </Col>
            <Col span={10}>
              <ButtonPrimaryBox
                key="back"
                onClick={close}
                style={{ marginLeft: 16 }}
              >
                {cancel ? cancel : 'НЕТ'}
              </ButtonPrimaryBox>
            </Col>
          </Row>
        }
      >
        {messageBox}
      </Modal>
    ),
    info: () => (
      <Modal
        width={'unset'}
        className={'modal helper-box'}
        centered
        visible={open}
        footer={null}
        onCancel={helperStore.onClose}
      >
        {messageBox}
      </Modal>
    ),
  };

  return <>{component[method]()}</>;
};

export default observer(HelperBox);
