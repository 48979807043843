import React from 'react';

export default class IconAttach extends React.Component {
  render() {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.01783 14.9998C4.41433 15.0009 4.8071 14.9233 5.1734 14.7717C5.5397 14.6201 5.87227 14.3974 6.15184 14.1165L8.67427 11.5966C8.9333 11.3379 8.9333 10.918 8.67427 10.6593C8.41561 10.4009 7.99652 10.4009 7.73785 10.6593L5.21447 13.1801C4.89446 13.4786 4.47089 13.6413 4.03312 13.6338C3.59534 13.6263 3.17759 13.4493 2.86799 13.14C2.55839 12.8307 2.38115 12.4134 2.37366 11.9761C2.36617 11.5387 2.52902 11.1156 2.82784 10.7959L5.34695 8.27933C5.60782 8.01872 5.60782 7.59592 5.34695 7.33531C5.08646 7.07508 4.66439 7.07508 4.40389 7.33531L1.88383 9.85283C1.4618 10.2745 1.1744 10.8117 1.05798 11.3965C0.941547 11.9814 1.00132 12.5876 1.22973 13.1385C1.45814 13.6894 1.84493 14.1602 2.3412 14.4916C2.83748 14.8229 3.42094 14.9997 4.01783 14.9998Z"
          fill="#004666"
          stroke="#004666"
          strokeWidth="0.2"
        />
        <path
          d="M11.9967 1.00001C11.6002 0.998965 11.2074 1.07649 10.8411 1.22811C10.4748 1.37973 10.1423 1.60242 9.86269 1.8833L7.34025 4.40319C7.08122 4.66196 7.08122 5.08178 7.34025 5.34055C7.59891 5.59895 8.01801 5.59895 8.27667 5.34055L10.8001 2.81972C11.1201 2.52119 11.5436 2.35851 11.9814 2.366C12.4192 2.37348 12.8369 2.55054 13.1465 2.85983C13.4561 3.16911 13.6334 3.58644 13.6409 4.02377C13.6484 4.46111 13.4855 4.88425 13.1867 5.20393L10.6642 7.72382C10.4052 7.98259 10.4052 8.40242 10.6642 8.66119C10.9229 8.91959 11.342 8.91959 11.6007 8.66119L14.124 6.14035C14.5434 5.71874 14.8287 5.18271 14.944 4.59962C15.0594 4.01653 14.9996 3.41239 14.7723 2.86312C14.545 2.31385 14.1602 1.84395 13.6664 1.51246C13.1725 1.18097 12.5916 1.00269 11.9967 1.00001Z"
          fill="#004666"
          stroke="#004666"
          strokeWidth="0.2"
        />
      </svg>
    );
  }
}
