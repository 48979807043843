import React from 'react';

export default class IconBasket extends React.Component {
  render() {
    return (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M14.8873 2.11948H12.484V0.557738C12.484 0.243381 12.2304 0 11.9161 0C11.8856 0 11.8652 0.0101825 11.8553 0.0203418C11.8451 0.0101825 11.8248 0 11.8146 0H6.16616H6.12571H6.08502C5.77069 0 5.52731 0.243381 5.52731 0.557738V2.1195H3.11381C2.42412 2.1195 1.88672 2.6569 1.88672 3.34659V4.23901V5.35446H2.95154V16.7831C2.95154 17.4728 3.47878 18 4.16847 18H13.8327C14.5224 18 15.0597 17.4728 15.0597 16.7831V5.35446H16.1144V4.23901V3.34659C16.1144 2.6569 15.5768 2.11948 14.8873 2.11948ZM6.64276 1.11545H11.3584V2.11948H6.64276V1.11545ZM13.9341 16.7831C13.9341 16.8543 13.9036 16.8845 13.8327 16.8845H4.16847C4.09752 16.8845 4.06702 16.8543 4.06702 16.7831V5.35444H13.9341V16.7831ZM14.9888 4.23899H3.01233V3.34657C3.01233 3.27559 3.04283 3.23493 3.11379 3.23493H14.8873C14.9583 3.23493 14.9888 3.27559 14.9888 3.34657V4.23899Z"
            fill="white"
          />
          <path
            d="M12.1686 6.66309H11.043V15.8911H12.1686V6.66309Z"
            fill="white"
          />
          <path
            d="M9.57483 6.66309H8.44922V15.8911H9.57483V6.66309Z"
            fill="white"
          />
          <path
            d="M6.97718 6.66309H5.85156V15.8911H6.97718V6.66309Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
