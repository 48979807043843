import React from 'react';

const createMarks = (min, max, step) => {
  const initial = {};
  const count = (max - min) / step;
  for (let i = 0; i <= count; i++) {
    const mark = min + i * step;

    if (i === 0 || i === count) {
      initial[mark] = {
        label: `${mark} мес`,
      };
    } else {
      initial[mark] = '';
    }
  }
  return initial;
};

const WithMarks = WrappedComponent => {
  return function(props) {
    const { max, min, step } = props;
    return <WrappedComponent {...props} marks={createMarks(min, max, step)} />;
  };
};

export default WithMarks;
