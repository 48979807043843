import React from 'react';
import { Collapse } from 'antd';
import CollapseBox from './CollapseBox';
import CorrespondenceBox from './CorrespondenceBox';

const { Panel } = Collapse;

const CorrespondenceCollapseBox = () => {
  return (
    <CollapseBox defaultActiveKey={['1']}>
      <Panel
        key={'1'}
        header={'Переписка с банком'}
        className={'correspondence'}
      >
        <CorrespondenceBox />
      </Panel>
    </CollapseBox>
  );
};

export default CorrespondenceCollapseBox;
