import React from 'react';

export default class IconClear extends React.Component {
  render() {
    const { onClick } = this.props;
    return (
      <svg
        onClick={onClick}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: 'pointer' }}
      >
        >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="#A9ADB2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.69603 5.17115C10.0085 4.85873 10.515 4.85873 10.8274 5.17115C11.1398 5.48357 11.1398 5.99011 10.8274 6.30252L6.30192 10.828C5.9895 11.1404 5.48297 11.1404 5.17055 10.828C4.85813 10.5156 4.85813 10.0091 5.17055 9.69664L9.69603 5.17115Z"
          fill="#DADADA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0203 9.89135C11.3327 10.2038 11.3327 10.7103 11.0203 11.0227C10.7078 11.3351 10.2013 11.3351 9.88888 11.0227L5.3634 6.49723C5.05098 6.18481 5.05098 5.67828 5.3634 5.36586C5.67582 5.05344 6.18235 5.05344 6.49477 5.36586L11.0203 9.89135Z"
          fill="#DADADA"
        />
      </svg>
    );
  }
}
