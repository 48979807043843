import { action, autorun, computed, observable } from 'mobx';

export default class DealerEntity {
  @observable _dealer;
  @observable _dealerCity;

  constructor(store) {
    this.store = store;
    autorun(() => {
      if (store && store.dealers) {
        this.cleanDealer();
      }
    });
  }

  updateFields(data) {
    this.setDealer(
      data.appGeneralConditions && data.appGeneralConditions.dealerSalePointName
    );
    this.setDealerCity(
      data.appGeneralConditions && data.appGeneralConditions.dealerCity,
      true
    );
  }

  @action.bound
  cleanDealer() {
    const dealer = this.store.dealers.find(
      o => this.dealerSalePointName === o.dealerCenterName
    );
    if (!dealer) {
      this._dealer = undefined;
    }

    const cityDealer = this.store.dealers.find(
      o => this.dealerCity === o.cityName
    );
    if (!cityDealer) {
      this._dealerCity = undefined;
    }
  }

  @computed
  get dealerSalePointName() {
    return this._dealer;
  }

  @action.bound
  setDealer(v, userChangeFlag) {
    if (!v) {
      return (this._dealer = v);
    }
    const city = this.getDealerCity(v);
    if (!this.dealerCity) {
      this.setDealerCity(city, userChangeFlag);
    }
    this._dealer = v;
  }

  @computed
  get dealerCity() {
    return this._dealerCity;
  }

  @action.bound
  setDealerCity(v, userChangeFlag) {
    if (this.dealerCity !== v) {
      this._dealerCity = v;
      if (!userChangeFlag) {
        this.setDealer(undefined, false);
      }
    }
  }

  getDealerCity(dealer) {
    const found = this.store.dealers.find(o => o.dealerCenterName === dealer);
    return found && found.cityName;
  }

  getDealer(city) {
    const found = this.store.dealers.find(o => o.cityName === city);
    return found && found.dealerCenterName;
  }

  get dealerCode() {
    const dealer = this.dealerList.find(
      dealer => dealer.label === this._dealer
    );
    return dealer && dealer.dealerCode;
  }

  @computed
  get dealerList() {
    let dealers = this.store.dealers.map(o => ({
      label: o.dealerCenterName,
      dealerCode: o.dealerCode,
      cityName: o.cityName,
    }));
    if (this.dealerCity) {
      dealers = dealers.filter(o => o.cityName === this.dealerCity);
    }

    return dealers.sort((a, b) => {
      const lowerA = a.label.toLowerCase().replace('"', '');
      const lowerB = b.label.toLowerCase().replace('"', '');
      if (lowerA > lowerB) {
        return 1;
      }
      if (lowerA < lowerB) {
        return -1;
      }
      return 0;
    });
  }

  @computed
  get cityList() {
    return [...new Set([...this.store.dealers.map(o => o.cityName)])].sort();
  }

  @action.bound
  onDealerFieldChange(info) {
    const changedFields = info.changedFields[0];
    if (!changedFields) {
      return;
    }
    const field = changedFields.name[0];
    const value = changedFields.value;
    if (field === 'dealerSalePointName') {
      this.setDealer(value, true);
    }
    if (field === 'dealerCity') {
      this.setDealerCity(value, false);
    }
  }
}
