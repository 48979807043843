import React from 'react';
import IconInfo from './icons/IconInfo';
import { helperStore } from '../stores';

const HelperIconBox = props => {
  const { message } = props;
  return <IconInfo onClick={() => helperStore.onOpen({ message })} />;
};

export default HelperIconBox;
