import React from 'react';
import { Card, Typography, Input, Row, Col } from 'antd';
import ButtonSecondaryBox from './ButtonSecondaryBox';
import ButtonPrimaryBox from './ButtonPrimaryBox';
import IconAttach from './icons/IconAttach';
import WithIcon from './hocs/WithIcon';
import IconSend from './icons/IconSend';
import useStores from '../hooks/useStores';
import { observer } from 'mobx-react';

const { Text } = Typography;
const { TextArea } = Input;

const AttachButton = WithIcon(ButtonSecondaryBox);
const SendButton = WithIcon(ButtonPrimaryBox);

const CorrespondenceBox = () => {
  const { requestStore } = useStores();
  const {
    openUploadAdditional,
    requestReworkEntity,
    requestReworkButtonDisable,
    sendToBank,
  } = requestStore;
  const {
    bankMessage,
    bankMessageDate,
    comment,
    setComment,
  } = requestReworkEntity;

  return (
    <>
      <Card title="Сообщения от банка" className={'bank-message'}>
        <Text className={'correspondence-time'}>{bankMessageDate}</Text>
        <br />
        <Text className={'correspondence-message'}>{bankMessage}</Text>
      </Card>
      <Text className={'correspondence-answer'}>Ваш ответ</Text>
      <TextArea
        placeholder={'Текст сообщения, не более 1000 символов'}
        rows={4}
        value={comment}
        onChange={setComment}
      />
      <Row gutter={[10, 0]} justify={'end'}>
        <Col>
          <AttachButton icon={<IconAttach />} onClick={openUploadAdditional}>
            ПРИКРЕПИТЬ ДОКУМЕНТЫ
          </AttachButton>
        </Col>
        <Col>
          <SendButton
            disabled={requestReworkButtonDisable}
            icon={<IconSend />}
            onClick={sendToBank}
          >
            ОТПРАВИТЬ В БАНК
          </SendButton>
        </Col>
      </Row>
    </>
  );
};

export default observer(CorrespondenceBox);
