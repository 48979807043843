import React from 'react';

const WithIcon = WrappedComponent => {
  return function(props) {
    const { icon, ...other } = props;
    return (
      <div className={'button-with-icon'}>
        <WrappedComponent {...other} />
        {icon}
      </div>
    );
  };
};

export default WithIcon;
