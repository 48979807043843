import React from 'react';
import { InputNumber } from 'antd';

const InputSliderNumberBox = props => {
  const {
    icon,
    suffix,
    value,
    onChange,
    unChangeable,
    disabled,
    ...rest
  } = props;

  const onPress = e => {
    if (unChangeable) {
      return;
    }
    e.preventDefault();
    e.target.blur();
    onChange(e);
  };

  const color = value ? '#004666' : 'rgb(193, 191, 191)';
  return (
    <div className={'input-slider-number'}>
      {!unChangeable ? icon : null}
      <div
        className={'input-slider-number-box'}
        style={{ display: 'inline-block' }}
      >
        <InputNumber
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
          parser={value => {
            const val = value.replace(/\(\d{0,2}%\)/, '');
            return val ? parseInt(val.replace(/\D/g, '')) : '0';
          }}
          value={value}
          disabled={unChangeable || disabled}
          onChange={!unChangeable ? onChange : undefined}
          onPressEnter={onPress}
          {...rest}
        />

        <div style={{ color }} className={'input-slider-number-suffix'}>
          {suffix}
        </div>
      </div>
    </div>
  );
};
export default InputSliderNumberBox;
