import React from 'react';
import { Typography } from 'antd';
import { observer } from 'mobx-react';
import UploadBox from 'src/components/UploadBox';
import useStores from 'src/hooks/useStores';

const { Text } = Typography;

const UploadAdditionalBox = () => {
  const { requestStore } = useStores();
  const { personalEntity, fileStore } = requestStore;
  const { additionalDocuments } = personalEntity;

  if (!additionalDocuments.length) {
    return null;
  }

  return (
    <>
      <Text className={'additional-documents-title'}>
        Дополнительные документы
      </Text>
      <div className={'additional-documents'}>
        {additionalDocuments.map(doc => {
          const [name] = Object.values(doc);
          const [id] = Object.keys(doc);
          return (
            <UploadBox key={id} id={id} label={name} fileStore={fileStore} />
          );
        })}
      </div>
    </>
  );
};

export default observer(UploadAdditionalBox);
