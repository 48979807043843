import React from 'react';
import { Col, Row, Typography } from 'antd';

const { Text } = Typography;

const CalculatorLineBox = props => {
  let { label, value, additional = '', disabled, changed } = props;
  const className = 'calculator-result-text-bold';

  let resultClass = disabled ? ['disabled', className].join(' ') : className;
  resultClass = changed
    ? ['calculator-result-text-changed', resultClass].join(' ')
    : resultClass;

  return (
    <Row
      className={'result-row'}
      justify={'space-between'}
      align={'middle'}
      gutter={6}
    >
      <Col>
        <Text>{label}</Text>
      </Col>
      <Col>
        <Text className={resultClass}>{value}</Text>
        {additional && (
          <Text className={[resultClass, 'additional'].join(' ')}>
            {additional}
          </Text>
        )}
      </Col>
    </Row>
  );
};
export default CalculatorLineBox;
