import React from 'react';
import { Row, Col, Form } from 'antd';
import InputBox from '../components/InputBox';
import DaDataBox from '../components/DaDataBox';
import {
  addressRequired,
  companyName,
  inn,
  required,
} from '../services/validators';

const JobAddressForm = props => {
  const {
    setEmployer,
    setEmployerInn,
    employerAddressFIAS,
    setEmployerAddressFIAS,
    toggleEmployerAddressCustom,
    isEmployerAddressCustom,
    formDisabled,
  } = props;
  return (
    <>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Form.Item name={'clientCompanyName'} rules={[required, companyName]}>
            <DaDataBox
              service={'party'}
              label={'Наименование работодателя*'}
              placeholder={'Начните ввод и выберите вариант'}
              mutationFn={setEmployerInn}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'clientCompanyTaxCode'} rules={[required, inn]}>
            <DaDataBox
              service={'party'}
              query={'data.inn'}
              helperText={'От 10 до 12 цифр'}
              mask={'111111111111'}
              mutationFn={setEmployer}
              placeholder={'000000000000'}
              label={'ИНН работодателя*'}
              selectedSuggestionFormatter={suggestion =>
                suggestion && `${suggestion.data.inn}`
              }
              searchIcon={false}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Form.Item
            name={'employerAddress'}
            rules={[
              form =>
                addressRequired(
                  form,
                  employerAddressFIAS,
                  form.getFieldValue('employerAddressCustom')
                ),
            ]}
            validateTrigger={'onBlur'}
          >
            <DaDataBox
              label={'Адрес работодателя*'}
              placeholder={'Начните ввод и выберите вариант'}
              additionalLabel={'Не могу найти правильный адрес'}
              mutationFn={setEmployerAddressFIAS}
              additionalFn={toggleEmployerAddressCustom}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={'employerRoom'}>
            <InputBox
              label={'Офис'}
              placeholder={'Номер офиса/квартиры'}
              disabled={formDisabled}
              maxLength={10}
            />
          </Form.Item>
        </Col>
      </Row>
      {isEmployerAddressCustom && (
        <Row gutter={[26, 32]}>
          <Col span={12}>
            <Form.Item name={'employerAddressCustom'}>
              <InputBox
                label={'Адрес работодателя (в произвольной форме)'}
                placeholder={'Введите полный адрес работодателя'}
                disabled={formDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

export default JobAddressForm;
