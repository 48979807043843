import aes from 'crypto-js/aes';
import encUtf8 from 'crypto-js/enc-utf8';

const jwt = require('jsonwebtoken');

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
const REACT_APP_DADATA_TOKEN = process.env.REACT_APP_DADATA_TOKEN;

/**
 * API для получения данных
 */
export const api = {
  /**
   * Ключ шифрования
   * @type {string}
   */
  cryptKey: 'suTS7IA2h981PcaZjgjSSKsYbXLjc8PFHdUtyEW710DTqnRWUxqTlFsaktPNyJzX',

  /**
   * Дата инициации токена
   * @type {number}
   */
  expiresAccessToken: 0,

  /**
   * Токен доступа
   * @type {string}
   */
  accessToken: '',

  /**
   * Токен обновления токена доступа
   * @type {string}
   */
  refreshToken: '',

  storage: localStorage,

  queue: new Map(),

  /**
   * Флаг для блокировки запросов, пока не отработает ключевой блокирующий запрос
   */
  blocked: false,

  /**
   * Аутентификация по номеру телефона или номеру заявки
   * @param {string} phone телефон
   */
  async authLogin(body) {
    const token = await this.sendRequest(
      '/auth/login',
      {
        method: 'POST',
        isPrivate: false,
        withRecaptcha: true,
      },
      body
    );
    this.updateAuth(token);
  },

  /**
   * Обновляет токен аутентификации
   * @returns {Promise}
   */
  async authRefresh() {
    if (!this.getRefreshToken()) {
      throw new NotFoundRefreshTokenError(
        'Not found refreshToken in Application storage'
      );
    }
    const token = await this.sendRequest(
      '/auth/refresh',
      {
        method: 'POST',
        isPrivate: false,
      },
      {
        refreshToken: this.getRefreshToken(),
      }
    );

    this.updateAuth(token);
  },

  /**
   * Двухфакторная аутентификация
   * @param {string} code Код для 2FA
   */
  async auth2FA(code) {
    const token = await this.sendRequest(
      '/auth/2fa',
      {
        method: 'POST',
        isAuth: true,
      },
      {
        code,
      }
    );
    this.updateAuth(token);
  },

  /**
   * Повторная отправка второго фактора
   */
  async auth2FARefresh() {
    const token = await this.sendRequest(
      '/auth/2fa',
      {
        method: 'PUT',
        isAuth: true,
      },
      {}
    );
    this.updateAuth(token);
  },

  /**
   * Логаут
   */
  authLogout() {
    return this.request(
      '/auth/logout',
      {
        method: 'POST',
      },
      {
        refreshToken: this.getRefreshToken(),
      },
      {
        blocked: true,
      }
    ).then(this.clearAuth);
  },

  getLead() {
    return this.request(`/widget/lead`, {
      method: 'GET',
      isPrivate: true,
    }).then(res => res);
  },

  setLead(request) {
    return this.request(
      `/widget/lead`,
      {
        method: 'POST',
        isPrivate: true,
      },
      request
    ).then(res => res);
  },

  async sendFile(file) {
    await this.sendRequest(
      '/file',
      {
        method: 'POST',
        isAuth: true,
      },
      {
        file,
      }
    );
  },

  /**
   * Запрос брендов
   */
  async getBrands() {
    const brands = await this.sendRequest('/calculation/brands', {
      method: 'GET',
      isPrivate: false,
    });
    return brands.values;
  },

  /**
   * Запрос моделей
   */
  async getModels() {
    const models = await this.sendRequest('/calculation/models', {
      method: 'GET',
      isPrivate: false,
    });
    return models;
  },

  /**
   * Запрос дилеров
   */
  async getDealers(brand) {
    const dealers = await this.sendRequest(
      `/calculation/dealers?brand=${brand}`,
      {
        method: 'GET',
        isPrivate: false,
      }
    );
    return dealers;
  },

  /**
   * Запрос списка документов
   */
  async getDocumentList() {
    const docs = await this.sendRequest('/calculation/documents', {
      method: 'GET',
      isPrivate: false,
    });
    return docs;
  },

  /**
   * Проверка наличия субсидии
   */
  async getSubsidyPossibility({ brand, model }) {
    const result = await this.sendRequest(
      `/calculation/subsidy?brand=${brand}&model=${model}`,
      {
        method: 'GET',
        isPrivate: false,
      }
    );
    return result;
  },

  /**
   * Рассчитать кредит
   */
  async setCalculation({ target, save }) {
    const result = await this.sendRequest(
      '/calculation',
      {
        method: 'POST',
        isPrivate: false,
      },
      {
        target,
        save,
      }
    );
    return result;
  },

  /**
   *  // Запросить код подтверждения
   */
  async codeSign({ client, documents }) {
    const result = await this.sendRequest(
      `/code/sign`,
      {
        method: 'POST',
        isPrivate: true,
      },
      {
        client,
        documents,
        operationID: '3D961F43-6639-4CCF-9B09-B5F3038724D2',
      }
    );

    if (hasState('SUCCESS', result.body.state)) {
      return result.body.otpID;
    } else {
      throw new EMSError(result.body.errorInfo);
    }
  },

  /**
   * Отправить код подтверждения
   * @param otpID: String
   * @param code: String
   */
  async codeConfirm(otpID, code) {
    const result = await this.sendRequest(
      `/code/confirm`,
      {
        method: 'POST',
        isPrivate: true,
      },
      {
        code,
        otpID,
      }
    );

    if (hasState('OK', result.body.state)) {
      return result;
    } else {
      const e = new Error();
      switch (result.body.state) {
        case 'CODE_IS_WRONG':
          e.code = 103;
          e.message = '-1';
          break;
        case 'ATTEMPT_EXPIRED':
          e.code = 103;
          e.message = '0';
          break;
        case 'TIMEOUT_EXPIRED':
          e.code = 0;
          e.message = 'expired code';
          break;
        default:
          e.code = 0;
          e.message = result.body.errorInfo.errorString
            ? result.body.errorInfo.errorString
            : 'Произошла ошибка';
      }
      throw e;
    }
  },

  /**
   * // Получить данные по заявке
   */
  async getCRM(leadID) {
    const result = await this.processRequest(`/online/crm?id=${leadID}`, {
      isPrivate: true,
    });
    this.setAppPosID(result.appPosID);
    return result;
  },

  /**
   * Создать заявку
   */
  async createCRM(data, signedDocs) {
    const result = await this.processRequest(
      `/online/crm/create`,
      {
        method: 'POST',
        isPrivate: true,
      },
      {
        appClientParams: data.appClientParams,
        appGeneralConditions: data.appGeneralConditions,
        signedDocs,
      }
    );
    this.updateAuth(result.tokens, result);
    return result.create;
  },

  /**
   * // Дополнить заявку.
   */
  async updateCRM(data, leadID, signedDocs) {
    const result = await this.processRequest(
      `/online/crm/update`,
      {
        method: 'POST',
        isPrivate: true,
      },
      {
        ...data,
        leadID,
        signedDocs,
      }
    );
    return result;
  },

  /**
   * // Отменить заявку.
   */
  async cancelCRM() {
    const leadID = api.getLeadId();
    const appPosID = api.getAppPosID();
    const result = await this.processRequest(
      `/online/crm/cancel`,
      {
        method: 'POST',
        isPrivate: true,
      },
      {
        leadID,
        appPosID,
      }
    );
    if (hasState('SUCCESS', result.state, result.operState)) {
      return result;
    } else {
      throw new Error('Произошла ошибка при отмене заявки');
    }
  },

  /**
   * // Создание заявки в POS
   */
  async createLoanApplication(data, signedDocs) {
    const leadAppID = api.getLeadId();
    const result = await this.processRequest(
      `/online/loan/create`,
      {
        method: 'POST',
        isPrivate: true,
      },
      {
        ...data,
        leadAppID,
        signedDocs,
      }
    );
    return result;
  },

  /**
   * // Подтверждение отправки заявки в POS
   */
  async confirmLoanApplication(optID, code) {
    const leadAppID = api.getLeadId();
    const result = await this.processRequest(
      `/online/loan/confirm`,
      {
        method: 'POST',
        isPrivate: true,
      },
      {
        leadAppID,
        optID,
        code,
      }
    );
    return result;
  },

  /**
   * // Обновление заявки в POS
   */
  async updateLoanApplication(data, signedDocs) {
    const leadAppID = api.getLeadId();
    const appPosID = api.getAppPosID();
    const result = await this.processRequest(
      `/online/loan/update`,
      {
        method: 'POST',
        isPrivate: true,
      },
      {
        ...data,
        leadAppID,
        appPosID,
        signedDocs,
      }
    );
    return result;
  },

  /**
   * Проверка действительности паспорта
   */
  async getPassportCompliance(data) {
    const result = await this.sendRequest(
      `/online/getCompliance`,
      {
        method: 'POST',
        isPrivate: true,
      },
      data
    );
    return result.response.compliances.compliance[0].checkResult === 'NO_MATCH';
  },

  async processRequest(method, opts, body) {
    const result = await this.sendRequest(method, opts, body);
    if (hasState('ERROR', result.state, result.operState)) {
      throw new EMSError(result.errorInfo);
    }
    return result;
  },

  async sendRequest(method, opts, body) {
    if (opts.isPrivate && this.needRefresh()) {
      try {
        await this.authRefresh();
        return await this.sendRequest(method, opts, body);
      } catch (e) {
        throw e;
      }
    }

    const requestHash = [method, opts.method, JSON.stringify(body)].join('_');
    let promise = this.queue.get(requestHash);
    if (!promise) {
      const headers = await this.getHeaders(
        opts.isPrivate || opts.isAuth,
        opts.withRecaptcha
      );
      return (promise = fetch(`${REACT_APP_API_URL}${method}`, {
        method: opts.method || 'GET',
        headers,
        body: body && JSON.stringify(body),
      })
        .then(res =>
          res.json().then(json => {
            if (res.status === 400) {
              if (json.message === 'Invalid token') {
                this.clearAuth();
              }
              throw json;
            }
            if (!res.ok) {
              throw json;
            }
            return json.data;
          })
        )
        .then(data => {
          return data;
        })
        .catch(err => {
          throw err;
        })
        .finally(() => this.queue.delete(requestHash)));
    }

    this.queue.set(requestHash, promise);
  },

  async getHeaders(isPrivate = false, withRecaptcha = false) {
    const headers = {
      Pragma: 'no-cache',
      'Content-Type': 'application/json',
    };

    if (isPrivate) {
      headers['x-auth-token'] = this.getAccessToken();
    }

    if (withRecaptcha) {
      headers['g-recaptcha-response'] = await this.getReCaptchaToken();
    }

    return headers;
  },

  setPhone(phone) {
    this.phone = phone;
    this.storage.setItem('userPhone', phone);
  },

  getPhone() {
    if (this.phone === undefined) {
      this.phone = this.storage.getItem('userPhone');
    }
    return this.phone;
  },

  setLeadId(leadID) {
    this.storage.setItem('widgetLeadId', leadID);
  },

  getLeadId() {
    return this.storage.getItem('widgetLeadId');
  },

  setAppPosID(appPosID) {
    this.storage.setItem('widgetAppPosID', appPosID);
  },

  getAppPosID() {
    return this.storage.getItem('widgetAppPosID');
  },

  updateAuth(tokens, res) {
    if (tokens.hasOwnProperty('username')) {
      api.setPhone(tokens.username);
    }
    if (tokens.hasOwnProperty('accessToken')) {
      api.setExpiresAccessToken(tokens.expiresInSeconds);
      api.setAccessToken(tokens.accessToken);
      const leadID = this.decodeLeadID();
      if (leadID) {
        this.setLeadId(leadID);
      }
    }
    if (tokens.hasOwnProperty('refreshToken')) {
      api.setRefreshToken(tokens.refreshToken);
    }

    if (res && res.create) {
      this.setLeadId(res.create.leadID);
    }

    return tokens;
  },

  clearAuth: res => {
    [
      'widgetExpiresAccessToken',
      'widgetAccessToken',
      'widgetRefreshToken',
      'userPhone',
      'widgetLeadId',
      'widgetAppPosID',
    ].forEach(key => {
      api[key] = '';
      api.storage.removeItem(key);
    });

    return res;
  },

  setExpiresAccessToken(expiresInSeconds) {
    this.expiresAccessToken =
      new Date().getTime() + (expiresInSeconds - 5) * 1000;
    this.storage.setItem(
      'widgetExpiresAccessToken',
      `${this.expiresAccessToken}`
    );
  },

  getExpiresAccessToken() {
    if (!this.expiresAccessToken) {
      return new Date().setTime(
        +this.storage.getItem('widgetExpiresAccessToken')
      );
    }
    return this.expiresAccessToken;
  },

  setAccessToken(value) {
    this.accessToken = value;
    this.storage.setItem('widgetAccessToken', this.accessToken);
  },

  getAccessToken() {
    if (!this.accessToken) {
      return this.storage.getItem('widgetAccessToken');
    }
    return this.accessToken;
  },

  setRefreshToken(value) {
    this.refreshToken = value;
    this.storage.setItem(
      'widgetRefreshToken',
      aes.encrypt(this.refreshToken, this.cryptKey).toString()
    );
  },

  getAccessTokenData() {
    const token = this.getAccessToken();
    return jwt.decode(token, { complete: true }).payload;
  },

  getOptID() {
    const data = this.getAccessTokenData();
    return data.verification && data.verification.optID;
  },

  decodeLeadID() {
    const data = this.getAccessTokenData();
    return data.ids && data.ids.leadID;
  },

  getRefreshToken() {
    if (!this.refreshToken && this.storage.getItem('widgetRefreshToken')) {
      return aes
        .decrypt(this.storage.getItem('widgetRefreshToken'), this.cryptKey)
        .toString(encUtf8);
    }
    return this.refreshToken;
  },
  needRefresh() {
    if (!this.getExpiresAccessToken()) {
      return true;
    }

    return new Date().getTime() > this.getExpiresAccessToken();
  },

  async getReCaptchaToken() {
    const { grecaptcha } = window;
    if (!grecaptcha) {
      return;
    }
    try {
      if (grecaptcha) {
        return await grecaptcha.execute(REACT_APP_RECAPTCHA_KEY);
      }
      throw new Error('ReCaptcha not loaded');
    } catch (e) {
      console.error('e ReCaptcha not loaded', e);
      throw e;
    }
  },

  /**
   * Получить название страны по коду
   */
  async getCountryName(code) {
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + REACT_APP_DADATA_TOKEN,
      },
      body: JSON.stringify({ query: code }),
    };

    try {
      const result = await fetch(
        'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/country',
        options
      )
        .then(response => response.json())
        .then(result => result.suggestions[0].value);

      return result;
    } catch (e) {
      throw e;
    }
  },

  /**
   * Получить строку адреса
   */
  async getAddressString(string) {
    const options = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + REACT_APP_DADATA_TOKEN,
      },
      body: JSON.stringify({ query: string }),
    };

    try {
      const result = await fetch(
        'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
        options
      )
        .then(response => response.json())
        .then(result => result.suggestions[0].value);

      return result;
    } catch (e) {
      throw e;
    }
  },
};
export default api;

export class NotFoundRefreshTokenError extends Error {}

function hasState(state, ...result) {
  for (let arg of result) {
    if (typeof arg === 'string') {
      if (arg.toUpperCase() === state) {
        return true;
      }
    }
  }
  return false;
}

function EMSError(errorObj) {
  if (typeof errorObj !== 'object' || errorObj === null) {
    return;
  }
  this.message = errorObj.errorString;
  this.name = errorObj.errorString;
  this.code = +errorObj.errorCode;
}
