import { action, computed, observable } from 'mobx';
import { bankList } from 'src/lists/lists';

export default class AdditionalInfoEntity {
  @observable store;
  @observable bankPartnerName;
  _bankPartnerInn;
  @observable relativeName;
  @observable relativeType;
  @observable infoFromSMS = true;
  @observable infoFromPush = true;

  @observable questions = [
    {
      id: 'foreignPublicExecutive',
      label: 'Являетесь ли Вы публичным должностным лицом?',
      value: false,
    },
    {
      id: 'relativeForeignPublicExecutive',
      label:
        'Являетесь ли Вы супругом(ой), близким родственником (родственники по прямой восходящей и нисходящей линии (родители и дети, дедушки, бабушки и внуки), полнородные и неполнородные (имеющие общих отца или мать) братья и сестры, усыновители и усыновленные) иностранного публичного должностного лица?',
      value: false,
    },
    {
      id: 'isDebtorCloseRelative',
      label:
        'Являетесь ли Вы близким родственником по отношению к текущему заемщику/поручителю?',
      value: false,
    },
    {
      id: 'dealersWhereStocks',
      label:
        'Перечислите все компании, являющиеся действующими официальными дилерами Volkswagen, Toyota, Renault, в капитале каждой из которых Вы владеете более 20% долей/акций/паев (просьба указать полное юридическое наименование каждой такой компании)',
      value: '',
    },
    {
      id: 'bankruptcyInTheLastFiveYears',
      label:
        'Проводились ли в отношении Вас процедуры банкротства за последние 5 лет?',
      value: false,
    },
    {
      id: 'foreignPublicCompanyExecutive',
      label:
        'Являетесь ли Вы должностным лицом публичной международной организации?',
      value: false,
    },
    {
      id: 'stateExecutive',
      label:
        'Являетесь ли Вы лицом, замещающим (занимающим) государственную должность РФ, должность члена Совета директоров Банка России, должность федеральной государственной службы, назначение на которую и освобождение от которой осуществляется Президентом РФ?',
      value: false,
    },
    {
      id: 'bankRFExecutive',
      label:
        'Являетесь ли Вы лицом, замещающим (занимающим) должность в Банке России, государственной корпорации или иной организации, созданной РФ на основании федерального закона, включенную в перечень должностей, определяемый Президентом РФ?',
      value: false,
    },
    {
      id: 'isPersonWhoCanControl',
      label:
        'Имеются ли физические лица, имеющие возможность контролировать Ваши действия?',
      value: false,
    },
    {
      id: 'clientRelativeIsWorkerVWBR',
      label: 'Является ли кто-то из Ваших родственников сотрудником Банка?',
      value: false,
    },
  ];

  bankList = bankList;

  constructor(store) {
    this.store = store;
  }

  @action
  updateFields(data) {
    this.setBankPartnerName(
      data.appGeneralConditions && data.appGeneralConditions.bankPartnerName
    );
    for (let key in data.appClientParams) {
      const value = data.appClientParams[key];
      if (this.hasOwnProperty(key)) {
        this[key] = value;
      }

      const questions = this.questions.map(q => q.id);
      if (questions.includes(key)) {
        this.switchQuestion(key, value);
      }

      if (key === 'fioForeignPublicExecutive') {
        this.setRelativeName(value);
      }
    }
  }

  getAnswerById(id) {
    return this.questions.find(o => o.id === id).value;
  }

  @computed
  get hasPublicRelative() {
    return this.questions.find(o => o.id === 2).value;
  }

  @action.bound
  switchQuestion(id, value) {
    this.questions.forEach(o => {
      if (o.id === id) {
        o.value = value;
      }
    });
  }

  @action.bound
  onAdditionalFieldChange(info) {
    const changedFields = info.changedFields[0];
    if (!changedFields) {
      return;
    }
    const field = changedFields.name[0];
    const value = changedFields.value;
    if (this.hasOwnProperty(field)) {
      this[field] = value;
    }
  }

  @action.bound
  setRelativeName(v) {
    this.relativeName = v;
  }

  @action.bound
  setRelativeType(v) {
    this.relativeType = v;
  }

  get clientRelativeIsWorkerVWBR() {
    return this.getAnswerById('clientRelativeIsWorkerVWBR');
  }

  get foreignPublicExecutive() {
    return this.getAnswerById('foreignPublicExecutive');
  }

  get relativeForeignPublicExecutive() {
    return this.getAnswerById('relativeForeignPublicExecutive');
  }

  get isDebtorCloseRelative() {
    return this.getAnswerById('isDebtorCloseRelative');
  }

  get bankruptcyInTheLastFiveYears() {
    return this.getAnswerById('bankruptcyInTheLastFiveYears');
  }

  get foreignPublicCompanyExecutive() {
    return this.getAnswerById('foreignPublicCompanyExecutive');
  }

  get stateExecutive() {
    return this.getAnswerById('stateExecutive');
  }

  get bankRFExecutive() {
    return this.getAnswerById('bankRFExecutive');
  }

  get isPersonWhoCanControl() {
    return this.getAnswerById('isPersonWhoCanControl');
  }

  get dealersWhereStocks() {
    return this.getAnswerById('dealersWhereStocks');
  }

  get fioForeignPublicExecutive() {
    return (
      this.relativeForeignPublicExecutive &&
      `${this.relativeName || ''} ${this.relativeType || ''}`
    );
  }

  get bankPartnerInn() {
    const found = this.bankList.find(
      bank => bank.name === this.bankPartnerName
    );
    return found ? found.inn : undefined;
  }

  set bankPartnerInn(v) {
    this._bankPartnerInn = v;
  }

  setBankPartnerName(v) {
    this.bankPartnerName = v;
  }
}
