import React from 'react';

export default class IconPlus extends React.Component {
  render() {
    const { onClick } = this.props;
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      >
        <circle cx="12" cy="12" r="12" fill="#66E4EE" />
        <rect x="11" y="5" width="2" height="14" fill="white" />
        <rect
          x="5"
          y="13"
          width="2"
          height="14"
          transform="rotate(-90 5 13)"
          fill="white"
        />
      </svg>
    );
  }
}
