import React from 'react';

export default class IconCalendar extends React.Component {
  render() {
    return (
      <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.07549 2.33042L3.85156 2.33042V3.11467C3.85156 3.64671 4.28287 4.07802 4.81492 4.07802C5.34696 4.07802 5.77827 3.64671 5.77827 3.11467V2.33042L11.5586 2.33042V3.11467C11.5586 3.64671 11.9899 4.07802 12.5219 4.07802C13.054 4.07802 13.4853 3.64671 13.4853 3.11467V2.33042L15.9093 2.33042V4.66058H2.07549L2.07549 2.33042ZM13.4568 0.729622H16.7342C17.4263 0.729622 17.9844 1.2002 17.9844 1.78046V9.32131H17.9826V13.9492C17.9826 14.5299 17.4239 15 16.7324 15H1.25014C0.558099 15 0 14.5294 0 13.9492V9.32119H0.0017983L0.00179864 6.05875H0V4.66058H0.00179879L0.00179909 1.78046C0.00179915 1.19968 0.560519 0.729622 1.25194 0.729622H3.88011C3.98456 0.31052 4.36348 0 4.81492 0C5.26636 0 5.64527 0.31052 5.74972 0.729622H11.5871C11.6916 0.31052 12.0705 0 12.5219 0C12.9734 0 13.3523 0.31052 13.4568 0.729622ZM2.07549 6.05875H15.9093V9.32119H15.9089V13.3992H2.07507V9.32131H2.07549L2.07549 6.05875Z"
          fill="#004666"
        />
      </svg>
    );
  }
}
