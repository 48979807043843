import { action, computed, observable } from 'mobx';

export default class AuthEntity {
  @observable store;
  @observable firstName = '';
  @observable lastName = '';
  @observable middleName = '';
  @observable clientEmail = '';
  @observable clientPassword;
  @observable accordancePersonInfo = false;
  @observable infoFromPromotionalMaterials = true;
  @observable _phone = '';
  @observable documentNumber = undefined;

  optID = '';

  constructor(store) {
    this.store = store;
  }

  @computed
  get clientPhoneMobile() {
    return this._phone || '';
  }

  set clientPhoneMobile(v) {
    this._phone = v;
  }

  @action.bound
  setLeadID(string) {
    this.documentNumber = string;
  }

  @action
  updateFields(data) {
    for (let key in data.appClientParams) {
      const value = data.appClientParams[key];
      if (this.hasOwnProperty(key)) {
        if (key.toLowerCase().includes('date') && value) {
          this[key] = new Date(value);
        } else {
          this[key] = value;
        }
      }
    }
  }

  @computed
  get buttonDisabled() {
    return (
      !this.clientPhoneMobile ||
      !this.firstName ||
      !this.lastName ||
      !this.clientPassword ||
      !this.accordancePersonInfo
    );
  }

  @action.bound
  onAuthFieldChange(info) {
    const changedFields = info.changedFields[0];
    if (!changedFields) {
      return;
    }

    const field = changedFields.name[0];
    const value = changedFields.value;

    this[field] = value;
  }
}
