import React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'antd';
import DialogBox from '../components/DialogBox';

const DialogModal = props => {
  const { dialogStore } = props;
  const { open, header, message, onClose, width } = dialogStore;
  return (
    <Modal
      width={width}
      className={'modal dialog-modal'}
      centered
      visible={open}
      footer={null}
      onOk={console.log}
      onCancel={onClose}
    >
      <DialogBox message={message} header={header} />
    </Modal>
  );
};

export default inject('dialogStore')(observer(DialogModal));
