import Text from 'antd/es/typography/Text';
import React from 'react';

const SubsidyWarningMessage = () => {
  return (
    <>
      <Text style={{ color: '#004666', fontWeight: 'bold' }}>
        Обратите внимание!
      </Text>
      <br />
      <br />
      <Text>
        Применение государственных программ «Первый автомобиль», «Семейный
        автомобиль», «Медицинский работник», «Trade-in» возможно при выполнении
        следующих условий:
      </Text>
      <ul>
        <li>вы приобретаете VW Polo или Skoda Rapid с двигателем 1.6 MPI;</li>
        <li>ЭПТС выдан не ранее 1 декабря 2019 г.;</li>
        <li>цена приобретаемого автомобиля не превышает 1,5 млн. руб;</li>
        <li>
          вы в 2019-2020 годах не получали и обязуетесь не получать
          автокредитов, кроме выдаваемого;
        </li>
        <li>
          <strong>у вас есть водительское удостоверение;</strong>
        </li>
        <li>
          <strong>
            у вас в собственности не было автомобилей (для программы «Первый
            автомобиль»);
          </strong>
        </li>
        <li>
          <strong>
            у вас один или более несовершеннолетних ребенка (для программы
            «Семейный автомобиль»);
          </strong>
        </li>
        <li>
          <strong>
            у вас есть справка с работы (2-НДФЛ или по форме Банка),
            подтверждающая, что вы являетесь работником государственной системы
            здравоохранения ( для программы «Медицинский работник»);
          </strong>
        </li>
        <li>
          <strong>
            вы продаете Дилеру свой автомобиль возрастом свыше 6 лет  при
            условии владения им не менее 1 года в счет уплаты части
            первоначального взноса по новому автомобилю (для программы
            «Trade-in»);
          </strong>
        </li>
      </ul>
    </>
  );
};

export default SubsidyWarningMessage;
