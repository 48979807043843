import React from 'react';
import InputDropdownBox from 'src/components/InputDropdownBox';
import useStores from 'src/hooks/useStores';

const EducationDropdownBox = props => {
  const { requestStore } = useStores();
  const { personalEntity } = requestStore;
  const { clientEducationTypeList } = personalEntity;
  return (
    <InputDropdownBox
      items={clientEducationTypeList.map(o => o.label)}
      label={'Образование*'}
      placeholder="Выберите из списка"
      {...props}
    />
  );
};

export default EducationDropdownBox;
