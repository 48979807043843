import React from 'react';

import { observer } from 'mobx-react';
import RequestPageLayout from '../layouts/RequestPageLayout';
import QAForm from '../forms/QAForm';
import DealerDataForm from '../forms/DealerDataForm';
import ButtonPrimaryBox from '../components/ButtonPrimaryBox';
import { Col, Form, Row, Typography } from 'antd';
import ConsentNoticeForm from '../forms/ConsentNoticeForm';
import useStores from 'src/hooks/useStores';
import NoteBox from 'src/components/NoteBox';
import { scrollToError } from 'src/helpers/helpers';
import QuestionnaireConfirmationInfoBox from 'src/components/QuestionnaireConfirmationInfoBox';
import ButtonSecondaryBox from '../components/ButtonSecondaryBox';

const { Text } = Typography;

const AdditionalDataPage = () => {
  const { requestStore, globalState } = useStores();
  const { formsReadOnly } = globalState;
  const {
    openUpload,
    additionalInfoEntity,
    dealerEntity,
    onSubmit,
    validateDocs,
    setForm,
    fileStore,
    updatePOS,
  } = requestStore;
  const {
    bankPartnerName,
    bankList,
    infoFromSMS,
    infoFromPush,
    relativeName,
    relativeType,
  } = additionalInfoEntity;
  const { dealerCity, dealerSalePointName } = dealerEntity;

  const [form] = Form.useForm();

  const formName = 'additionalData';

  const initialValues = {
    infoFromPush,
    infoFromSMS,
    bankPartnerName,
    dealerCity,
    dealerSalePointName,
    relativeName,
    relativeType,
  };

  if (form) {
    setForm(formName, form, Object.keys(initialValues));
  }

  const onFinish = () => {
    onSubmit(formName);
  };

  return (
    <RequestPageLayout>
      <Form
        form={form}
        name={formName}
        initialValues={initialValues}
        fields={[
          { name: 'dealerSalePointName', value: dealerSalePointName },
          { name: 'dealerCity', value: dealerCity },
          { name: 'bankPartnerName', value: bankPartnerName },
        ]}
        onFinish={onFinish}
        onFinishFailed={scrollToError}
      >
        <DealerDataForm bankList={bankList} formDisabled={formsReadOnly} />

        <Form.Item
          name={'addDocuments'}
          rules={[
            () => ({
              validator() {
                if (validateDocs(fileStore)) {
                  return Promise.resolve();
                }
                return Promise.reject('Прикрепите документы');
              },
            }),
          ]}
        >
          <Row gutter={[26, 0]}>
            <Col>
              <ButtonPrimaryBox
                className={'thin-button'}
                onClick={openUpload}
                disabled={formsReadOnly}
              >
                ПРИКРЕПИТЬ ДОКУМЕНТЫ
              </ButtonPrimaryBox>
            </Col>
            <Col
              span={6}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Text className={'upload-text'}>
                {'Скан или фото. Максимальный размер 5Мб. Тип файла: JPG, PDF'}
              </Text>
            </Col>
          </Row>
        </Form.Item>

        <QAForm formDisabled={formsReadOnly} />

        <NoteBox />

        <ConsentNoticeForm formDisabled={formsReadOnly} />

        <QuestionnaireConfirmationInfoBox />

        <Form.Item>
          <ButtonPrimaryBox htmlType={'submit'} disabled={formsReadOnly}>
            ОТПРАВИТЬ ЗАЯВКУ
          </ButtonPrimaryBox>
        </Form.Item>
      </Form>
      <ButtonSecondaryBox
        style={{ float: 'right', opacity: 0.3 }}
        onClick={updatePOS}
      >
        updateLoanApp
      </ButtonSecondaryBox>
    </RequestPageLayout>
  );
};

export default observer(AdditionalDataPage);
