import React from 'react';
import MaskedInput from 'antd-mask-input';
import { Row, Typography } from 'antd';

const { Text } = Typography;

const InputMaskedBox = props => {
  const { label, onChange, helperText, ...other } = props;
  const onInput = e => onChange(e.target.value.replace(/[()+\-/s_]/g, ''));
  return (
    <div className={'input-box masked'}>
      {label && (
        <Row>
          <Text className={'input-label'}>{label}</Text>
        </Row>
      )}
      <Row
        style={{
          paddingTop: label ? 0 : '36px',
        }}
      >
        <MaskedInput onChange={onInput} {...other} />
      </Row>
      <div className={'helper-text'}>
        {helperText && (
          <Row>
            <Text>{helperText}</Text>
          </Row>
        )}
      </div>
    </div>
  );
};

export default InputMaskedBox;
