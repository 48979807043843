import React from 'react';
import { Row, Col, Form } from 'antd';
import InputBox from '../components/InputBox';
import DaDataBox from '../components/DaDataBox';
import DatePickerBox from '../components/DatePickerBox';
import CheckBox from '../components/CheckBox';
import {
  addressRequired,
  registrationDate,
  required,
} from '../services/validators';

const AddressForm = props => {
  const {
    clientAddressActualSameAsRegistration,
    addressRegistrationFIAS,
    addressLivingFIAS,
    setAddressLivingFIAS,
    setAddressRegistrationFIAS,
    isLivingRandom,
    isRegistrationRandom,
    toggleRegistrationRandomFlag,
    toggleLivingRandomFlag,
    formDisabled,
  } = props;
  const onKeyDown = e => {
    if (/^[a-zA-Z]$/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Row gutter={[26, 32]}>
        <Col span={12}>
          <Form.Item
            name={'addressRegistration'}
            validateTrigger={'onBlur'}
            rules={[
              form =>
                addressRequired(
                  form,
                  addressRegistrationFIAS,
                  form.getFieldValue('addressRegistrationRandom')
                ),
            ]}
          >
            <DaDataBox
              label={'Адрес регистрации*'}
              placeholder={'Введите полный адрес регистрации'}
              additionalLabel={'Не могу найти правильный адрес'}
              additionalFn={toggleRegistrationRandomFlag}
              mutationFn={setAddressRegistrationFIAS}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Row gutter={9}>
            <Col span={12}>
              <Form.Item name={'flatRegister'}>
                <InputBox
                  label={'Квартира'}
                  placeholder={'Номер квартиры'}
                  disabled={formDisabled}
                  onKeyDown={onKeyDown}
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                dependencies={['clientBirthdate']}
                name={'clientAddressRegDate'}
                rules={[required, registrationDate]}
              >
                <DatePickerBox
                  label={'Дата регистрации*'}
                  disabled={formDisabled}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      {isRegistrationRandom && (
        <Row gutter={[26, 32]}>
          <Col span={12}>
            <Form.Item name={'addressRegistrationRandom'}>
              <InputBox
                label={'Адрес регистрации (в произвольной форме)'}
                placeholder={'Введите полный адрес регистрации'}
                disabled={formDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row
        gutter={[26, 0]}
        style={{
          marginTop: -15,
          paddingBottom: 32,
        }}
      >
        <Col style={{ width: '100%' }}>
          <Form.Item
            name={'clientAddressActualSameAsRegistration'}
            valuePropName={'checked'}
          >
            <CheckBox
              small={true}
              label={'Адрес проживания совпадает с местом регистрации'}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
      {!clientAddressActualSameAsRegistration && (
        <Row gutter={[26, 32]}>
          <Col span={12}>
            <Form.Item
              name={'addressLiving'}
              validateTrigger={'onBlur'}
              rules={[
                form =>
                  addressRequired(
                    form,
                    addressLivingFIAS,
                    form.getFieldValue('addressLivingRandom')
                  ),
              ]}
            >
              <DaDataBox
                label={'Адрес проживания*'}
                placeholder={'Введите полный адрес проживания'}
                additionalLabel={'Не могу найти правильный адрес'}
                additionalFn={toggleLivingRandomFlag}
                mutationFn={setAddressLivingFIAS}
                disabled={formDisabled}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row gutter={9}>
              <Col span={12}>
                <Form.Item name={'flatLiving'}>
                  <InputBox
                    label={'Квартира'}
                    placeholder={'Номер квартиры'}
                    disabled={formDisabled}
                    onKeyDown={onKeyDown}
                    maxLength={10}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {isLivingRandom && !clientAddressActualSameAsRegistration && (
        <Row gutter={[26, 32]}>
          <Col span={12}>
            <Form.Item name={'addressLivingRandom'}>
              <InputBox
                label={'Адрес проживания (в произвольной форме)'}
                placeholder={'Введите полный адрес проживания'}
                disabled={formDisabled}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};

export default AddressForm;
