import React from 'react';
import QuestionSwitchBox from '../components/QuestionSwitchBox';
import { inject, observer } from 'mobx-react';
import QuestionTextBox from '../components/QuestionTextBox';
import RelativeQuestionBox from 'src/components/RelativeQuestionBox';

const QAForm = props => {
  const { requestStore, formDisabled } = props;
  const { additionalInfoEntity } = requestStore;
  const { questions, switchQuestion } = additionalInfoEntity;
  const rowClass = 'qa-row';
  return (
    <div className={'qa-box'}>
      {questions.map((o, i) => {
        if (i === 1) {
          return (
            <RelativeQuestionBox
              className={rowClass}
              key={o.id}
              label={o.label}
              value={o.value}
              onChange={value => switchQuestion(o.id, value)}
              disabled={formDisabled}
            />
          );
        }
        if (i === 3) {
          return (
            <QuestionTextBox
              className={rowClass}
              key={o.id}
              label={o.label}
              value={o.value}
              onChange={e => switchQuestion(o.id, e.target.value)}
              disabled={formDisabled}
            />
          );
        }
        return (
          <QuestionSwitchBox
            className={rowClass}
            key={o.id}
            label={o.label}
            checked={o.value}
            onChange={value => switchQuestion(o.id, value)}
            disabled={formDisabled}
          />
        );
      })}
    </div>
  );
};
export default inject('requestStore')(observer(QAForm));
