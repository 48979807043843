import React from 'react';
import { Checkbox, Row, Typography } from 'antd';

const { Text } = Typography;

const CheckBoxYesNo = props => {
  const { label, checked, ...other } = props;
  const mark = checked ? 'ДА' : 'НЕТ';

  return (
    <>
      <Row>
        <Text>{label}</Text>
      </Row>
      <Row>
        <Checkbox
          className={'checkbox checkbox-yes-no small'}
          {...other}
          checked={checked}
        >
          {mark}
        </Checkbox>
      </Row>
    </>
  );
};

export default CheckBoxYesNo;
