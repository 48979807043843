import React from 'react';
import { Typography } from 'antd';
import IconClear from './icons/IconClear';
import { observer } from 'mobx-react';

const { Text } = Typography;

const UploadBox = props => {
  const { label, id, disabled, fileStore } = props;

  let el;
  const preventDefaults = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const highLight = e => {
    el = e.target;
    el.classList.add('highlight');
  };

  const unHighLight = () => el && el.classList.remove('highlight');

  const handleDrop = e => {
    const dt = e.dataTransfer;
    let files = dt.files;
    fileStore.save(files, id);
  };

  const onChange = e => {
    e.persist();
    const files = e.target.files;
    fileStore.save(files, id);
    //надо очищать FileList, на случай повторного прикрепления документа
    e.target.value = '';
  };

  const names = fileStore.getNamesByDoc(id);
  const hasErrors = fileStore.hasErrorByDoc(id);

  return (
    <>
      <div className={'upload-box'}>
        <input
          id={id}
          accept={'.pdf,.jpg'}
          type="file"
          onChange={onChange}
          style={{ display: 'none' }}
          disabled={disabled}
        />
        {label && <Text className={'upload-box-label-title'}>{label}</Text>}
        <label
          htmlFor={id}
          className={'button-primary'}
          style={{ display: 'flex' }}
        >
          <div
            className={['ant-btn', disabled ? 'disabled' : ''].join(' ')}
            component="span"
            onDrop={e => preventDefaults(e)}
          >
            ВЫБРАТЬ ФАЙЛ(Ы)
          </div>
          <div
            className={'drag-box'}
            onDragEnter={e => {
              preventDefaults(e);
              highLight(e);
            }}
            onDragOver={e => {
              preventDefaults(e);
              highLight(e);
            }}
            onDragLeave={e => {
              preventDefaults(e);
              unHighLight(e);
            }}
            onDrop={e => {
              preventDefaults(e);
              unHighLight(e);
              handleDrop(e);
            }}
          >
            <Text>Перетащить</Text>
          </div>
        </label>
        {names.map(({ index, fileName, error }) => (
          <div key={index} className={'upload-list-item-name'}>
            <Typography className={error ? 'error' : ''}>{fileName}</Typography>
            <IconClear onClick={() => fileStore.removeFile(id, index)} />
          </div>
        ))}
      </div>

      {hasErrors && (
        <div style={{ margin: '-30px 0 20px' }}>
          <Text className={'error'}>
            Выбранный файл не соответствует требованиям к типу или размеру файла
          </Text>
        </div>
      )}
    </>
  );
};
export default observer(UploadBox);
