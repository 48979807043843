import React from 'react';
import { Row, Col, Form } from 'antd';
import InputNumberBox from '../components/InputNumberBox';
import InputDropdownBox from '../components/InputDropdownBox';
import HelperIconBox from '../components/HelperIconBox';
import GovernmentSwitchBox from '../components/GovernmentSwitchBox';
import { cost, required } from '../services/validators';
import SubsidyMessage from 'src/components/SubsidyMessage';
import SubsidyNameDropdownBox from '../components/SubsidyNameDropdownBox';

const CarPropertiesForm = props => {
  const {
    brand,
    model,
    setModel,
    setBrand,
    toggleGovProgram,
    govProgram,
    govProgramDisabled,
    setGovProgram,
    subsidyPossibility,
    brands,
    models,
    setCost,
    formDisabled,
  } = props;

  return (
    <>
      <Row gutter={[33, 40]}>
        <Col span={12}>
          <Form.Item>
            <InputDropdownBox
              label={'Марка'}
              items={brands}
              value={brand}
              placeholder={'Не выбрано'}
              onChange={setBrand}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <InputDropdownBox
              label={'Модель'}
              items={models}
              value={model}
              placeholder={'Не выбрано'}
              onChange={setModel}
              disabled={!brand || formDisabled}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[40, 40]} align={'middle'}>
        <Col span={12}>
          <Form.Item
            name={'carCost'}
            validateTrigger={'onBlur'}
            rules={[required, _ => cost(_, 5e5)]}
          >
            <InputNumberBox
              placeholder={'500 000'}
              label={'Стоимость автомобиля'}
              onChange={setCost}
              suffix={'₽'}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
        {subsidyPossibility && (
          <>
            <Col flex={'210px'}>
              <Form.Item valuePropName={'checked'} name={'govProgram'}>
                <GovernmentSwitchBox
                  disabled={govProgramDisabled || formDisabled}
                  onChange={toggleGovProgram}
                  label={
                    <span>
                      Госпрограмма{' '}
                      <HelperIconBox message={<SubsidyMessage />} />
                    </span>
                  }
                />
              </Form.Item>
            </Col>
            {govProgram && (
              <Col flex={'auto'}>
                <Form.Item name={'govProgramName'} rules={[required]}>
                  <SubsidyNameDropdownBox
                    onChange={setGovProgram}
                    disabled={formDisabled}
                  />
                </Form.Item>
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default CarPropertiesForm;
