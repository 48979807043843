export const bindMethods = (methods, context) => {
  methods.forEach(method => (context[method] = context[method].bind(context)));
};

export function scrollToError() {
  const el =
    document.querySelector('.ant-form-item-has-error') ||
    document.querySelector('.ant-form-item-has-error-leave');
  if (el) {
    window.scrollTo(0, findPosition(el));
  }
}

function findPosition(obj) {
  const offset = -100;
  let currentTop = offset;
  if (obj.offsetParent) {
    do {
      currentTop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
    return currentTop;
  }
}
