import React from 'react';
import InputDropdownBox from 'src/components/InputDropdownBox';
import useStores from 'src/hooks/useStores';
import { observer } from 'mobx-react';

const DealerCityDropdownBox = props => {
  const { requestStore } = useStores();
  const { dealerEntity } = requestStore;
  const { cityList } = dealerEntity;

  return (
    <InputDropdownBox
      items={cityList}
      label={'Город*'}
      placeholder="Выберите из списка"
      {...props}
    />
  );
};

export default observer(DealerCityDropdownBox);
