import React from 'react';

export default class IconInfo extends React.Component {
  render() {
    const { onClick } = this.props;
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          cursor: 'pointer',
          transform: 'translateY(2px)',
        }}
        onClick={onClick}
      >
        <path
          d="M6.90649 0.000636163C3.04079 0.0523751 -0.0508026 3.22838 0.000632601 7.0942C0.0523721 10.9583 3.22811 14.0508 7.09366 13.9994C10.9586 13.9473 14.0508 10.7713 13.9994 6.90581C13.9476 3.04136 10.7717 -0.0509506 6.90649 0.000636163ZM6.88579 11.2609L6.84714 11.2602C6.25183 11.2426 5.83213 10.804 5.84902 10.2176C5.86561 9.64112 6.29551 9.22265 6.87118 9.22265L6.90573 9.22325C7.51762 9.24136 7.9326 9.67551 7.91541 10.2789C7.89837 10.857 7.47517 11.2609 6.88579 11.2609ZM9.38983 6.29103C9.24983 6.48992 8.94198 6.73689 8.55409 7.03911L8.12693 7.33402C7.89243 7.51633 7.75091 7.68783 7.6978 7.85659C7.65595 7.98943 7.63541 8.02458 7.63175 8.29484L7.63115 8.36347H6.00013L6.00485 8.22545C6.02479 7.65815 6.03879 7.32444 6.2739 7.0487C6.64277 6.61561 7.45645 6.09168 7.49099 6.06946C7.60756 5.98166 7.70586 5.88168 7.77906 5.77485C7.95026 5.53883 8.02604 5.35303 8.02604 5.17042C8.02604 4.91705 7.95087 4.68271 7.80219 4.47408C7.6593 4.27275 7.38782 4.17079 6.99521 4.17079C6.60579 4.17079 6.33918 4.29436 6.1797 4.54788C6.01566 4.80855 5.93272 5.08231 5.93272 5.36201V5.43155H4.25103L4.25408 5.35896C4.29745 4.3289 4.6651 3.58721 5.34639 3.15443C5.77446 2.87884 6.30707 2.73915 6.92855 2.73915C7.74208 2.73915 8.42885 2.93682 8.96953 3.32669C9.51736 3.72173 9.79523 4.31338 9.79523 5.0852C9.79523 5.51692 9.65888 5.92246 9.38983 6.29103Z"
          fill="#66E4EE"
        />
      </svg>
    );
  }
}
