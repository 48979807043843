import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import LoginForm from 'src/components/LoginForm';
import useStores from 'src/hooks/useStores';

const LoginModal = () => {
  const { authStore } = useStores();
  const {
    authEntity,
    loginFormVisible,
    closeLoginForm,
    loginByLead,
    hasError,
    btnDisabled,
  } = authStore;
  const { documentNumber, setLeadID } = authEntity;

  return (
    <Modal
      width={684}
      className={'modal pin-code-modal'}
      centered
      visible={loginFormVisible}
      footer={null}
      onOk={console.log}
      onCancel={closeLoginForm}
      maskClosable={false}
    >
      <LoginForm
        documentNumber={documentNumber}
        setLead={setLeadID}
        hasError={hasError}
        btnDisabled={btnDisabled}
        onSend={loginByLead}
      />
    </Modal>
  );
};

export default observer(LoginModal);
