export const timer = {
  startTime: undefined,
  interval: undefined,
  callback: undefined,
  start(seconds, callback) {
    this.startTime = new Date().getTime() + seconds * 1e3;
    this.callback = callback;
    this.run();
  },
  restore(startTime, callback) {
    this.startTime = startTime;
    this.callback = callback;
    this.run();
  },
  run() {
    this.iterator();
    this.interval = setInterval(() => this.iterator(), 1e3);
  },
  iterator() {
    const count = this.count();

    if (count >= 0) {
      this.callback(count);
      if (count === 0) {
        this.clear();
      }
    } else {
      this.clear();
    }
  },
  count() {
    let count = Math.round((this.startTime - new Date().getTime()) / 1e3);
    return count <= 0 ? 0 : count;
  },
  clear() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
