import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/es';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

require('es6-promise').polyfill();
require('isomorphic-fetch');

// eslint-disable-next-line no-unused-vars
const initRecaptcha = () => {
  const REACT_APP_RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
  const script = document.createElement('script');
  script.setAttribute(
    'src',
    `https://www.google.com/recaptcha/api.js?render=${REACT_APP_RECAPTCHA_KEY}`
  );
  document.body.appendChild(script);
};
// initRecaptcha();

ReactDOM.render(
  //    <React.StrictMode> // warnings from antD
  <App />,
  //  </React.StrictMode>
  document.getElementById('root')
);

const render = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

// (async () => {
//   try {
//     const needRefresh = await api.needRefresh();
//     if (needRefresh && api.getRefreshToken()) {
//       const token = await api.authRefresh();
//       authStore.updateStatus(token);
//     }
//   } catch (e) {
//     dialogStore.catch(e);
//   } finally {
//     render();
//   }
// })();

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
