import { action, computed, observable } from 'mobx';
import formatters from '../../services/formatters';
import statuses from 'src/stores/lists/statuses';

export default class RequestReworkEntity {
  @observable store;
  @observable comment = '';
  @observable _status;
  @observable _requestDate;
  @observable cancelButtonShow = false;
  @observable correspondenceBoxShow = false;
  @observable calculatorBoxShow = false;
  @observable rejectInfoBoxShow = false;
  @observable bankMessage =
    'Возможно, вы допустили ошибку в номере мобильного телефона, из-за\n' +
    '          этого мы не можем дозвониться до Вас. Пожалуйста, проверьте\n' +
    '          правильность указанного номера.';
  @observable bankMessageDate = '17.06.2020, 15:45';

  constructor(store) {
    this.store = store;
  }

  get statusColor() {
    switch (this.status) {
      case statuses.approvedRequest:
        return '#00ffcc';
      default:
        return '#4C5356';
    }
  }

  @action.bound
  setComment(event) {
    const string = event.target.value;
    if (string.length > 1000) return;
    this.comment = string;
  }

  updateFields(data) {
    this.setRequestDate(
      data.appClientParams && data.appClientParams.consentDate
    );
    this.setStatus(data.loanAppStatus, data.dssAppStatus, data.appPosID);
  }

  @computed
  get number() {
    return this.store.documentNumber ? this.store.documentNumber : '';
  }

  setRequestDate(v) {
    this._requestDate = formatters.toDate(new Date(v));
  }

  @computed
  get requestDate() {
    return this._requestDate ? this._requestDate : '';
  }

  setStatus(statusPOS, statusDiasoft, appPosID) {
    let v = '';
    if (statusPOS === null && statusDiasoft === null) {
      v = 'new';
    } else if (
      statusPOS === statuses.project ||
      (statusPOS === null && appPosID)
    ) {
      v = statuses.sentToBank;
    } else if (
      ([statuses.checkDocuments, statuses.reapproval].indexOf(statusPOS) !==
        -1 &&
        statusDiasoft === statuses.input) ||
      (statusPOS === statuses.additionalCheck &&
        statusDiasoft === statuses.AMLCheck) ||
      (statusPOS === statuses.error && statusDiasoft === statuses.error) ||
      (statusPOS === statuses.creditAnalysis &&
        [
          statuses.autoCheck,
          statuses.underwriting,
          statuses.expertRecommendation,
          statuses.expertDecision,
          statuses.HUDecision,
          statuses.rejectionNotice,
          statuses.AMLCheckPassed,
        ].indexOf(statusDiasoft) !== -1)
    ) {
      v = statuses.onConsideration;
    } else if (
      statusPOS === statuses.rework &&
      [statuses.preliminary, statuses.additionalDocsRequest].indexOf(
        statusDiasoft
      ) !== -1
    ) {
      v = statuses.additionalInfoRequest;
    } else if (
      statusPOS === statuses.rework &&
      statusDiasoft === statuses.carInPledge
    ) {
      v = statuses.changeCarRequest;
    } else if (
      statusPOS === statuses.rework &&
      statusDiasoft === statuses.finWarranterRequest
    ) {
      v = statuses.cancelled;
    } else if (
      (statusPOS === statuses.altOffer &&
        statusDiasoft === statuses.alternativeOffer) ||
      (statusPOS === statuses.approved &&
        statusDiasoft === statuses.dealExpecting)
    ) {
      v = statuses.approvedRequest;
    } else if (
      statusPOS === statuses.clientRejection &&
      statusDiasoft === statuses.clientRejection
    ) {
      v = statuses.rejectedRequest;
    } else if (
      statusPOS === statuses.bankRejection &&
      [
        statuses.clientRejected,
        statuses.nationalHunterRejection,
        statuses.negativeCreditHistoryRejection,
        statuses.AMLRejection,
      ].indexOf(statusDiasoft) !== -1
    ) {
      v = statuses.bankRejection;
    } else if (
      statusPOS === statuses.outdated &&
      [
        statuses.outdated,
        statuses.reworkOutdated,
        statuses.approvalOutdated,
      ].indexOf(statusDiasoft) !== -1
    ) {
      v = statuses.outdatedRequest;
    }
    this._status = v;
    this.setCancelButtonShow(v);
    this.setCorrespondenceBoxShow(v);
    this.setCalculatorBoxShow(v);
    this.setRejectInfoBoxShow(v);
  }

  get status() {
    return this._status ? this._status : '';
  }

  setCancelButtonShow(status) {
    this.cancelButtonShow =
      [statuses.additionalInfoRequest, statuses.changeCarRequest].indexOf(
        status
      ) !== -1;
  }

  setCorrespondenceBoxShow(status) {
    this.correspondenceBoxShow =
      [statuses.additionalInfoRequest, statuses.changeCarRequest].indexOf(
        status
      ) !== -1;
  }

  setCalculatorBoxShow(status) {
    this.calculatorBoxShow =
      [
        statuses.sentToBank,
        statuses.onConsideration,
        statuses.additionalInfoRequest,
        statuses.changeCarRequest,
        statuses.approvedRequest,
      ].indexOf(status) !== -1;
  }

  setRejectInfoBoxShow(status) {
    this.rejectInfoBoxShow = status === statuses.cancelled;
  }

  @computed
  get calculatorHeader() {
    return this._status === statuses.approvedRequest
      ? 'Одобренные условия кредитования'
      : 'Выбранные условия кредитования';
  }

  @computed
  get collapseCalculator() {
    return this._status === statuses.approvedRequest ? ['1'] : undefined;
  }
}
