import React from 'react';
import useStores from 'src/hooks/useStores';
import CalculatorPage from 'src/pages/CalculatorPage';
import AuthPage from 'src/pages/AuthPage';
import PassportDataPage from 'src/pages/PassportDataPage';
import PersonalDataPage from 'src/pages/PersonalDataPage';
import IncomeDataPage from 'src/pages/IncomeDataPage';
import AdditionalDataPage from 'src/pages/AdditionalDataPage';
import MainLayout from 'src/layouts/MainLayout';
import { observer } from 'mobx-react';
import RequestStatusPage from '../pages/RequestStatusPage';

const RouterLayout = () => {
  const { globalState } = useStores();
  const { requestStatus } = globalState;

  if (requestStatus) {
    return <RequestStatusPage />;
  }

  let component;
  switch (globalState.activeMode) {
    case 'auth':
      component = <AuthPage />;
      break;
    case 'passportData':
      component = <PassportDataPage />;
      break;
    case 'personalData':
      component = <PersonalDataPage />;
      break;
    case 'incomeData':
      component = <IncomeDataPage />;
      break;
    case 'additionalData':
      component = <AdditionalDataPage />;
      break;
    case 'revision':
      component = <RequestStatusPage />;
      break;
    default:
      component = <CalculatorPage />;
      break;
  }
  return <MainLayout>{component}</MainLayout>;
};

export default observer(RouterLayout);
