export const educationList = [
  { label: 'MBA', value: 'MBA' },
  { label: 'Несколько высших образований', value: 'SEVERAL_HIGHER_EDUCATIONS' },
  { label: 'Высшее образование', value: 'HIGHER_EDUCATION' },
  { label: 'Начальное образование', value: 'PRIMARY_EDUCATION' },
  { label: 'Незаконченное высшее', value: 'INCOMPLETE_HIGHER_EDUCATION' },
  { label: 'Среднее образование', value: 'SECONDARY_EDUCATION' },
  {
    label: 'Среднее специальное образование',
    value: 'SECONDARY_VOCATIONAL_EDUCATION',
  },
  { label: 'Ученая степень', value: 'GRADUATE' },
  { label: 'Другое', value: 'OTHER' },
];

export const maritalStatusList = [
  { label: 'Гражданский брак', value: 'CIVIL_MARRIAGE' },
  { label: 'Замужем / Женат', value: 'MARRIED' },
  { label: 'Разведена / Разведен', value: 'DIVORCED' },
  { label: 'Вдова / Вдовец', value: 'WIDOWHOOD' },
  { label: 'Не замужем / Холост', value: 'SINGLE' },
];

export const secondDocumentList = [
  { id: '4', label: 'Заграничный паспорт', value: 'FOREIGN_PASSPORT_RF' },
  { id: '6', label: 'ИНН', value: 'INN' },
  { id: '2', label: 'Водительское удостоверение', value: 'DRIVING_LICENCE' },
  { id: '5', label: 'СНИЛС', value: 'RETIREMENT_INSURANCE_LICENCE' },
  { id: '1', label: 'Паспорт', value: 'PASSPORT' },
];

export const taxRateList = [
  { label: '0%', value: '0' },
  { label: '6%', value: '6' },
  { label: '9%', value: '9' },
  { label: '10%', value: '10' },
  { label: '13%', value: '13' },
  { label: '15%', value: '15' },
  { label: '35%', value: '35' },
];

export const employeeQtyList = [
  { label: '0-49', value: '1' },
  { label: '50-100', value: '2' },
  { label: 'Свыше 100', value: '3' },
];

export const occupationList = [
  { label: 'Пенсионер', value: 'PENSIONER' },
  { label: 'Наемный работник', value: 'HIRED_EMPLOYEE' },
  { label: 'Индивидуальный предприниматель', value: 'BUSINESSMAN' },
  { label: 'Собственник бизнеса', value: 'BUSINESS_OWNER' },
  { label: 'Частная практика', value: 'PRIVATE_PRACTICE' },
  { label: 'Другое', value: 'OTHER' },
];

export const industryList = [
  { label: 'Финансы, банки, консалтинг', value: 'FINANCE' },
  { label: 'Промышленность, строительство', value: 'INDUSTRY' },
  { label: 'Недвижимость', value: 'REAL_ESTATE' },
  { label: 'Вооруженные силы', value: 'ARMED_FORCES' },
  {
    label: 'Игорный бизнес, развлекательная индустрия',
    value: 'ENTERTAINMENT_INDUSTRY',
  },
  {
    label: 'Безопасность, правоохранительные органы',
    value: 'SECURITY',
  },
  {
    label: 'Государственная и муниципальная служба',
    value: 'STATE_AND_MUNICIPAL_SERVICE',
  },
  {
    label: 'Телекоммуникации, информационные технологии (услуги)',
    value: 'INFORMATION_TECHNOLOGY',
  },
  {
    label: 'Наука и культура, образование, медицина, социальная сфера',
    value: 'SOCIAL',
  },
  { label: 'Оптовая торговля', value: 'WHOLESALE' },
  { label: 'Розничная торговля', value: 'RETAIL_TRADE' },
  { label: 'Транспорт', value: 'TRANSPORT' },
  {
    label: 'Туризм, Ресторанный бизнес, Коммунальные услуги',
    value: 'SERVICES_SECTOR',
  },
  { label: 'Топливно-энергетический комплекс', value: 'FUEL_ENERGY_COMPLEX' },
  { label: 'Другое', value: 'OTHER' },
];

export const positionTypeList = [
  { label: 'Руководитель организации', value: 'ORGANIZATION_HEAD' },
  {
    label: 'Руководитель подразделения/Квалифицированный специалист',
    value: 'DEPARTMENT_HEAD',
  },
  { label: 'Специалист', value: 'SPECIALIST' },
];

export const additionalIncomeTypeList = [
  { label: 'Доходы от работы по совместительству', value: '1' },
  { label: 'Доходы от сдачи имущества в наем/аренду', value: '2' },
  { label: 'Пенсия', value: '3' },
  { label: 'Алименты', value: '4' },
  { label: 'Иное', value: 'OTHER' },
];

export const subsidyNameList = [
  { label: 'Первый автомобиль', value: 'FIRST_AUTO' },
  { label: 'Семейный автомобиль', value: 'FAMILY_AUTO' },
  { label: 'Медработник', value: 'PARAMEDIC' },
  { label: 'Trade-in', value: 'TRADE_IN' },
];

export const bankList = [
  { name: 'Росбанк', inn: '7730060164' },
  { name: 'Райффайзенбанк', inn: '7744000302' },
];
