import React from 'react';
import { Form } from 'antd';
import CheckBoxWithLink from '../components/CheckBoxWithLink';
import { required, requiredCheckBox } from '../services/validators';

import links from '../stores/lists/links';

const ConsentForm = props => {
  const { formDisabled } = props;
  return (
    <>
      <Form.Item
        name={'UserAgreement'}
        valuePropName={'checked'}
        rules={[required, requiredCheckBox]}
      >
        <CheckBoxWithLink
          text={'Я подтверждаю принятие условий'}
          linkText={'Пользовательского соглашения'}
          to={links.termsOfUse}
          disabled={formDisabled}
        />
      </Form.Item>
      <Form.Item
        name={'AccordanceInformationToBKI'}
        valuePropName={'checked'}
        rules={[required, requiredCheckBox]}
      >
        <CheckBoxWithLink
          text={'Я предоставляю согласие на запрос и получение информации'}
          linkText={'в бюро кредитных историй'}
          to={links.consentToRequestCreditHistory}
          disabled={formDisabled}
        />
      </Form.Item>
    </>
  );
};

export default ConsentForm;
