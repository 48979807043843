import React from 'react';

export default class IconPencil extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
      >
        <path
          d="M11.928 3.19045L8.8657 0.128157C8.69483 -0.0427189 8.41799 -0.0427189 8.24709 0.128157L0.129031 7.77778C0.0470038 7.85981 0.000859826 7.97089 0.000859826 8.08708L0 11.1502C0 11.266 0.046144 11.3775 0.128171 11.4595C0.210199 11.5416 0.321689 11.5877 0.437479 11.5877L3.50064 11.5869C3.61683 11.5869 3.72792 11.5407 3.80995 11.4587L11.928 3.80906C12.0989 3.63819 12.0989 3.36135 11.928 3.19045ZM3.31881 10.7127L0.874099 10.7135L0.874959 8.26882L6.14991 3.46233L8.59377 5.90618L3.31881 10.7127ZM9.21241 5.28753L6.76856 2.84371L8.55616 1.05611L11 3.49996L9.21241 5.28753Z"
          fill="#A8ADB3"
        />
      </svg>
    );
  }
}
