import React from 'react';
import { Row, Col } from 'antd';
import CalculatorLineBox from './CalculatorLineBox';

const CalculatorInfoRow = props => {
  const { data } = props;

  const count = 24 / data.length;

  return (
    <Row className={'calculator-row-info'}>
      {data.map(item => (
        <Col span={count} key={item.label}>
          <CalculatorLineBox
            label={item.label}
            value={item.value}
            changed={item.changed}
          />
        </Col>
      ))}
    </Row>
  );
};

export default CalculatorInfoRow;
