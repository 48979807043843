import { action, autorun, computed, observable } from 'mobx';
import {
  educationList,
  maritalStatusList,
  secondDocumentList,
} from 'src/lists/lists';
import { helperStore } from 'src/stores/index';
import React from 'react';
import SubsidyDocumentWarningMessage from 'src/components/SubsidyDocumentWarningMessage';

export default class PersonalEntity {
  @observable store;
  @observable _docType = 'DRIVING_LICENCE';
  @observable _clientEducationType;
  @observable _clientEducationOther;
  @observable amountChild;
  @observable _clientMaritalStatus;
  @observable docSeries;
  @observable docNumber;
  @observable docIssueDate;
  @observable docEndDate;
  @observable docIssueBy;
  @observable additionalDocuments = [];

  docTypeList = secondDocumentList.filter(doc => doc.id !== '1');
  clientEducationTypeList = educationList;
  clientMaritalStatusList = maritalStatusList;

  constructor(store) {
    this.store = store;
    autorun(() => {
      if (this.store.hasSubsidy) {
        this._docType = 'DRIVING_LICENCE';
      }
    });
  }

  @action
  async updateFields(data) {
    for (let key in data.appClientParams) {
      const value = data.appClientParams[key];
      if (this.hasOwnProperty(key)) {
        if (key.toLowerCase().includes('date')) {
          this[key] = new Date(value);
        } else if (key !== 'inn' && key !== 'drivingLicence') {
          this[key] = value;
        }
      } else if (key === 'tablAdditional' && value && value.additional) {
        const docFieldValue = value.additional[0];
        for (let name in docFieldValue) {
          if (this.hasOwnProperty(name)) {
            if (name.toLowerCase().includes('date') && docFieldValue[name]) {
              this[name] = new Date(docFieldValue[name]);
            } else {
              this[name] = docFieldValue[name];
            }
          }
        }
      }
    }
  }

  @computed
  get clientEducationType() {
    const found = this.clientEducationTypeList.find(
      o => o.value === this._clientEducationType
    );
    return found ? found.label : undefined;
  }

  set clientEducationType(value) {
    this._clientEducationType = value;
  }

  @computed
  get clientEducationOther() {
    return this.isOtherEducation ? this._clientEducationOther : '';
  }

  set clientEducationOther(v) {
    this._clientEducationOther = v;
  }

  @computed
  get clientMaritalStatus() {
    const found = this.clientMaritalStatusList.find(
      o => o.value === this._clientMaritalStatus
    );
    return found ? found.label : undefined;
  }

  set clientMaritalStatus(value) {
    this._clientMaritalStatus = value;
  }

  @computed
  get docType() {
    const found = this.docTypeList.find(o => o.value === this._docType);
    return found ? found.label : undefined;
  }

  set docType(v) {
    this._docType = v;
  }

  @computed
  get secondDocId() {
    const found = this.docTypeList.find(o => o.label === this.docType);
    return found ? found.id : undefined;
  }

  @computed
  get additionalDocId() {
    return this.additionalDocuments.map(doc => {
      const [id] = Object.keys(doc);
      return id;
    });
  }

  @computed
  get isOtherEducation() {
    return this._clientEducationType === 'OTHER';
  }

  @computed
  get isForeignPassport() {
    return this._docType === 'FOREIGN_PASSPORT_RF';
  }

  @computed
  get isDrivingLicense() {
    return this._docType === 'DRIVING_LICENCE';
  }

  @computed
  get isInn() {
    return this._docType === 'INN';
  }

  @action.bound
  onPersonalFieldChange(info) {
    const changedFields = info.changedFields[0];
    if (!changedFields) {
      return;
    }

    const field = changedFields.name[0];
    const value = changedFields.value;

    if (field === 'docType' && this.docType !== value) {
      this.docSeries = this.docNumber = this.docIssueBy = this.docIssueDate = this.docEndDate = undefined;
    }

    if (this.store.hasSubsidy && field === 'docType') {
      const document = this.findDocValue(value);
      const form = info.forms.personalData;
      if (document !== 'DRIVING_LICENCE') {
        const docName = this.findDocName('DRIVING_LICENCE');
        form.setFieldsValue({ docType: docName });
        return helperStore.onOpen({
          message: <SubsidyDocumentWarningMessage />,
        });
      }
    }

    if (this.hasOwnProperty(field)) {
      this[field] = value;
    }

    const listFieldNames = [
      'clientMaritalStatus',
      'clientEducationType',
      'docType',
    ];
    if (listFieldNames.indexOf(field) !== -1) {
      const found = this[field + 'List'].find(o => o.label === value);
      this['_' + field] = found ? found.value : undefined;
    }
  }

  @action.bound
  setDocuments(docs) {
    const secondDocsId = ['2', '4', '5', '6'];
    const additionalDocuments = [];
    docs.forEach(doc => {
      const [id] = Object.keys(doc);
      if (secondDocsId.indexOf(id) === -1) {
        additionalDocuments.push(doc);
      }
    });
    this.additionalDocuments = additionalDocuments;
  }

  findDocValue = name => {
    const found = this.docTypeList.find(doc => doc.label === name);
    return found.value || '';
  };

  findDocName = value => {
    const found = this.docTypeList.find(doc => doc.value === value);
    return found.label || '';
  };
}
