import { action, computed, observable } from 'mobx';
import {
  additionalIncomeTypeList,
  employeeQtyList,
  industryList,
  occupationList,
  positionTypeList,
  taxRateList,
} from 'src/lists/lists';
import AddressEntity from 'src/stores/entities/AddressEntity';

export default class IncomeEntity {
  @observable store;
  @observable seniority;
  @observable _employeesNumber;
  @observable _clientState;
  @observable _clientStateOther;
  @observable _clientCompanyLineOfBusiness;
  @observable _clientCompanyTypeOther;
  @observable clientPosition;
  @observable _clientOfficialPosition;
  @observable clientProfessionalLengthOfThisJobYear;
  @observable clientProfessionalLengthOfThisJobMonths;
  @observable clientProfessionalLengthYear;
  @observable clientProfessionalLengthMonths;
  @observable incomesOneMonth;
  @observable _addIncomesOneMonth;
  @observable _sourceOfAdditionalIncome;
  @observable _sourceOfAdditionalIncomeOther;
  @observable _taxRate;
  @observable _addrateNalog = '13';
  @observable clientCompanyPhoneNumber1;
  @observable clientCompanyPhoneInternalNumber1;
  @observable _clientCompanyPhoneNumber2;
  @observable hasPhoneAdditional;
  @observable clientCompanyPhoneInternalNumber2;
  @observable webPage;
  @observable clientCompanyName;
  @observable _employerInn;
  @observable employerAddress;
  @observable employerAddressCustom;
  @observable isEmployerAddressCustom;
  @observable _employerRoom;
  @observable additionalIncomeFlag;

  @observable employerAddressFIAS = new AddressEntity();

  @action
  async updateFields(data) {
    for (let key in data.appClientParams) {
      const value = data.appClientParams[key];
      if (this.hasOwnProperty(key)) {
        this[key] = value;
        if (key === 'clientCompanyPhoneNumber2') {
          this['hasPhoneAdditional'] = !!value;
        }
        if (key === 'addIncomesOneMonth') {
          this['additionalIncomeFlag'] = !!value;
        }
        if (key === 'sourceOfAdditionalIncome') {
          const found = this.sourceOfAdditionalIncomeList.find(
            o => o.label === value
          );
          this['sourceOfAdditionalIncome'] = found ? found.value : undefined;
        }
      } else if (key === 'clientAddressList' && value && value.clientAddress) {
        for (let address of value.clientAddress) {
          if (address.type === 'JOB') {
            this.employerAddressFIAS.set(address);
            this.employerAddress = await this.employerAddressFIAS.toString();
          }
        }
      }
    }
  }

  @action.bound
  toggleEmployerAddressCustom() {
    this.isEmployerAddressCustom = !this.isEmployerAddressCustom;
    this.employerAddressFIAS.fias(this.isEmployerAddressCustom);
  }

  set clientCompanyTaxCode(v) {
    this._employerInn = v;
  }

  @computed
  get clientCompanyTaxCode() {
    return this._employerInn && this._employerInn.replaceAll('_', '');
  }

  @computed
  get clientCompanyPhoneNumber2() {
    return this.hasPhoneAdditional
      ? this._clientCompanyPhoneNumber2
      : undefined;
  }

  set clientCompanyPhoneNumber2(v) {
    this._clientCompanyPhoneNumber2 = v;
  }

  @computed
  get taxRate() {
    if (!this._taxRate && this.clientState) {
      return this._clientState === 'BUSINESSMAN' ? '15' : '13';
    }
    return this._taxRate;
  }

  @computed
  get rateNalog() {
    const found = this.rateNalogList.find(o => o.value === this.taxRate);
    return found ? found.label : '';
  }

  set rateNalog(v) {
    const rate = parseInt(v);
    if (isNaN(rate)) {
      return;
    }
    this._taxRate = '' + parseInt(v);
  }

  @computed
  get employeesNumber() {
    const found = this.employeesNumberList.find(
      o => o.value === this._employeesNumber
    );
    return found ? found.label : undefined;
  }

  set employeesNumber(value) {
    this._employeesNumber = value;
  }

  @computed
  get clientState() {
    const found = this.clientStateList.find(o => o.value === this._clientState);
    return found ? found.label : undefined;
  }

  set clientState(value) {
    this._clientState = value;
  }

  @computed
  get clientStateOther() {
    return this.isOtherOccupation ? this._clientStateOther : '';
  }

  set clientStateOther(v) {
    this._clientStateOther = v;
  }

  @computed
  get clientCompanyLineOfBusiness() {
    const found = this.clientCompanyLineOfBusinessList.find(
      o => o.value === this._clientCompanyLineOfBusiness
    );
    return found ? found.label : undefined;
  }

  set clientCompanyLineOfBusiness(value) {
    this._clientCompanyLineOfBusiness = value;
  }

  @computed
  get clientCompanyTypeOther() {
    return this.isOtherIndustry ? this._clientCompanyTypeOther : '';
  }

  set clientCompanyTypeOther(v) {
    this._clientCompanyTypeOther = v;
  }

  @computed
  get clientOfficialPosition() {
    const found = this.clientOfficialPositionList.find(
      o => o.value === this._clientOfficialPosition
    );
    return found ? found.label : undefined;
  }

  set clientOfficialPosition(value) {
    this._clientOfficialPosition = value;
  }

  @computed
  get addIncomesOneMonth() {
    return this.additionalIncomeFlag ? this._addIncomesOneMonth : undefined;
  }

  set addIncomesOneMonth(v) {
    this._addIncomesOneMonth = v;
  }

  @computed
  get sourceOfAdditionalIncome() {
    const found = this.sourceOfAdditionalIncomeList.find(
      o => o.value === this._sourceOfAdditionalIncome
    );
    return found ? found.label : undefined;
  }

  set sourceOfAdditionalIncome(value) {
    this._sourceOfAdditionalIncome = value;
  }

  @computed
  get sourceOfAdditionalIncomeOther() {
    return this.isOtherAdditionalIncome
      ? this._sourceOfAdditionalIncomeOther
      : undefined;
  }

  set sourceOfAdditionalIncomeOther(v) {
    this._sourceOfAdditionalIncomeOther = v;
  }

  @computed
  get addrateNalog() {
    const found = this.rateNalogList.find(o => o.value === this._addrateNalog);
    return found ? found.label : '';
  }

  set addrateNalog(value) {
    if (value) {
      this._addrateNalog = value;
    }
  }

  @computed
  get addrateNalogValue() {
    return this.additionalIncomeFlag ? this._addrateNalog : undefined;
  }

  get clientProfessionalLengthOfThisJobYearList() {
    return new Array(51).fill(undefined).map((_, i) => i);
  }

  get clientProfessionalLengthOfThisJobMonthsList() {
    return new Array(12).fill(undefined).map((_, i) => i);
  }

  rateNalogList = taxRateList;
  addrateNalogList = taxRateList;
  employeesNumberList = employeeQtyList;
  clientStateList = occupationList;
  clientCompanyLineOfBusinessList = industryList;
  clientOfficialPositionList = positionTypeList;
  sourceOfAdditionalIncomeList = additionalIncomeTypeList;

  @action.bound
  setEmployerAddressFIAS(v) {
    this.employerAddressFIAS.clean();
    v && this.employerAddressFIAS.update('JOB', v.data);
  }

  @computed
  get isOtherOccupation() {
    return (
      this._clientState === 'OTHER' ||
      (this._clientState &&
        !this.clientStateList.map(o => o.value).includes(this._clientState))
    );
  }

  @computed
  get isOtherIndustry() {
    return (
      this._clientCompanyLineOfBusiness === 'OTHER' ||
      (this._clientCompanyLineOfBusiness &&
        !this.clientCompanyLineOfBusinessList
          .map(o => o.value)
          .includes(this._clientCompanyLineOfBusiness))
    );
  }

  @computed
  get isOtherAdditionalIncome() {
    return (
      this._sourceOfAdditionalIncome === 'OTHER' ||
      (this._sourceOfAdditionalIncome &&
        !this.sourceOfAdditionalIncomeList
          .map(o => o.value)
          .includes(this._sourceOfAdditionalIncome))
    );
  }

  @action.bound
  setEmployer(v) {
    if (!v) {
      return;
    }
    this.clientCompanyName = v.value;
    this.employerAddress = v.data.address.value;
    this.employerAddressFIAS.update('JOB', v.data.address.data);
  }

  @action.bound
  setEmployerInn(v) {
    if (!v) {
      return;
    }
    this.clientCompanyTaxCode = v.data.inn;
    this.employerAddress = v.data.address.value;
    this.employerAddressFIAS.update('JOB', v.data.address.data);
  }

  @action.bound
  onIncomeFieldChange(info) {
    const changedFields = info.changedFields[0];
    if (!changedFields) {
      return;
    }
    const field = changedFields.name[0];
    const value = changedFields.value;

    if (field === 'employerRoom') {
      this.employerAddressFIAS.addFlat(value);
    } else if (field === 'employerAddressCustom') {
      this.employerAddressFIAS.addComment(value);
    } else if (field === 'clientCompanyName') {
      info.forms.incomeData.validateFields(['clientCompanyTaxCode']);
    } else if (field === 'clientCompanyTaxCode') {
      info.forms.incomeData.validateFields(['employerAddress']);
    } else if (field === 'clientState') {
      info.forms.incomeData.validateFields(['rateNalog']);
    }
    if (this.hasOwnProperty(field)) {
      this[field] = value;
    }

    const listFieldNames = [
      'rateNalog',
      'addrateNalog',
      'employeesNumber',
      'clientState',
      'clientCompanyLineOfBusiness',
      'clientOfficialPosition',
      'sourceOfAdditionalIncome',
    ];
    if (listFieldNames.indexOf(field) !== -1) {
      const found = this[field + 'List'].find(o => o.label === value);
      this[field] = found ? found.value : undefined;
    }
  }

  getValue = (label, list) => list.find(o => o.label === label).value;

  set employerRoom(v) {
    this._employerRoom = v;
  }

  @computed
  get employerRoom() {
    return this._employerRoom || this.employerAddressFIAS.flat;
  }

  constructor(store) {
    this.store = store;
  }
}
