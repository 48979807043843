import React from 'react';
import { Row, Col, Form } from 'antd';
import InputBox from '../components/InputBox';
import { codePhrase, required } from '../services/validators';
import PhoneInputBox from '../components/PhoneInputBox';

const AuthDataForm = () => {
  return (
    <>
      <Row gutter={[26, 10]}>
        <PhoneInputBox />
        <Col span={12}>
          <Form.Item
            name={'clientPassword'}
            rules={[required, codePhrase]}
            validateTrigger={'onBlur'}
          >
            <InputBox
              label={'Кодовое слово*'}
              helperText={
                'Допускаются только буквы латинского алфавита от 5 до 19 символов'
              }
              placeholder={'Придумайте кодовое слово'}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default AuthDataForm;
