import React from 'react';
import { observer } from 'mobx-react';
import { Col, Form, Row } from 'antd';
import InputBox from '../components/InputBox';
import InputNumberBox from '../components/InputNumberBox';
import RequestPageLayout from '../layouts/RequestPageLayout';
import SecondDocumentForm from '../forms/SecondDocumentForm';
import { childrenAmount, required } from '../services/validators';
import useStores from 'src/hooks/useStores';
import NoteBox from 'src/components/NoteBox';
import ButtonPrimaryBox from 'src/components/ButtonPrimaryBox';
import MaritalStatusDropdownBox from 'src/components/MaritalStatusDropdownBox';
import EducationDropdownBox from 'src/components/EducationDropdownBox';
import { scrollToError } from 'src/helpers/helpers';

const PersonalDataPage = () => {
  const { requestStore, globalState } = useStores();
  const { personalEntity, passportEntity, onSubmit, setForm } = requestStore;
  const { formsReadOnly } = globalState;
  const {
    docType,
    clientEducationType,
    clientEducationOther,
    amountChild,
    clientMaritalStatus,
    docSeries,
    docNumber,
    docIssueDate,
    docEndDate,
    docIssueBy,
    docTypeList,
    isOtherEducation,
    isForeignPassport,
    isDrivingLicense,
    isInn,
  } = personalEntity;
  const { clientBirthdate } = passportEntity;

  const [form] = Form.useForm();
  const formName = 'personalData';

  const initialValues = {
    docType,
    clientEducationType,
    clientEducationOther,
    amountChild,
    clientMaritalStatus,
    docSeries,
    docNumber,
    docIssueDate,
    docEndDate,
    docIssueBy,
  };

  const onFinish = () => {
    onSubmit(formName);
  };

  if (form) {
    setForm(formName, form, Object.keys(initialValues));
  }
  return (
    <RequestPageLayout>
      <Form
        form={form}
        name={formName}
        initialValues={initialValues}
        fields={[
          { name: 'clientEducationType', value: clientEducationType },
          { name: 'docSeries', value: docSeries },
          { name: 'docNumber', value: docNumber },
          { name: 'docIssueDate', value: docIssueDate },
          { name: 'docEndDate', value: docEndDate },
          { name: 'docIssueBy', value: docIssueBy },
        ]}
        onFinish={onFinish}
        onFinishFailed={scrollToError}
      >
        <SecondDocumentForm
          clientBirthdate={clientBirthdate}
          docTypeList={docTypeList}
          isForeignPassport={isForeignPassport}
          isDrivingLicense={isDrivingLicense}
          isInn={isInn}
          formDisabled={formsReadOnly}
        />
        <Row gutter={[26, 32]}>
          <Col span={12}>
            <Form.Item name={'clientEducationType'} rules={[required]}>
              <EducationDropdownBox disabled={formsReadOnly} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'amountChild'} rules={[required, childrenAmount]}>
              <InputNumberBox
                label={'Количество иждивенцев*'}
                placeholder={'Укажите количество'}
                thousandSeparator={''}
                helperText={'Дети до 18 лет, неработающие супруг/супруга'}
                disabled={formsReadOnly}
              />
            </Form.Item>
          </Col>
        </Row>
        {isOtherEducation && (
          <Row gutter={[26, 32]}>
            <Col span={12}>
              <Form.Item name={'clientEducationOther'} rules={[required]}>
                <InputBox
                  placeholder={'Укажите полученное образование'}
                  paddingTop={0}
                  disabled={formsReadOnly}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={[26, 0]}>
          <Col span={12}>
            <Form.Item name={'clientMaritalStatus'} rules={[required]}>
              <MaritalStatusDropdownBox disabled={formsReadOnly} />
            </Form.Item>
          </Col>
        </Row>
        <NoteBox />
        <Form.Item>
          <ButtonPrimaryBox htmlType={'submit'} disabled={formsReadOnly}>
            ДАЛЕЕ
          </ButtonPrimaryBox>
        </Form.Item>
      </Form>
    </RequestPageLayout>
  );
};

export default observer(PersonalDataPage);
