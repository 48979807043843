import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'antd';
import UploadBox from '../components/UploadBox';
import DropdownInput from '../components/InputDropdownBox';
import AddMoreDocBox from '../components/AddMoreDocBox';

const UploadAdditionalModal = props => {
  const { requestStore } = props;
  const {
    uploadAdditionalShow,
    closeUploadAdditional,
    reworkFileStore,
  } = requestStore;
  const { documentListAll } = requestStore;

  const [documents, addMoreDoc] = useState([{}]);

  const $addMoreDoc = () => {
    addMoreDoc(documents => [...documents, {}]);
  };

  const $setID = (position, newID) => {
    documents[position].enabled = true;
    documents[position].docID = newID;
    addMoreDoc([...documents]);
  };

  return (
    <Modal
      className={'modal upload-modal'}
      centered
      visible={uploadAdditionalShow}
      title={'Скан или фото. Максимальный размер 5Мб. Тип файла: JPG, PDF'}
      footer={null}
      onCancel={closeUploadAdditional}
      width={600}
    >
      {documents.map((doc, i) => {
        return (
          <div key={i}>
            <DropdownInput
              label={'Тип документа'}
              items={documentListAll
                .map(doc => ({ value: doc.name, key: doc.docID }))
                .sort((a, b) => {
                  return a.value.charCodeAt(0) > b.value.charCodeAt(0);
                })}
              onChange={(_, { key }) => {
                $setID(i, key);
              }}
            />
            <UploadBox
              id={doc.docID}
              disabled={!doc.enabled}
              fileStore={reworkFileStore}
            />
          </div>
        );
      })}
      <AddMoreDocBox onClick={$addMoreDoc} />
    </Modal>
  );
};

export default inject('requestStore')(observer(UploadAdditionalModal));
