import React from 'react';

import { observer } from 'mobx-react';
import RequestPageLayout from '../layouts/RequestPageLayout';
import JobAddressForm from '../forms/JobAddressForm';
import JobContactsForm from '../forms/JobContactsForm';
import JobDataForm from '../forms/JobDataForm';
import SalaryForm from '../forms/SalaryForm';
import { Form } from 'antd';
import useStores from 'src/hooks/useStores';
import NoteBox from 'src/components/NoteBox';
import ButtonPrimaryBox from 'src/components/ButtonPrimaryBox';
import { scrollToError } from 'src/helpers/helpers';

const IncomeDataPage = () => {
  const { requestStore, globalState, authStore } = useStores();
  const { authEntity } = authStore;
  const { formsReadOnly } = globalState;
  const { incomeEntity, onSubmit, setForm } = requestStore;
  const { clientPhoneMobile } = authEntity;
  const {
    employeesNumber,
    clientState,
    clientStateOther,
    clientCompanyLineOfBusiness,
    clientCompanyTypeOther,
    clientOfficialPosition,
    clientPosition,
    clientProfessionalLengthOfThisJobYear,
    clientProfessionalLengthOfThisJobMonths,
    clientProfessionalLengthYear,
    clientProfessionalLengthMonths,
    incomesOneMonth,
    addIncomesOneMonth,
    sourceOfAdditionalIncome,
    sourceOfAdditionalIncomeOther,
    rateNalog,
    addrateNalog,
    clientCompanyPhoneNumber1,
    clientCompanyPhoneInternalNumber1,
    clientCompanyPhoneNumber2,
    clientCompanyPhoneInternalNumber2,
    webPage,
    clientCompanyName,
    clientCompanyTaxCode,
    employerAddress,
    employerAddressCustom,
    employerRoom,
    employerRoomCustom,
    clientProfessionalLengthOfThisJobYearList,
    clientProfessionalLengthOfThisJobMonthsList,
    seniority,
    rateNalogList,
    clientStateList,
    isOtherOccupation,
    clientCompanyLineOfBusinessList,
    isOtherIndustry,
    isOtherAdditionalIncome,
    employeesNumberList,
    clientOfficialPositionList,
    employerAddressFIAS,
    setEmployerInn,
    setEmployer,
    setEmployerAddressFIAS,
    additionalIncomeFlag,
    hasPhoneAdditional,
    isEmployerAddressCustom,
    toggleEmployerAddressCustom,
  } = incomeEntity;

  const [form] = Form.useForm();

  const initialValues = {
    seniority,
    employeesNumber,
    clientState,
    clientStateOther,
    clientCompanyLineOfBusiness,
    clientCompanyTypeOther,
    clientOfficialPosition,
    clientPosition,
    clientProfessionalLengthOfThisJobYear,
    clientProfessionalLengthOfThisJobMonths,
    clientProfessionalLengthYear,
    clientProfessionalLengthMonths,
    incomesOneMonth,
    addIncomesOneMonth,
    sourceOfAdditionalIncome,
    sourceOfAdditionalIncomeOther,
    rateNalog,
    addrateNalog,
    clientCompanyPhoneNumber1,
    clientCompanyPhoneInternalNumber1,
    clientCompanyPhoneNumber2,
    clientCompanyPhoneInternalNumber2,
    webPage,
    clientCompanyName,
    clientCompanyTaxCode,
    employerAddress,
    employerAddressCustom,
    employerRoom,
    employerRoomCustom,
    additionalIncomeFlag,
    hasPhoneAdditional,
  };

  const formName = 'incomeData';
  if (form) {
    setForm(formName, form, Object.keys(initialValues));
  }

  const onFinish = () => {
    onSubmit(formName);
  };

  return (
    <RequestPageLayout onButtonClick={console.log}>
      <Form
        form={form}
        name={formName}
        initialValues={initialValues}
        fields={[
          { name: 'incomesOneMonth', value: incomesOneMonth },
          { name: 'addIncomesOneMonth', value: addIncomesOneMonth },
          { name: 'clientCompanyName', value: clientCompanyName },
          { name: 'employerAddress', value: employerAddress },
          { name: 'clientCompanyTaxCode', value: clientCompanyTaxCode },
          { name: 'rateNalog', value: rateNalog },
        ]}
        onFinish={onFinish}
        onFinishFailed={scrollToError}
      >
        <JobAddressForm
          setEmployer={setEmployer}
          setEmployerInn={setEmployerInn}
          setEmployerAddressFIAS={setEmployerAddressFIAS}
          employerAddressFIAS={employerAddressFIAS}
          isEmployerAddressCustom={isEmployerAddressCustom}
          toggleEmployerAddressCustom={toggleEmployerAddressCustom}
          formDisabled={formsReadOnly}
        />
        <JobContactsForm
          employeesNumberList={employeesNumberList}
          hasPhoneAdditional={hasPhoneAdditional}
          formDisabled={formsReadOnly}
          clientPhoneMobile={clientPhoneMobile}
        />
        <JobDataForm
          clientProfessionalLengthOfThisJobYearList={
            clientProfessionalLengthOfThisJobYearList
          }
          clientProfessionalLengthOfThisJobMonthsList={
            clientProfessionalLengthOfThisJobMonthsList
          }
          seniority={seniority}
          clientStateList={clientStateList}
          isOtherOccupation={isOtherOccupation}
          clientCompanyLineOfBusinessList={clientCompanyLineOfBusinessList}
          isOtherIndustry={isOtherIndustry}
          clientOfficialPositionList={clientOfficialPositionList}
          formDisabled={formsReadOnly}
        />
        <SalaryForm
          rateNalogList={rateNalogList}
          additionalIncomeFlag={additionalIncomeFlag}
          isOtherAdditionalIncome={isOtherAdditionalIncome}
          formDisabled={formsReadOnly}
        />
        <NoteBox />
        <Form.Item>
          <ButtonPrimaryBox htmlType={'submit'} disabled={formsReadOnly}>
            ДАЛЕЕ
          </ButtonPrimaryBox>
        </Form.Item>
      </Form>
    </RequestPageLayout>
  );
};

export default observer(IncomeDataPage);
