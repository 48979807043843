import React from 'react';
import { Row, Col, Form } from 'antd';
import InputSliderBox from '../components/InputSliderBox';
import WithMarks from '../components/hocs/WithMarks';
import SliderSuffix from '../components/SliderSuffix';
import CheckBoxWithLink from 'src/components/CheckBoxWithLink';

const CreditConditionsForm = props => {
  const {
    carCost,
    beginPaymentPercent,
    beginPaymentMax,
    beginPaymentMin,
    residualPaymentPercent,
    residualPaymentMax,
    residualPaymentMin,
    isResidualProgram,
    creditTermMax,
    creditTermMin,
    toggleInsurance,
    insuranceInCredit,
    calcDisabled,
    creditTermMultiplicity,
    formDisabled,
  } = props;

  const SliderWithMarks = WithMarks(InputSliderBox);
  const onChange = e => toggleInsurance(e.target.checked);

  return (
    <>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Form.Item name={'beginPayment'}>
            <InputSliderBox
              label={'Первоначальный взнос'}
              max={beginPaymentMax}
              min={beginPaymentMin}
              total={carCost}
              placeholder={'100 000'}
              suffix={<SliderSuffix value={beginPaymentPercent} />}
              disabled={calcDisabled || formDisabled}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Form.Item name={'creditTerm'}>
            <SliderWithMarks
              label={'Срок кредитования'}
              max={creditTermMax}
              min={creditTermMin}
              placeholder={'24'}
              disabled={calcDisabled || formDisabled}
              unChangeable={true}
              step={creditTermMultiplicity}
              suffix={' мес'}
              marksType={'data'}
            />
          </Form.Item>
        </Col>
      </Row>
      {isResidualProgram && (
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Form.Item name={'residualPayment'}>
              <InputSliderBox
                total={carCost}
                label={'Остаточный платеж'}
                max={residualPaymentMax}
                min={residualPaymentMin}
                placeholder={'100 000'}
                disabled={calcDisabled || formDisabled}
                suffix={<SliderSuffix value={residualPaymentPercent} />}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={[0, 20]} style={{ padding: '30px 0 0' }}>
        <Col span={24}>
          <Form.Item>
            <CheckBoxWithLink
              checked={insuranceInCredit}
              text={'В сумму кредита включено страхование жизни и здоровья'}
              onChange={onChange}
              disabled={formDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default CreditConditionsForm;
